import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tabs from '../../../components/Tabs';
import ExpansionPanelDetailed from '../../../components/ExpansionPanelDetail';
import ChartLine2 from '../../../components/LineChart2';
// import ChartBar from '../../../components/BarChart';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    marginBottom: theme.spacing(10),
    backgroundColor: 'inherit',
    // overflow: 'scroll',
  },
  heading: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  section: {
    margin: theme.spacing(2, 4),
  },

});

class MonitoringDashboard extends Component {
  constructor(props) {
    super(props);
    // this.onMapBlockClick = this.onMapBlockClick.bind(this);

    this.state = {
      graphData: {},
      expandedMonitorType: '',
      expandedMonitorTypeInner: '',
    };
  }

  componentDidMount() {
    const { data , chemicals, getFromGlobalState} = this.props;
    const targetTypes = getFromGlobalState('targetsTypesSetting');

    const graphData = this.formatData(data, chemicals,targetTypes);
    if (graphData) {
      this.setState({ graphData});
    }
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    const { data , chemicals, getFromGlobalState } = this.props;
    const targetTypes = getFromGlobalState('targetsTypesSetting');

    if (data !== prevProps.data) {
      // const blocks = getFromGlobalState('blocks');
      const graphData = this.formatData(data, chemicals, targetTypes);
      this.setState({ graphData });
    }
  }

  handleMonitorTypeExpand = (setting) => {
    const { expandedMonitorType } = this.state;
    if (expandedMonitorType !== setting) {
      this.setState({ expandedMonitorType: setting });
    } else {
      this.setState({ expandedMonitorType: '' });
    }
  }

  handleMonitorTypeExpandInner = (setting) => {
    const { expandedMonitorTypeInner } = this.state;
    if (expandedMonitorTypeInner !== setting) {
      this.setState({ expandedMonitorTypeInner: setting });
    } else {
      this.setState({ expandedMonitorTypeInner: '' });
    }
  }

  distinctPush = (array, member) => {
    const idx = array.findIndex((p) => p === member);
    if (idx === -1) {
      array.push(member);
    }
  };


  findUnitValue(data, value){
    // Helper function to recursively search for the 'unit' key
    function search(obj) {
        if (obj && typeof obj === 'object') {
            // Check if the 'unit' key exists at the current level
            if (obj.hasOwnProperty(value)) {
                return obj[value];
            }
            // Search recursively in all properties
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const result = search(obj[key]);
                    if (result) {
                        return result;
                    }
                }
            }
        }
        return null;
    }

    return search(data);
}

  renderCharts = (data) => {
    const { handleMonitoringClick } = this.props;
    const belee = 2;

    return (
      Object.keys(data).map((key) => {
        const unit = this.findUnitValue(data[key], "unit");
        const Ylabel = "Average " + (unit ? "(per" +unit+")" :"")
        const procedure = this.findUnitValue(data[key], "procedure");
        return <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ChartLine2
            dataKey="date"
            key={key}
            title={key}
            height={300}
            name={key}
            handleClick={handleMonitoringClick}
            keys={data[key].dataKeys}
            Ylabel={Ylabel}
            procedure={procedure}
            data={data[key].data.sort((a, b) => a.date - b.date)}
          />
        </Grid>
      }))
  }

  aggregateChemicalData(applications, chemicals, targetTypes) {
    const results = [];

    const getTargetTypeName = (targetTypeSk) => {
        const targetType = (targetTypes||[]).find(type => type.sk === targetTypeSk);
        return targetType ? targetType.name : 'Unknown';  
    };

    const getTargetName = (target) => {
      const targetType = (this.props.getFromGlobalState('monitorings')||[]).find(type => type.sk === target);
      return targetType ? targetType.targetDetail.name : 'Unknown';  
  };

    applications.forEach(app => {
        const { product, volume, date, reasons } = app;

        
        const chemical = chemicals.find(chem => chem.sk === product);
        if (!chemical) return; 

        reasons.forEach(reason => {
            if (reason.applied) {
               
                const targetTypeName = getTargetTypeName(reason.targetType);

                let entry = results.find(entry => entry.product === chemical.name && entry.date === date && entry.targetType === targetTypeName);
                if (!entry) {
                    entry = {
                        product: chemical.name,
                        date: date,
                        volume: 0,
                        targets: [],
                        targetType: targetTypeName
                    };
                    results.push(entry);
                }

                entry.volume += volume;

                const targetExists = entry.targets.some(target => target.target === reason.target);
                if (!targetExists) {
                    entry.targets.push({
                        name:getTargetName(reason.target),
                        targetType: reason.targetType
                    });
                }
            }
        });
    });

    return results;
}

createUniqueId(data) {
  // Create a unique key based on product, date, volume, and sorted targets
  const targetDetails = data.pest?.targets.map(t => `${t.name}:${t.targetType}`).sort().join('|');
  return `${data.pest?.product}:${data.date}:${data.pest?.volume}:${targetDetails}`;
}

addUnique(dataArray, newData) {
  const existingIds = new Set(dataArray.map(data => this.createUniqueId(data)));
  const newId = this.createUniqueId(newData);

  // Check if the unique identifier already exists
  if (!existingIds.has(newId)) {
      dataArray.push(newData);
      return true; // Successfully added
  }
  return false; // Duplicate found, not added
}

  formatData = (monitoringData, chemicals, targetTypes) => {
    const retVal = {};
    if (!monitoringData || !monitoringData.targetTypes) {
      return null;
    }


  //  generateReasonList = (allowedChemicals, product) => {
  //     if (!product) {
  //       return [];
  //     }
    
  //     const monitorings = this.props.getFromGlobalState('monitorings');
  //     // now filter all the targets that this chemical can be used for.
  //     const chemical = allowedChemicals.find((f) => f.sk === product);
  //     const { targets } = chemical.chemcrops[0];
    
  //     const monitoringFiltered = monitorings.filter((el) => targets.some((f) => f.target === el.sk));
  //     return monitoringFiltered;
  //   };

 

    for (let k = 0; k < monitoringData.targetTypes.length; k += 1) {
      const type = monitoringData.targetTypes[k];

      const records = monitoringData.circles.filter((m) => m.targetDetail && m.targetDetail.type === type);

      // const recordsByTarget = records.filte
      // records[type][target][stage]
      if (!retVal[type]) {
        retVal[type] = {};
      }
      for (let r = 0; r < records.length; r += 1) {
        const record = records[r];
        const targetDetailSk =  record?.targetDetail?.detail?.sk;
        const appliedChemicals =  this.aggregateChemicalData(record.sprayings, chemicals, targetTypes);
   
        if (!retVal[type][record.observationData.targetName]) {
          retVal[type][record.observationData.targetName] = {};
        }
        if (!retVal[type][record.observationData.targetName][record.observationData.stageDescription]) {
          retVal[type][record.observationData.targetName][record.observationData.stageDescription] = [];
        }
        const block = `Block: ${record.block}`;
        if (!retVal[type][record.observationData.targetName][record.observationData.stageDescription].dataKeys) {
          retVal[type][record.observationData.targetName][record.observationData.stageDescription].dataKeys = [];
        }

        const pestName = record.observationData.targetName;
        const appliedForPestName = appliedChemicals.filter((x)=>{
          const targets = x.targets.map((x)=>x.name);
          return targets.includes(pestName);
        });

        this.distinctPush(retVal[type][record.observationData.targetName][record.observationData.stageDescription].dataKeys, block);

        appliedForPestName.map((x)=>{
          this.distinctPush(retVal[type][record.observationData.targetName][record.observationData.stageDescription].dataKeys, x.product);
        });

        if (!retVal[type][record.observationData.targetName][record.observationData.stageDescription].data) {
          retVal[type][record.observationData.targetName][record.observationData.stageDescription].data = [];
        }
        const dataObj = { date: new Date(record.observationData.date).getTime() };
        dataObj[block] = record.observationData.value;
        dataObj.blockName = block;
        dataObj.observationData = record.observationData;
        // observationData.value.toFixed(2)
        retVal[type][record.observationData.targetName][record.observationData.stageDescription].data.push(dataObj);
        appliedForPestName.map((x)=>{
          const newDataObject = {
            [x.product]:x.volume,
            date:new Date(x.date).getTime(),
            pest:x
          }
        const d = retVal[type][record.observationData.targetName][record.observationData.stageDescription].data;
        this.addUnique(d, newDataObject)
        })
      }
    }

    return retVal;

  }

  render() {
    const { classes } = this.props;

    const { graphData, expandedMonitorType, expandedMonitorTypeInner } = this.state;
    return (
      <Paper elevation={1} style={{
       marginTop: '10px', maxHeight: '80vh', minHeight: '400px', overflowY: 'auto', backgroundClip: 'inherit'
      }}
      >
        <Grid container className={classes.root} spacing={1} alignItems="flex-start">
          {graphData
              && Object.keys(graphData).map((key) => (
                <ExpansionPanelDetailed
                  heading={key}
                  overwriteDisplay
                  expanded={expandedMonitorType}
                  handleExpand={this.handleMonitorTypeExpand}
                >
                  {Object.keys(graphData[key]).map((key2) => (
                    <div style={{ width: '100%', marginBottom: '5px' }}>
                      <ExpansionPanelDetailed
                        heading={key2}
                        overwriteDisplay
                        expanded={expandedMonitorTypeInner}
                        handleExpand={this.handleMonitorTypeExpandInner}
                      >
                        <div style={{ width: '100%' }}>
                          {expandedMonitorTypeInner === key2 && this.renderCharts(graphData[key][key2])}
                        </div>
                      </ExpansionPanelDetailed>
                    </div>
                  ))}
                </ExpansionPanelDetailed>

              ))}
        </Grid>
      </Paper>

    );
  }
}
MonitoringDashboard.propTypes = {
  data: PropTypes.object.isRequired,
  handleMonitoringClick: PropTypes.func.isRequired,
  chemicals:PropTypes.array,
  getFromGlobalState:PropTypes.func
};
export default withStyles(styles)(MonitoringDashboard);
