import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import SimpleCard from '../../components/SimpleCard';

import { withStyles } from '@material-ui/core/styles';
import Load from '../../components/Load';
import PlantingItems from '../../components/PlantingItems'
import BlockD from '../../containers/BlockD';
import PlantingD from '../../containers/PlantingD';
import RecordD from '../../containers/RecordD';
import Status from '../../components/Status';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import StarBorder from '@material-ui/icons/StarBorder';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import { Link } from 'react-router-dom';
import { green } from '@material-ui/core/colors';


import { statusEngine, getBlockCollection } from "../../libs/analytics";
import Tooltip from '@material-ui/core/Tooltip';
import {getNameFromKey } from '../../libs/genLib';
import ReportD from '../../containers/ReportD';
import {updateStateRecord} from '../../libs/storage';
import SearchBar from '../../components/SearchBar';
import CachedIcon from '@material-ui/icons/CloudUploadOutlined';
import SyncErrorIcon from '@material-ui/icons/CloudOffOutlined';
import ReportBlockPDF from '../ReportBlockPDF';
import ReportBlockCSV from '../ReportBlockCSV';
// import { useHistory } from 'react-router-dom';





const styles = theme => ({
  root: {
    flexGrow: 1,
    // marginTop:'5px',
  },
  root2: {
    flexGrow: 1,
    marginTop:'5px',
  },
  paper: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    marginBottom: theme.spacing(10),
    backgroundColor: 'inherit',
    // overflow: 'scroll',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  fab: {
    margin: theme.spacing(1),
  },
  fabFloat: {
    margin: theme.spacing(1),
    top: 'auto',
    right: 20,
    bottom: 80,
    left: 'auto',
    position: 'fixed',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  list :{
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  heading: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  searchBar: {
    [theme.breakpoints.up('sm')]: {
      //display: 'none',
    },

  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  cardHeader: {
    paddingBottom: '6px',

  },
  cached: {
    fontSize: 15,
    color: theme.palette.secondary.main,
  },
  syncError: {
    fontSize: 15,
    color: 'red',
  },
  

  greenAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: green[500],
    width: 30,
    height: 30,
    // boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
  },
  redAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: theme.palette.error.dark,
    width: 30, 
    height: 30,
    // boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
  },
});

const friendlyActivityName = {
  fertilisings: 'Fertilisers',
  waterings: 'Irrigation',
  pestings: 'Pests',
  sprayings: 'Plant Protection',
  harvestings: 'Harvesting',
  packings: 'Packing',
}

class Home extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      isloading: true,
      isNew: false,
      openDialog: "",
      reportDialogOpen:false,
      showAllDialogOpen:false,
      // blocks: [],
      selectedBlock: null,
      selectedBlockSK:"",
      selectedRecordSK:"",
      selectedPlantingSK:"",
      expanded: "",
      recordExpanded: {plantingSK:"", recordType: ""},
      pdf: false,
      csv: false,
    };
  }
  
  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    
  
    this.setState({ isloading: false });
  }
  
  // records() {
  // return API.get("farms", "/farms/block_");
  // console.log ("api get from Home")
  // return API.get("farms", "/farms/init_");
  // }

  handleClickAddNew() {
    this.setState({openDialog: 'block', isNew: true });
  }
  
  handleClickShow(selectedBlock,event) {
    // event.preventDefault();
    event.stopPropagation();
    // let selectedBlock = this.props.getFromGlobalState('blocks').find(block=>block.sk===selectedBlockID);
    this.setState({selectedBlock: selectedBlock, selectedBlockSK:selectedBlock.sk });
    this.setState({openDialog: 'block', isNew: false})
   
  }

  handleUpdateBlocks(blockIn) {
    // see if we have the block
    var updatedBlockIndex = this.props.getFromGlobalState('blocks').findIndex(i => i.sk === blockIn.sk);
    if (updatedBlockIndex === -1){
      var newBlocks = this.props.getFromGlobalState('blocks');
      newBlocks.push(blockIn);
      this.props.setGlobalState({blocks: newBlocks});
      // this.state.blocks.push(blockIn);
    }
    else {
      let newBlocks = this.props.getFromGlobalState('blocks');
      newBlocks[updatedBlockIndex] = blockIn;
      this.props.setGlobalState({ blocks: newBlocks });
    }
  }

  handleDeleteBlock(blockIn, blockSK) {
    // see if we have the block
    const { getFromGlobalState, setGlobalState } = this.props;
    const blocks = getFromGlobalState('blocks');
    const updatedBlockIndex = blocks.findIndex((i) => i.sk === blockIn.sk);
    if (updatedBlockIndex === -1) {
      alert("impossible - we don't have this block!");
      return;
    }
    blocks.splice(updatedBlockIndex, 1);
    setGlobalState({ blocks });
  }
// bock
handleExpandBlock = (block) => {
  let selectedBlock = this.state.selectedBlock;
  if (selectedBlock && block.sk === selectedBlock.sk){
    this.setState({selectedBlock:null});
    // this.setState({expandedContent:null});
  }
  else {
    this.setState({selectedBlock: block});
  }


}
  
  // planting
  handlePlantingExpand = (plantingSK) => {
    if (this.state.expanded !== plantingSK) {
      this.setState({ expanded:plantingSK });
    }
    else {
      this.setState({ expanded:"" });
    }
  }
  handleClickAddNewPlanting = (blockSK,block) => {
    this.setState({openDialog: 'planting',selectedBlock: block, isNew: true, selectedBlockSK: blockSK });
  }
  handleClickShowPlanting = (selectedBlockId, selectedPlantingId,block,event) => {
    event.stopPropagation();
    // let selectedBlock = this.props.getFromGlobalState('blocks').find(block=>block.sk===selectedBlockId);
    let selectedPlanting = block.plantings.find(planting =>planting.sk === selectedPlantingId)
    this.setState({selectedPlanting: selectedPlanting, selectedBlockSK: block.sk, selectedBlock:block});
    this.setState({openDialog: 'planting', isNew: false})
  }
  setClosePlanting() {
    this.setState({openDialog: ''})
  }
  handleUpdatePlantings(plantingIn,blockSK) {
    // see if we have the block
    var updatedBlockIndex = this.props.getFromGlobalState('blocks').findIndex(i => i.sk === blockSK);
    if (updatedBlockIndex === -1){
      alert("impossible - we don't have this block!")
      return;
    }
    // find the planting
    var blocks = this.props.getFromGlobalState('blocks');
    var plantings = blocks[updatedBlockIndex].plantings;
    
    var updatedPlantingIndex = (plantings ? blocks[updatedBlockIndex].plantings.findIndex(i => i.sk === plantingIn.sk): -1 );
    // let newBlocks = this.props.getFromGlobalState('blocks');
    if (updatedPlantingIndex === -1)
    {
     
      if (!plantings) {
        blocks[updatedBlockIndex].plantings=[];
      }

      blocks[updatedBlockIndex].plantings.push(plantingIn);
      this.props.setGlobalState({ blocks: blocks });
    }
    else {
      // find the planting
      // let newBlocks = this.state.blocks;
      blocks[updatedBlockIndex].plantings[updatedPlantingIndex] = plantingIn;
      this.props.setGlobalState({ blocks: blocks });
    }
  }

  handleDeleteRecord(recordIn, parentSK) {
    // see if we have the block
    const {
      getFromGlobalState, setGlobalState,
    } = this.props;
    
    const { selectedBlockSK, selectedPlantingSK } = this.state;

    const blocks = getFromGlobalState('blocks');
    const deletedBlockIndex = blocks.findIndex((i) => i.sk === selectedBlockSK);
    if (deletedBlockIndex === -1) {
      alert("impossible - we don't have this block!");
      return;
    }
    // find the planting
    const { plantings } = blocks[deletedBlockIndex];
    const updatedPlantingIndex = blocks[deletedBlockIndex].plantings.findIndex((i) => i.sk === selectedPlantingSK);
    const records = plantings[updatedPlantingIndex][recordIn.type];
    const recordsIndex = (records ? plantings[updatedPlantingIndex][recordIn.type].findIndex((i) => i.sk === recordIn.sk) : -1);

    if (recordsIndex === -1) {
      alert("impossible deleting a record that doesn't exist");
    } else {
      // see if this  record has any children
      if (recordIn.type === 'fertilisings' || recordIn.type === 'sprayings') {
        const chemInventory = getFromGlobalState('inv_chemical');
        const fertInventory = getFromGlobalState('inv_fertiliser');
        if (chemInventory) {
          const chemItems = chemInventory.filter((x) => x.sk.startsWith(recordIn.sk));
          if (chemItems.length > 0) {
            chemItems.forEach((x) => chemInventory.splice(chemInventory.indexOf(x), 1));
            setGlobalState({ inv_chemical: chemInventory });
          }
        }
        if (fertInventory) {
          const fertItems = fertInventory.filter((x) => x.sk.startsWith(recordIn.sk));
          if (fertItems.length > 0) {
            fertItems.forEach((x) => fertInventory.splice(fertInventory.indexOf(x), 1));
            setGlobalState({ inv_fertiliser: fertInventory });
          }
        }
      }
      blocks[deletedBlockIndex].plantings[updatedPlantingIndex][recordIn.type].splice(recordsIndex, 1);
      setGlobalState({ blocks: blocks });
    }
  }
  handleDeletePlantings(plantingIn,blockSK) {
    // see if we have the block
    var updatedBlockIndex = this.props.getFromGlobalState('blocks').findIndex(i => i.sk === blockSK);
    if (updatedBlockIndex === -1){
      alert("impossible - we don't have this block!")
      return;
    }
    // find the planting
    var blocks = this.props.getFromGlobalState('blocks');
    var plantings = blocks[updatedBlockIndex].plantings;
    
    var updatedPlantingIndex = (plantings ? blocks[updatedBlockIndex].plantings.findIndex(i => i.sk === plantingIn.sk): -1 );
    // let newBlocks = this.props.getFromGlobalState('blocks');
    if (updatedPlantingIndex === -1)
    {
     
      alert ("impossible - deleting a planting we don't have");
    }
    else {
      // find the planting
      // let newBlocks = this.state.blocks;
      blocks[updatedBlockIndex].plantings.splice(updatedPlantingIndex, 1);
      this.props.setGlobalState({ blocks: blocks });
    }
  }

  // plantings done 

  // records
  handleClickShowRecord = (selectedBlockId, selectedPlantingId, recordType, selectedRecordId) => {
   
    let selectedBlock = this.props.getFromGlobalState('blocks').find(block=>block.sk===selectedBlockId);
    let selectedPlanting = selectedBlock.plantings.find(planting =>planting.sk === selectedPlantingId)
    let selectedRecord = selectedPlanting[recordType].find(record =>record.sk === selectedRecordId)
    this.setState({selectedBlock: selectedBlock, selectedPlanting: selectedPlanting, selectedBlockSK: selectedBlock.sk, selectedPlantingSK: selectedPlanting.sk, selectedRecord: selectedRecord,selectedRecordSK: selectedRecord.sk});
    this.setState({openDialog: recordType, isNew: false})
    console.log(selectedRecord, this.props.getFromGlobalState('monitorings'));

  }
  
  handleClickShowTableRecord = (event, planting, selectedRecordId,actionType,selectedBlockId,recordType) => {
   
    let selectedBlock = this.props.getFromGlobalState('blocks').find(block=>block.sk===selectedBlockId);
    let selectedPlanting = selectedBlock.plantings.find(p =>p.sk === planting.sk)
    let selectedRecord = selectedPlanting[recordType].find(record =>record.sk === selectedRecordId)
    console.log(selectedRecord, this.props.getFromGlobalState('monitorings'));

    this.setState({selectedBlock: selectedBlock, selectedPlanting: selectedPlanting, selectedBlockSK: selectedBlock.sk, selectedPlantingSK: selectedPlanting.sk, selectedRecord: selectedRecord,selectedRecordSK: selectedRecord.sk});
    this.setState({openDialog: recordType, isNew: false})
  }

  handleClickAddNewRecord = (blockSK, plantingSK, recordType) => {
    let selectedBlock = this.props.getFromGlobalState('blocks').find(block=>block.sk===blockSK);
    let selectedPlanting = selectedBlock.plantings.find(p =>p.sk === plantingSK)
    this.setState({selectedBlock: selectedBlock, selectedPlanting: selectedPlanting, openDialog: recordType, isNew: true, selectedBlockSK: blockSK, selectedPlantingSK: plantingSK });
  }

  handleClickViewReport = (blockSK, plantingSK, recordType) => {
    let selectedBlock = this.props.getFromGlobalState('blocks').find(block=>block.sk===blockSK);
    let selectedPlanting = selectedBlock.plantings.find(planting =>planting.sk === plantingSK);
    this.setState({selectedBlock: selectedBlock,selectedPlanting:selectedPlanting,reportDialogOpen: true,viewRecordType:recordType, isNew: false, selectedBlockSK: blockSK, selectedPlantingSK: plantingSK });
  }
  handleClickViewAll = (blockSK, plantingSK, recordType) => {
    let selectedBlock = this.props.getFromGlobalState('blocks').find(block=>block.sk===blockSK);
    let selectedPlanting = selectedBlock.plantings.find(planting =>planting.sk === plantingSK);
    // this.setState({ });
    this.setState({recordExpanded:{plantingSK: plantingSK, recordType: recordType} ,selectedBlock: selectedBlock,selectedPlanting:selectedPlanting,showAllDialogOpen: true,viewRecordType:recordType, isNew: false, selectedBlockSK: blockSK, selectedPlantingSK: plantingSK });
  }

  handleUpdateRecord(recordIn,parentSK) {
    updateStateRecord(recordIn,parentSK,this.props.getFromGlobalState,this.props.setGlobalState,this.state.selectedBlockSK,this.state.selectedPlantingSK);
  }

 

  // records done
  handleRecordExpanded = (plantingSK, recordType,event) => {
    event.stopPropagation();
    // if (this.state.recordExpanded.plantingSK === plantingSK && this.state.recordExpanded.recordType === recordType) {
    // /   this.setState({ recordExpanded:{plantingSK: "", recordType: ""} });
    // }
    // else {
    this.setState({ recordExpanded:{plantingSK: plantingSK, recordType: recordType} });
    // }
  }

  setClose() {
    
    this.setState({openDialog: ''})
    
  }
  setCloseReport() {
    
    this.setState({reportDialogOpen: false})
    
  }

  setCloseViewAll() {
    this.setState({showAllDialogOpen:false})
  }

  renderPlantings(plantings, classes)
  {
    return (
      <>
    {plantings.map (planting => 
      <ListItem key={planting.sk} button className={classes.nested}>
        <ListItemIcon>
          <StarBorder />
        </ListItemIcon>
        <ListItemText primary={planting.Val} />
      </ListItem>)}
      </>)


  }
  
  getStatusIcon = (status,classes) => {
    if (status === true)
    {
      return ( <Tooltip placement="top" title={`In Pre-harvest Interval`}><Avatar className={classes.redAvatar}><checkIcon /></Avatar></Tooltip>)
    }
    else {
      return (<Tooltip placement="top" title={`Not in Pre-harvest Interval`}><Avatar className={classes.greenAvatar}><crossIcon /></Avatar></Tooltip>);

    }
    
  }
  getIrrigationName(key) {
    let name = getNameFromKey('irrigations',key,this.props.getFromGlobalState);
    return name;
  }

  renderBlocksList(blocks, classes) {
    
    return blocks.map(
      (block) =>
          <Grid key={block.sk} style={{padding:"3px"}} item xs={12} sm={12} md={4} lg={4} xl={4}>
          <SimpleCard
           expanded = {this.state.selectedBlock && this.state.selectedBlock.sk === block.sk ? true: undefined}
          disableContentPadding
          title={`${block.number}`}
          ariaLabel="Block" 
          subHeader={`${block.size} ha, ${block.slope} slope, ${block.soilType}, ${(block.irrigated ? (block.irrigated==='irrigated' ? block.volumePerHour + 'l/hour' : 'Dry land') : block.volumePerHour  + 'l/hour') } `} 
            
            // avatar={this.getStatusIcon(inPHI(block),classes)}
            avatar = {
              <><Status status={block.status} hideIfGreen={false} size='medium'/>
              {(block.cached && !block.syncError) && <CachedIcon className={classes.cached} />}
              {(block.cached && block.syncError) && <SyncErrorIcon className={classes.syncError} />}
              </>
            }
          /*action={<IconButton aria-label="Edit"
          color="primary"
            className={classes.menuButton}
            onClick={this.handleClickShow.bind(this,block)}
            >
            <EditIcon />
          </IconButton>} */
            
        
           handleExpandClick={this.handleExpandBlock.bind(this,block)}
           expandedContent={ <PlantingItems
              plantings= {block.plantings}
              handleExpandClick={this.handlePlantingExpand}
              expandedPlanting={this.state.expanded}
              onClickAdd={this.handleClickAddNewPlanting}
              blockSK={block.sk}
              onClickView={this.handleClickShowPlanting}
              onClickViewRecord= {this.handleClickShowRecord}
              onClickViewTableItem = {this.handleClickShowTableRecord}
              onClickAddRecord= {this.handleClickAddNewRecord}
              onClickViewReport= {this.handleClickViewReport}
              onClickViewAll={this.handleClickViewAll}
              showAll={this.state.showAllDialogOpen}
              // handleExpandRecordClick={this.handleRecordExpanded}
              handleExpandRecordClick={this.handleClickViewAll}
              expandedRecord={this.state.recordExpanded}
              settings={this.props.getFromGlobalState('crops')}
              getFromGlobalState={this.props.getFromGlobalState}
              setCloseShowAll={this.setCloseViewAll.bind(this)}
              
              block={block}
              />}
              
              >
                
          </SimpleCard>
          </Grid>
      
    );
  }
  

  renderLander() {
    return (
      <div className="lander">
        <h1>Land Diary</h1>
        <p>Coming Soon</p>
        <div>
          <Link color="primary" to="/login">
            Login
          </Link>
          <Link to="/signup" className="btn btn-success btn-lg">
            Signup
    </Link>
    </div> 
      </div>
    );
  }
  async refresh(){
    this.setState({isLoading:true});
   // await this.props.refreshState();
   await this.props.sync(this.props.getFromGlobalState('activeSite'));
    this.setState({isLoading:false});
  }

  blockReportPDF () {
  
    this.setState({ pdf: true });
  } 


  blockReportCSV () {
  
    this.setState({ csv: true });
  } 
   handleSwitchFarm = async (event) => {
    const activeSite = event.target.value;
    const activeSiteCurr = this.props.getFromGlobalState('activeSite');
    if (event.target.value !== activeSiteCurr){
   // this.props.setGlobalState ({activeSite}) ;
    await this.props.refreshState(activeSite);
    await this.props.sync(activeSite);
    }
  }

  onDownloaded () {
    this.setState({ pdf: false });
  }

  onDownloadedCSV () {
    this.setState({ csv: false });
  }
  getBlockItems = (blocks,crops,irrigationTypes,farm) => {
   
    const blockCollection = getBlockCollection(blocks,crops,irrigationTypes,farm);
    let rows = blockCollection.retVal;
   
    let headCells = [];
    let headCellsClean = [];

    let totalSize = blockCollection.totalSize;

  

    headCells = [
      {
        id: 'country', numeric: false, disablePadding: false, label: 'Country', minWidth: '30px',
      },
      {
        id: 'province', numeric: false, disablePadding: false, label: 'Province', minWidth: '30px',
      },
      {
        id: 'farmName', numeric: false, disablePadding: false, label: 'farm Name', minWidth: '30px',
      },
      {
        id: 'blockNumber', numeric: false, disablePadding: false, label: 'Block no', minWidth: '30px',
      },
      {
        id: 'blockSize', numeric: false, disablePadding: false, label: 'Block Size (ha)', minWidth: '30px',
      },
      {
        id: 'soilType', numeric: false, disablePadding: false, label: 'Soil Type',
      },
      {
        id: 'slope', numeric: false, disablePadding: false, label: 'Slope',
      },
      {
        id: 'cropName', numeric: false, disablePadding: false, label: 'Crop', minWidth: '70px',
      },
      {
        id: 'varietyName', numeric: false, disablePadding: false, label: 'Variety', minWidth: '70px',
      },
      {
        id: 'plantingName', numeric: false, disablePadding: false, label: 'Planting Name', minWidth: '30px',
      },
      {
        id: 'plantingSize', numeric: false, disablePadding: false, label: 'Planting Size (ha)', minWidth: '70px',
      },
      {
        id: 'plantingDate', numeric: false, disablePadding: false, label: 'Established', minWidth: '70px',
      },
      {
        id: 'plantSpacing', numeric: false, disablePadding: false, label: 'Plant Spacing (m)', minWidth: '70px',
      },
      {
        id: 'rowSpacing', numeric: false, disablePadding: false, label: 'Row Spacing (m)', minWidth: '70px',
      },
      {
        id: 'totalPlants', numeric: false, disablePadding: false, label: 'Plants', minWidth: '70px',
      },
      {
        id: 'irrigationType', numeric: false, disablePadding: false, label: 'Irrigation', minWidth: '70px',
      },
      {
        id: 'irrigationFlowRate', numeric: false, disablePadding: false, label: 'Flow rate (l/h)', minWidth: '70px',
      },
      {
        id: 'irrigationFlowRateUnit', numeric: false, disablePadding: false, label: 'Flow rate unit', minWidth: '70px',
      },
      {
        id: 'latitude', numeric: false, disablePadding: false, label: 'Latitude [middle point of the block coordinate]', minWidth: '70px',
      },
      {
        id: 'longitude', numeric: false, disablePadding: false, label: 'Longitude [middle point of the block coordinate]', minWidth: '70px',
      }
    ];
    return ({
      rows: rows, headCells: headCells, totalSize,
    });
  }

  
  // async reload () {

  // }
  renderBlocks(classes) {
    // test
    statusEngine(this.props.getFromGlobalState('blocks'));
    let farm = this.props.getFromGlobalState('farm')[0];
    const farms = this.props.getFromGlobalState('farms');
    console.log('farms',this.props.getFromGlobalState('farm'));
    let blocks = this.props.getFromGlobalState('blocks');
    let crops = this.props.getFromGlobalState('crops');
    const siteListUnfiltered = this.props.getFromGlobalState('siteList');
    const siteList = siteListUnfiltered.filter((s)=> !s.isDisabled || s.isDisabled !== true);
    const activeSite = this.props.getFromGlobalState('activeSite');
    let irrigations = this.props.getFromGlobalState('irrigations');
    const isMobile = !window.matchMedia('(min-width: 850px)').matches;
    blocks = blocks && blocks.sort((a, b) => ("" + a.number).localeCompare(b.number, undefined, {numeric: true}));
    const blockItems = (this.state.pdf || this.state.csv) && this.getBlockItems(blocks, crops,irrigations,farm);
    const { industryView, history } = this.props;
    if (industryView){
      //const history = useHistory();
      history.push('/census');
    }
    // test
    return (
     
        <Paper elevation={0} className={classes.paper}>

        
        
        <Grid container className={classes.root} spacing={2} alignItems="flex-start"> 
        <Grid className={classes.searchBar} key='search' style={{padding:"3px",marginBottom:'3px'}} item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Paper > <SearchBar 
              pdfRequested={this.state.pdf} 
              csvRequested={this.state.csv}
              onRefresh={this.refresh.bind(this)} 
              siteList= {siteList}
              activeSite={activeSite}
              getFromGlobalState={this.props.getFromGlobalState}
              isMobile={isMobile}
              onSwitchFarm={this.handleSwitchFarm}
              onBlockReportPDF={this.blockReportPDF.bind(this)}
              onBlockReportCSV={this.blockReportCSV.bind(this)}
              reportPDF={<ReportBlockPDF onDownloaded={this.onDownloaded.bind(this)} color="secondary" className={classes.pdfButton} totalSize={blockItems.totalSize} farm={farm} headCells={blockItems.headCells} rows={blockItems.rows} getFromGlobalState={this.props.getFromGlobalState}/>}
              reportCSV={<ReportBlockCSV onDownloaded={this.onDownloadedCSV.bind(this)} color="secondary" className={classes.pdfButton} totalSize={blockItems.totalSize} farm={farm} headCells={blockItems.headCells} rows={blockItems.rows} getFromGlobalState={this.props.getFromGlobalState}/>}

              /></Paper>
        </Grid>
        </Grid>
        <Load isloading={this.state.isLoading}>
        <Grid container className={classes.root2} spacing={2}  alignItems="flex-start"> 
          { this.renderBlocksList(this.props.getFromGlobalState('blocks'), classes)}
          
          {(this.state.openDialog ==='block') && 
          <BlockD
          handleUpdateBlocks={this.handleUpdateBlocks.bind(this) } 
          handleDeleteBlock={this.handleDeleteBlock.bind(this) }
          open={this.state.openDialog ==='block'} 
          setClose={this.setClose.bind(this)} 
          isNew={this.state.isNew} 
          block={this.state.selectedBlock}
          metaData= {this.props.getFromGlobalState('irrigations')}
          getFromGlobalState={this.props.getFromGlobalState}
           />}

          {(this.state.openDialog ==='planting') && 
          <PlantingD
          handleUpdatePlantings={this.handleUpdatePlantings.bind(this)} 
          handleDeletePlantings={this.handleDeletePlantings.bind(this)}
          allowDelete
          open={this.state.openDialog ==='planting'} 
          setClose={this.setClosePlanting.bind(this)} 
          isNew={this.state.isNew} 
          planting={this.state.selectedPlanting}
          blockSK={this.state.selectedBlockSK}
          block={this.state.selectedBlock} 
          getFromGlobalState={this.props.getFromGlobalState}
          metaData={{clusters: this.props.getFromGlobalState('clusters'), crops: this.props.getFromGlobalState('crops'), block: this.state.selectedBlock}}/>}

          {(this.state.openDialog ==='waterings') && 
          <RecordD
          handleUpdateRecord={this.handleUpdateRecord.bind(this)} 
          handleDeleteRecord={this.handleDeleteRecord.bind(this)}
          allowDelete
          open={this.state.openDialog ==='waterings'} 
          setClose={this.setClose.bind(this)} 
          isNew={this.state.isNew} 
          record={this.state.selectedRecord}
          blockSK={this.state.selectedBlockSK}
          block={this.state.selectedBlock}
          plantingSK={this.state.selectedPlantingSK}
          planting={this.state.selectedPlanting} 
          recordType={'waterings'}
          newTitle={'New Irrigation'}
          title={'Irrigation'}
          getFromGlobalState={this.props.getFromGlobalState}
          // inputData={{volumePerHour: this.state.selectedBlock.volumePerHour}}
          
          requiredFields={[{name:'date', min: 1, max: 40}, {name:'duration', min: 1, max: 40},{name:'operator', min: 1, max: 200}, {name:'actualLiters', min: 0, max: 10000000000000, type: 'number'}]}
          />}

          {(this.state.openDialog ==='fertilisings') && 
          <RecordD
          handleUpdateRecord={this.handleUpdateRecord.bind(this)} 
          handleDeleteRecord={this.handleDeleteRecord.bind(this)}
          allowDelete
          open={this.state.openDialog ==='fertilisings'} 
          setClose={this.setClose.bind(this)} 
          isNew={this.state.isNew} 
          record={this.state.selectedRecord}
          blockSK={this.state.selectedBlockSK}
          block={this.state.selectedBlock}
          plantingSK={this.state.selectedPlantingSK} 
          planting={this.state.selectedPlanting} 
          recordType={'fertilisings'}
          newTitle={'New Fertilising'}
          title={'Fertilising'}
          getFromGlobalState={this.props.getFromGlobalState}
          requiredFields={[{name:'product', min: 1, max: 100},{name:'operator', min: 1, max: 200},{name:'date', min: 1, max: 40}, {name:'volume', min: 1, max: 40},{name:'endTime', min: 1, max: 40}]}
          />}

          {(this.state.openDialog ==='pestings') && 
          <RecordD
          handleUpdateRecord={this.handleUpdateRecord.bind(this)} 
          handleDeleteRecord={this.handleDeleteRecord.bind(this)}
          allowDelete
          open={this.state.openDialog ==='pestings'} 
          setClose={this.setClose.bind(this)} 
          isNew={this.state.isNew} 
          setSnack={this.props.setSnack}
          record={this.state.selectedRecord}
          blockSK={this.state.selectedBlockSK}
          block={this.state.selectedBlock}
          plantingSK={this.state.selectedPlantingSK} 
          planting={this.state.selectedPlanting} 
          recordType={'pestings'}
          isMobile={this.props.isMobile}
          newTitle={'New Monitoring'}
          title={'Monitoring'}
          getFromGlobalState={this.props.getFromGlobalState}
          setGlobalState={this.props.setGlobalState}
          requiredFields={[{name:'date', min: 1, max: 40}]}
          />}

          {(this.state.openDialog ==='activities') && 
          <RecordD
          handleUpdateRecord={this.handleUpdateRecord.bind(this)} 
          handleDeleteRecord={this.handleDeleteRecord.bind(this)}
          allowDelete
          open={this.state.openDialog ==='activities'} 
          setClose={this.setClose.bind(this)} 
          isNew={this.state.isNew} 
          record={this.state.selectedRecord}
          blockSK={this.state.selectedBlockSK}
          setSnack={this.props.setSnack}
          block={this.state.selectedBlock}
          plantingSK={this.state.selectedPlantingSK} 
          planting={this.state.selectedPlanting} 
          recordType={'activities'}
          newTitle={'New Activity'}
          title={'Activity'}
          getFromGlobalState={this.props.getFromGlobalState}
          requiredFields={[{name:'activity', min: 1, max: 100},{name:'operator', min: 1, max: 200}, {name:'date', min: 1, max: 40}]}
          />}

          {(this.state.openDialog ==='sprayings') && 
          <RecordD
          handleUpdateRecord={this.handleUpdateRecord.bind(this)} 
          handleDeleteRecord={this.handleDeleteRecord.bind(this)}
          allowDelete
          open={this.state.openDialog ==='sprayings'} 
          setClose={this.setClose.bind(this)} 
          isNew={this.state.isNew} 
          setSnack={this.props.setSnack}
          record={this.state.selectedRecord}
          blockSK={this.state.selectedBlockSK}
          block={this.state.selectedBlock}
          plantingSK={this.state.selectedPlantingSK} 
          planting={this.state.selectedPlanting} 
          recordType={'sprayings'}
          newTitle={'New Plant Protection'}
          title={'Plant Protection'}
          getFromGlobalState={this.props.getFromGlobalState}
          requiredFields={[{name:'product', min: 1, max: 100},{name:'date', min: 1, max: 40},  {name:'chemrigs', min: 1, max: 200}, {name:'volume', min: 1, max: 100}, {name:'operator', min: 1, max: 200},{name:'weather', min: 1, max: 200}]}
          />}

          {(this.state.openDialog ==='harvestings') && 
          <RecordD
          handleUpdateRecord={this.handleUpdateRecord.bind(this)} 
          handleDeleteRecord={this.handleDeleteRecord.bind(this)}
          allowDelete
          open={this.state.openDialog ==='harvestings'} 
          setClose={this.setClose.bind(this)} 
          isNew={this.state.isNew} 
          setSnack={this.props.setSnack}
          record={this.state.selectedRecord}
          blockSK={this.state.selectedBlockSK}
          block={this.state.selectedBlock}
          plantingSK={this.state.selectedPlantingSK} 
          planting={this.state.selectedPlanting} 
          recordType={'harvestings'}
          newTitle={'New Harvesting'}
          title={'Harvesting'}
          getFromGlobalState={this.props.getFromGlobalState}
          requiredFields={[{name:'harvestEquip', min: 1, max: 100, condition:{name:'totalHarvestOverride',value:false}}, {name:'harvestUnits', min: 1, max: 40, condition:{name:'totalHarvestOverride',value:false}},{name:'harvestTotal', min: 1, max: 40}, {name:'date', min: 1, max: 40}]}
          />}

          {(this.state.openDialog ==='packings') && 
          <RecordD
          handleUpdateRecord={this.handleUpdateRecord.bind(this)} 
          handleDeleteRecord={this.handleDeleteRecord.bind(this)}
          allowDelete
          open={this.state.openDialog ==='packings'} 
          setClose={this.setClose.bind(this)} 
          isNew={this.state.isNew} 
          record={this.state.selectedRecord}
          blockSK={this.state.selectedBlockSK}
          block={this.state.selectedBlock}
          plantingSK={this.state.selectedPlantingSK} 
          planting={this.state.selectedPlanting} 
          recordType={'packings'}
          newTitle={'New Packing'}
          title={'Packing'}
          getFromGlobalState={this.props.getFromGlobalState}
          requiredFields={[{name:'destination', min: 1, max: 40},{name:'consignment', min: 1, max: 40}, {name:'packEquip', min: 1, max: 100}, {name:'packUnits', min: 1, max: 40},{name:'packTotal', min: 1, max: 40},{name:'wasteEquip', min: 1, max: 100},{name:'wasteUnits', min: 1, max: 40},{name:'wasteTotal', min: 1, max: 40}, {name:'date', min: 1, max: 40}]}
          />}

          {(this.state.reportDialogOpen === true) && 
          <ReportD
          handleUpdateRecord={this.handleUpdateRecord.bind(this)} 
          open={this.state.reportDialogOpen } 
          setClose={this.setCloseReport.bind(this)} 
          isNew={this.state.isNew} 
          showAll
          planting={this.state.selectedPlanting}
          block={this.state.selectedBlock}
          farm={farm}
          recordType={this.state.viewRecordType}
          getFromGlobalState={this.props.getFromGlobalState}
          title={friendlyActivityName[this.state.viewRecordType]}
         
          
          getFromGlobalState={this.props.getFromGlobalState}
          
          />}

        

         
          
         {/* }  <Fab
            size="medium"
            color="secondary"
            aria-label="Add"
            className={classes.fabFloat}
            onClick={this.handleClickAddNew.bind(this)}
            // component={Link} to="/blocks/new"
          >
        <AddIcon />
          </Fab> */}
      </Grid>
      </Load> 
      </Paper>
    
    );
  }

  render() {
    const {classes} = this.props;
    
    return (
      <>

       
        {this.props.isAuthenticated ? this.renderBlocks(classes) : this.renderLander()}
        
      </>
    );
  }
}
export default withStyles(styles)(Home);
