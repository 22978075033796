import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

export default function Terms(props) {
  const classes = useStyles();
  return (
    <Paper elevation={0} className={classes.paper}>
      <Typography>
        Land Diary is in Beta phase. This means not yet released for general use. Land Diary does not provide any guarantees during the Beta phase nor does it accept any liability. The terms and conditions will be updated for production release.
      </Typography>

    </Paper>
  );
}
Terms.propTypes = {
};
