import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import { CSVLink } from 'react-csv';
import LoaderFab from '../../components/LoaderFab';
import { getData } from '../../libs/analytics';
import { getToday, format } from '../../libs/genLib';

class ReportCSV extends Component {
  constructor(props) {
    super(props);

    this.state = {
      csvData: [],
      loading: false,
      title: '',
    };
  }

  componentDidMount() {
    const {
      recordType, getFromGlobalState, planting, startDate, endDate, block, rows, headCells,title,
    } = this.props;
    this.setState({ loading: true });

    const data = { rows, headCells, title };

    if (data.headCells) {
      data.headCells = data.headCells.filter((headCell) => headCell.type !== 'icon');
    }
    const csvData = [];
    csvData.push(data.headCells.map((headCell) => headCell.label));
    // get the data from rows. data that has a corresponding headCell name
    for (let i = 0; i < data.rows.length; i += 1) {
      const csvRow = [];
      for (let j = 0; j < data.headCells.length; j += 1) {
        csvRow.push(data.rows[i][data.headCells[j].id]);
      }
      csvData.push(csvRow);
    }
    this.setState({ csvData, loading: false, title: data.title });
  }

  render() {
    const {
      onDownloaded, farm,
    } = this.props;
    const { csvData, loading, title } = this.state;


    return (
      <CSVLink style={{ color: 'inherit' }} data={csvData} filename={`${farm.name} ${title} ${getToday()}`}>
        <LoaderFab
          key="csv"
          onClick={onDownloaded}
          success
          icon={<DownloadIcon />}
          color="inherit"
          loading={loading}
          disabled={loading}
        />
      </CSVLink>
    );
  }
}
ReportCSV.propTypes = {
  classes: PropTypes.object.isRequired,
  farm: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
  headCells: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  recordType: PropTypes.string.isRequired,
  recordTypeLabel: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  planting: PropTypes.array.isRequired,
  block: PropTypes.array.isRequired,
  farm: PropTypes.object.isRequired,
  onDownloaded: PropTypes.func.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  showAll: PropTypes.bool,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  blocks: PropTypes.array.isRequired,

};
export default (ReportCSV);
