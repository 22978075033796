import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tabs from '../../components/Tabs';
import FarmFilterBox from '../../components/FarmFilterBox';
import { recordTypes } from '../../libs/genLib';
import { flattenRecords } from '../../libs/analytics';
import FilterD from './filterSaveForm';
import MonitoringDetail from '../../components/MonitoringDetail';

import MapDashboard from './mapsForm';
import DataDashboard from './dataForm';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    marginBottom: theme.spacing(10),
    backgroundColor: 'inherit',
    // overflow: 'scroll',
  },
  heading: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  section: {
    margin: theme.spacing(2, 4),
  },

});

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 0,
      expandedFilter: '',
      filterSaveDialogOpen: false,
      selectedMonitoring: null,
      activeFilter: '',
      filteredMetaData: {

      },
      selectedData: {
        startDate: null,
        endDate: null,
        crops: [],
        clusters: [],
        blocks: [],
        plantings: [],
        recordTypes: [],
        monitorTypes: [],
        targetTypes: [],
        targets: [],
        stages: [],
        userGroups: [],
        users: [],
        cultivars: [],
      },
      flatItems: [],
      metaData: {
        clusters: [],
        blocks: [],
        crops: [],
        plantings: [],
        monitorTypes: [],
        recordTypes: [],
        targetTypes: [],
        stages: [],
        targets: [],
        userGroups: [],
        users: [],
      },
      filteredData: [],
    };
  }

  componentDidMount() {
    const { selectedData } = this.state;
    const { getFromGlobalState } = this.props;
    let newSelectedData = { ...selectedData };
    const filters = getFromGlobalState('filters');
    if (filters.length) {
      const filter = filters.find((f) => f.default === true);
      if (filter) {
        newSelectedData = { ...filter };
      }
    }
    const endDate = new Date();
    newSelectedData.endDate = endDate.toISOString().substr(0, 10);
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);
    newSelectedData.startDate = startDate.toISOString().substr(0, 10);
    const blocks = getFromGlobalState('blocks');
    // create our metaData
    const metaAndfilter = this.generateMetaDataAndFilter(blocks, newSelectedData);
    this.setState({
      filteredData: metaAndfilter.filteredData, metaData: metaAndfilter.metaData, selectedData: newSelectedData, activeFilter: newSelectedData.sk ? newSelectedData.sk : '',
    });
  }

  distinctPush = (array, member) => {
    const idx = array.findIndex((p) => p.sk === member.sk);
    if (idx === -1) {
      array.push(member);
    }
  }

  generateMetaDataAndFilter = (blocks, selectedData, arrayName) => {
    const { metaData } = this.state;
    const { getFromGlobalState } = this.props;
    const newMetaData = { ...metaData };
    const newSelectedData = { ...selectedData };
    const monitoringSettings = getFromGlobalState('monitorings');
    const targetTypes = getFromGlobalState('targetsTypesSetting');
    const filteredData = [];
    let tempBlocks = [...blocks];

    if (arrayName) {
      if (arrayName === 'dateRange') {
      //  newMetaData.recordTypes = [];
      //  newSelectedData.recordTypes = [];
      //  newMetaData.monitorTypes = [];
      //  newSelectedData.monitorTypes = [];
        // newMetaData.targetTypes = [];
        // newSelectedData.targetTypes = [];
      }
      if (arrayName === 'clusters') {
        newMetaData.blocks = [];
        newMetaData.plantings = [];
        newSelectedData.blocks = [];
        newSelectedData.plantings = [];
      //  newMetaData.recordTypes = [];
      //  newSelectedData.recordTypes = [];
      //  newMetaData.monitorTypes = [];
      //  newSelectedData.monitorTypes = [];
      //  newMetaData.targetTypes = [];
      //  newSelectedData.targetTypes = [];
      }
      if (arrayName === 'blocks') {
        newMetaData.plantings = [];
        newSelectedData.plantings = [];
        // newMetaData.recordTypes = [];
        // newSelectedData.recordTypes = [];
        // newMetaData.monitorTypes = [];
        // newSelectedData.monitorTypes = [];
      //  newMetaData.targetTypes = [];
      //  newSelectedData.targetTypes = [];
      }
      if (arrayName === 'userGroups') {
        newMetaData.monitorTypes = [];
        newSelectedData.monitorTypes = [];
        newMetaData.targetTypes = [];
        newSelectedData.targetTypes = [];
        newMetaData.stages = [];
        newSelectedData.stages = [];
        newMetaData.users = [];
        newSelectedData.users = [];
        newSelectedData.recordTypes = [];
        newMetaData.recordTypes = [];
        newSelectedData.targets = [];
        newMetaData.targets = [];
        // newMetaData.userGroups = [];
      //  newSelectedData.userGroups = [];
      }
      if (arrayName === 'recordTypes') {
        newMetaData.monitorTypes = [];
        newSelectedData.monitorTypes = [];
        newMetaData.targetTypes = [];
        newSelectedData.targetTypes = [];
        newMetaData.stages = [];
        newSelectedData.stages = [];
      }
      if (arrayName === 'targetTypes') {
        newMetaData.targets = [];
        newSelectedData.targets = [];
      }
      if (arrayName === 'targets') {
        newMetaData.stages = [];
        newSelectedData.stages = [];
      }
    }

    if (newSelectedData.blocks.length) {
      tempBlocks = blocks.filter((f) => newSelectedData.blocks.indexOf(f.sk) !== -1);
    }

    let clusters = [];
    for (let b = 0; tempBlocks && b < tempBlocks.length; b += 1) {
      const block = { ...tempBlocks[b] };
      const plantings = [...block.plantings];
      block.plantings = [];
      let hasPlantings = false;
      for (let p = 0; plantings && p < plantings.length; p += 1) {
        const planting = { ...plantings[p] };
        let stillIn = true;

        planting.cluster = planting.cluster ? planting.cluster : 'Unassigned';

        if (stillIn && newSelectedData.crops.length && !newSelectedData.crops.includes(planting.crop)) {
          stillIn = false;
        }
        if (stillIn && newSelectedData.clusters.length && !newSelectedData.clusters.includes(planting.cluster)) {
          stillIn = false;
        }
        if (stillIn && newSelectedData.plantings.length && !newSelectedData.plantings.includes(planting.sk)) {
          stillIn = false;
        }
        if (stillIn) {
          const recordTypesInDateRange = this.hasRecordInDateRange(selectedData, planting, getFromGlobalState('users'));
          for (let r = 0; recordTypesInDateRange.foundRecordTypes && r < recordTypesInDateRange.foundRecordTypes.length; r += 1) {
            const type = recordTypesInDateRange.foundRecordTypes[r];
            this.distinctPush(newMetaData.recordTypes, type);
          }
          const foundRecords = Object.keys(recordTypesInDateRange.foundRecords);
          for (let f = 0; f < foundRecords.length; f += 1) {
            const key = foundRecords[f];
            planting[key] = [...recordTypesInDateRange.foundRecords[key]];
          }

          for (let u = 0; u < recordTypesInDateRange.userGroups.length; u += 1) {
            const group = recordTypesInDateRange.userGroups[u];
            this.distinctPush(newMetaData.userGroups, group);
          }
          for (let u = 0; u < recordTypesInDateRange.usersMeta.length; u += 1) {
            const user = recordTypesInDateRange.usersMeta[u];
            this.distinctPush(newMetaData.users, user);
          }
        }
        if (stillIn && newSelectedData.userGroups.length) {
          const filteredGroups = this.filterUserGroups(selectedData, planting, getFromGlobalState('users'));
          const types = Object.keys(filteredGroups.foundRecords);
          for (let t = 0; t < types.length; t += 1) {
            const type = types[t];
            planting[type] = filteredGroups.foundRecords[type];
          }
          for (let u = 0; u < filteredGroups.userGroups.length; u += 1) {
            const group = filteredGroups.userGroups[u];
            this.distinctPush(newMetaData.userGroups, group);
          }
          for (let v = 0; v < filteredGroups.usersMeta.length; v += 1) {
            const user = filteredGroups.usersMeta[v];
            this.distinctPush(newMetaData.users, user);
          }
        }
        if (stillIn && newSelectedData.users.length) {
          const filteredUsers = this.filterUsers(selectedData, planting, getFromGlobalState('users'));
          const types = Object.keys(filteredUsers.foundRecords);
          for (let t = 0; t < types.length; t += 1) {
            const type = types[t];
            planting[type] = filteredUsers.foundRecords[type];
          }
        }
        if (stillIn && newSelectedData.recordTypes.length) {
          const filteredRecordTypes = this.filterRecordTypes(selectedData, planting);
          const types = Object.keys(filteredRecordTypes);
          for (let t = 0; t < types.length; t += 1) {
            const type = types[t];
            planting[type] = filteredRecordTypes[type];
          }
          // if (planting.pestings.length) {
          // this.distinctPush(newMetaData.monitorTypes, { name: 'Field', sk: 'Field' });
          // this.distinctPush(newMetaData.monitorTypes, { name: 'Fixed', sk: 'Fixed' });
          // }
        }
        if (stillIn && newSelectedData.recordTypes.length && newSelectedData.recordTypes.findIndex((rt) => rt === 'pestings') !== -1) {
          // this.distinctPush(newMetaData.monitorTypes, { name: 'Field', sk: 'Field' });
          const filteredTargetTypes = this.filterTargetTypes(planting, getFromGlobalState('monitorings'), getFromGlobalState('targetsTypesSetting'));
          for (let f = 0; filteredTargetTypes && f < filteredTargetTypes.length; f += 1) {
            const targetType = filteredTargetTypes[f];
            this.distinctPush(newMetaData.targetTypes, { sk: targetType.sk, name: targetType.name });
          }

          if (stillIn) {
            // filter out the target types and get the metadata
            const filteredObservations = this.filterTargets(planting, selectedData, getFromGlobalState('monitorings'), getFromGlobalState('targetsTypesSetting'));
            planting.pestings = filteredObservations.filteredPestings;// [];
            for (let ob = 0; ob < filteredObservations.targets.length; ob += 1) {
              this.distinctPush(newMetaData.targets, filteredObservations.targets[ob]);
            }
          }

          if (stillIn) {
            // filter out the target types and get the metadata
            const filteredObservationsByTarget = this.fiterTargetFin(planting, selectedData, getFromGlobalState('monitorings'), getFromGlobalState('targetsTypesSetting'));
            planting.pestings = filteredObservationsByTarget.filteredPestings;
            for (let ob = 0; ob < filteredObservationsByTarget.stages.length; ob += 1) {
              this.distinctPush(newMetaData.stages, filteredObservationsByTarget.stages[ob]);
            }
          }

          if (stillIn) {
            // filter out the target types and get the metadata
            const filteredObservationsByTargetStage = this.filterStages(planting, selectedData, getFromGlobalState('monitorings'), getFromGlobalState('targetsTypesSetting'));
            planting.pestings = filteredObservationsByTargetStage;
            // for (let ob = 0; ob < filteredObservations.targets.length; ob += 1) {
            //   this.distinctPush(newMetaData.targets, filteredObservations.targets[ob]);
            // }
          }
        }

        /* if (stillIn && newSelectedData.recordTypes.length && !this.hasRecordInDateRange(selectedData, planting)) {
          stillIn = false;
        } */
        if (stillIn) {
          hasPlantings = true;
          block.plantings.push(planting);
          this.distinctPush(newMetaData.plantings, { name: planting.name, sk: planting.sk });
          const clusters = getFromGlobalState('clusters') || [];
          (planting?.clusters||[]).map((x)=>{
            const clustersks = clusters.map(z=>z.sk);
            const location = clustersks.indexOf(x.sk);
            if(location > -1){
              this.distinctPush(newMetaData.clusters, {name: clusters[location].name, sk: x.sk})
            }
         })
          // add the names to newMetaData, with name and sk, then combine the filter data, 
        }

        // new filter to handle selected clusters
        if(arrayName === 'clusters'){
          // console.log("cluster baby", planting, block); 
          const selectedClusters = selectedData.clusters ||[];
          selectedClusters.map((x)=>{
            const clusters = (planting?.clusters||[]).map((x)=>x.sk);
            if(clusters.includes(x)){
              hasPlantings = true;
            }
          })
        }

      }
      if (hasPlantings) {
        filteredData.push(block);
        this.distinctPush(newMetaData.blocks, { name: block.number, sk: block.sk });
      }
    }
    newMetaData.users = selectedData.userGroups.length > 0 ? newMetaData.users.filter((el) => selectedData.userGroups.some((f) => f === el.group)) : newMetaData.users;
    console.log("filtered-data", filteredData);
    return { filteredData, metaData: newMetaData, selectedData: newSelectedData };
  }

  filterStages = (planting, selectedData, monitorings, targetTypes) => {
    const filteredPestings = [];
    // const stages = [];
    for (let p = 0; p < planting.pestings.length; p += 1) {
      const pesting = planting.pestings[p];
      const localPesting = { ...pesting };
      localPesting.observations = [];
      // localPesting.geoLocations = [];
      for (let o = 0; pesting.observations && o < pesting.observations.length; o += 1) {
        const observation = pesting.observations[o];
        // const monitoring = monitorings.find((m) => observation.monitoringSK === m.sk);
        // const targetType = targetTypes.find((t) => t.sk === monitoring.targetType);
        if (selectedData.stages.length) {
          if (selectedData.stages.findIndex((tt) => tt === observation.stageSK) !== -1) {
            localPesting.observations.push(observation);
            // localPesting.geoLocations.push(pesting.geoLocations[o]);
            // this.distinctPush(stages, { sk: monitoring.targetDetail.sk, name: monitoring.targetDetail.name });
          }
        } else {
          localPesting.observations.push(observation);
          // this.distinctPush(stages, { sk: monitoring.targetDetail.sk, name: monitoring.targetDetail.name });
        }
      }
      if (localPesting.observations.length > 0) {
        filteredPestings.push(localPesting);
      }
    }
    return filteredPestings;
  }

  fiterTargetFin = (planting, selectedData, monitorings, targetTypes) => {
    const filteredPestings = [];
    const stages = [];
    for (let p = 0; p < planting.pestings.length; p += 1) {
      const pesting = planting.pestings[p];
      const localPesting = { ...pesting };
      localPesting.observations = [];
      // localPesting.geoLocations = [];
      for (let o = 0; pesting.observations && o < pesting.observations.length; o += 1) {
        const observation = pesting.observations[o];
        if (selectedData.targets.length) {
          if (selectedData.targets.findIndex((tt) => tt === observation.targetDetailSK) !== -1) {
            localPesting.observations.push(observation);
            const monitoring = monitorings.find((m) => observation.monitoringSK === m.sk);
            if (monitoring.cropSpecific === 'Yes') {
              const stage = monitoring.targetDetail.observableOn[0].stages.find((s) => s.sk === observation.stageSK);
              this.distinctPush(stages, { sk: stage.sk, name: stage.stageDescription });
            }
          }
        } else {
          localPesting.observations.push(observation);
        }
      }
      if (localPesting.observations.length > 0) {
        filteredPestings.push(localPesting);
      }
    }
    return { filteredPestings, stages };
  }

  filterTargets = (planting, selectedData, monitorings, targetTypes) => {
    const filteredPestings = [];
    const targets = [];
    for (let p = 0; p < planting.pestings.length; p += 1) {
      const pesting = planting.pestings[p];
      const localPesting = { ...pesting };
      localPesting.observations = [];
      // localPesting.geoLocations = [];
      for (let o = 0; pesting.observations && o < pesting.observations.length; o += 1) {
        const observation = pesting.observations[o];
        const monitoring = monitorings.find((m) => observation.monitoringSK === m.sk);
        if (!monitoring) {
          console.log('LD error: monitoring not found monitoringSK', observation.monitoringSK);
        } else {
          const targetType = (targetTypes||[]).find((t) => t.sk === monitoring.targetType);
          if (selectedData.targetTypes.length) {
            if (selectedData.targetTypes.findIndex((tt) => tt === targetType.sk) !== -1) {
              localPesting.observations.push(observation);
              // localPesting.geoLocations.push(pesting.geoLocations[o]);
              this.distinctPush(targets, { sk: monitoring.targetDetail.sk, name: monitoring.targetDetail.name });
            }
          } else {
            localPesting.observations.push(observation);
            this.distinctPush(targets, { sk: monitoring.targetDetail.sk, name: monitoring.targetDetail.name });
          }
        }
        if (localPesting.observations.length > 0) {
          filteredPestings.push(localPesting);
        }
      }
    }
    return { filteredPestings, targets };
  }

  filterTargetTypes = (planting, monitorings, targetTypes) => {
    const targetTypesFound = [];
    for (let p = 0; p < planting.pestings.length; p += 1) {
      const pesting = planting.pestings[p];
      for (let o = 0; pesting.observations && o < pesting.observations.length; o += 1) {
        const observation = pesting.observations[o];
        const monitoring = monitorings.find((m) => observation.monitoringSK === m.sk);
        if (!monitoring) {
          console.log('LD error: monitoring not found monitoringSK', observation.monitoringSK);
        } else {
          const targetType = targetTypes?.find((t) => t.sk === monitoring.targetType);
          this.distinctPush(targetTypesFound, { sk: targetType.sk, name: targetType.name });
        }
      }
    }
    return targetTypesFound;
  }

  filterMonitorings = (arr, monitorType, planting) => {
    const plantingCrop = planting.crop;
    const monitorings = [];
    for (let i = 0; i < arr.length; i += 1) {
      if (arr[i].targetDetail) {
        if (arr[i].targetDetail.observableOn[0]) {
          if (arr[i].cropSpecific === 'Yes' && arr[i].targetDetail.observableOn[0].sk === plantingCrop) {
            const stages = arr[i].targetDetail.observableOn[0].stages.filter((f) => f.monitorType === monitorType);
            if (stages.length > 0) {
              const localMonitor = { ...arr[i] };
              localMonitor.targetDetail.observableOn[0].stages = [...stages];
              monitorings.push(localMonitor);
            }
          }
        } else if (arr[i].targetDetail.nonCropDetail.monitorType === monitorType) {
          const localMonitor = { ...arr[i] };
          monitorings.push(localMonitor);
        }
      }
    }
    return monitorings;
  };

  filterUsers = (selectedData, planting) => {
    let hasRecords = false;
    const foundRecords = {};
    const r = recordTypes;

    Object.keys(r).forEach((key) => {
      if (planting[key] && planting[key].length) {
        const records = planting[key].filter((el) => selectedData.users.some((f) => f === el.createdByDetail.email));
        if (records.length) {
          foundRecords[key] = records;
          hasRecords = true;
        } else {
          foundRecords[key] = [];
        }
      } else {
        foundRecords[key] = [];
      }
    });

    return ({
      hasRecords, foundRecords,
    });
  }

  filterUserGroups = (selectedData, planting, users) => {
    let hasRecords = false;
    const foundRecords = {};
    const groupDictionary = {};
    const userGroups = [];
    const usersMeta = [];
    const r = recordTypes;

    const localUsers = users.map((u) => {
      const ob = { ...u };
      if (!ob.group) {
        ob.group = 'Unassisgned';
      }
      return ob;
    });
    const filteredUsers = localUsers.filter((el) => selectedData.userGroups.some((f) => f === el.group));
    Object.keys(r).forEach((key) => {
      if (planting[key] && planting[key].length) {
        const records = planting[key].filter((el) => filteredUsers.some((f) => f.email === el.createdByDetail.email));
        if (records.length) {
          foundRecords[key] = records;
          hasRecords = true;
          for (let r = 0; r < records.length; r += 1) {
            const record = records[r];
            if (!groupDictionary[record.createdBy]) {
              const user = users.find((u) => u.email === record.createdByDetail.email);
              const groupName = user?.group ? user?.group : 'Unassisgned';
              this.distinctPush(userGroups, { sk: groupName, name: groupName });
              this.distinctPush(usersMeta, { sk: user?.email, name: `${user?.name} ${user.surname}`, group: groupName });
              groupDictionary[record.createdBy] = user;
            } else {
              const user = groupDictionary[record.createdBy];
              const groupName = user?.group ? user?.group : 'Unassisgned';
              this.distinctPush(userGroups, { sk: groupName, name: groupName });
              this.distinctPush(usersMeta, { sk: user?.email, name: `${user?.name} ${user.surname}`, group: groupName });
            }
          }
        } else {
          foundRecords[key] = [];
        }
      } else {
        foundRecords[key] = [];
      }
    });

    return ({
      hasRecords, foundRecords, userGroups, usersMeta,
    });
  }

  filterRecordTypes = (selectedData, planting) => {
    const foundRecords = {};
    if (selectedData.recordTypes.length) {
      for (let r = 0; r < selectedData.recordTypes.length; r += 1) {
        const recordType = selectedData.recordTypes[r];
        foundRecords[recordType] = planting[recordType];
      }

      // clear the rest
      const availableTypes = Object.keys(recordTypes);
      for (let t = 0; t < availableTypes.length; t += 1) {
        const type = availableTypes[t];
        if (!foundRecords[type]) {
          foundRecords[type] = [];
        }
      }
      return foundRecords;
    }
  }

  hasRecordInDateRange = (selectedData, planting, users) => {
    const foundRecordTypes = [];
    const userGroups = [];
    const usersMeta = [];
    const groupDictionary = {};
    let hasRecords = false;
    const foundRecords = {};
    const r = recordTypes;
    Object.keys(r).forEach((key) => {
      if (planting[key] && planting[key].length) {
        const records = planting[key].filter((f) => f.date >= selectedData.startDate && f.date <= selectedData.endDate);
        if (records.length) {
          this.distinctPush(foundRecordTypes, { sk: key, name: recordTypes[key] });
          foundRecords[key] = records;
          hasRecords = true;
          for (let r = 0; r < records.length; r += 1) {
            const record = records[r];
            if (!groupDictionary[record.createdBy]) {
              const user = users.find((u) => u.email === record.createdByDetail.email);
              const groupName = user?.group ? user?.group : 'Unassisgned';
              this.distinctPush(userGroups, { sk: groupName, name: groupName });
              this.distinctPush(usersMeta, { sk: user?.email, name: `${user?.name} ${user?.surname}`, group: groupName });
              groupDictionary[record.createdBy] = user;
            } else {
              const user = groupDictionary[record.createdBy];
              const groupName = user?.group ? user?.group : 'Unassisgned';
              this.distinctPush(userGroups, { sk: groupName, name: groupName });
              this.distinctPush(usersMeta, { sk: user?.email, name: `${user?.name} ${user?.surname}`, group: groupName });
            }
          }
        } else {
          foundRecords[key] = [];
        }
      } else {
        foundRecords[key] = [];
      }
    });

    return ({
      hasRecords, foundRecordTypes, foundRecords, userGroups, usersMeta,
    });
  }

  handleTabChange = (event, newValue) => {
    const currentTab = this.state.selectedTab;
    if (newValue !== currentTab) {
      this.setState({ selectedTab: newValue });
    }
  }

  handleChange = (event) => {
    const { selectedData } = this.state;
    const { getFromGlobalState } = this.props;
    const newSelectedData = { ...selectedData };
    if (newSelectedData[event.target.id] !== event.target.value) {
      newSelectedData[event.target.id] = event.target.value;
      const blocks = getFromGlobalState('blocks');
      const metaAndfilter = this.generateMetaDataAndFilter(blocks, newSelectedData, 'dateRange');
      this.setState({ filteredData: metaAndfilter.filteredData, metaData: metaAndfilter.metaData, selectedData: metaAndfilter.selectedData });
    }
  }

  handleExpand = (setting) => {
    const { expandedFilter } = this.state;
    if (expandedFilter !== setting) {
      this.setState({ expandedFilter: setting });
    } else {
      this.setState({ expandedFilter: '' });
    }
  }

  handleSaveFilter = (event) => {
    this.setState({ filterSaveDialogOpen: true });
  }

  closeFilterSaveDialog = () => {
    this.setState({ filterSaveDialogOpen: false });
  }

  handleFilterChange = (event) => {
    const { activeFilter, selectedData } = this.state;
    let newSelectedData = { ...selectedData };
    const { getFromGlobalState } = this.props;
    if (event.target.value !== activeFilter) {
      const filters = getFromGlobalState('filters');
      const filter = filters.find((f) => f.sk === event.target.value);
      if (filter) {
        newSelectedData = { ...filter };
      }
      const blocks = getFromGlobalState('blocks');
      const metaAndfilter = this.generateMetaDataAndFilter(blocks, newSelectedData, '');
      this.setState({
        filteredData: metaAndfilter.filteredData, metaData: metaAndfilter.metaData, selectedData: metaAndfilter.selectedData, activeFilter: event.target.value,
      });
      // this.setState({ selectedData: newSelectedData, activeFilter: event.target.value });
    }
  }

  handleFilterUpdate =(object) => {
    const { getFromGlobalState, setGlobalState } = this.props;
    const filters = getFromGlobalState('filters');
    const newFilters = [...filters];
    const index = newFilters.findIndex((f) => f.sk === object.sk);
    if (index === -1) {
      newFilters.push(object);
    } else {
      newFilters[index] = object;
    }
    setGlobalState({ filters: newFilters });
  }

  handleMonitoringClick = (data, index) => {
    //   alert("clickedddddd");
    this.setState({ selectedMonitoring: data });
  };

  handleFertilisingClick = (data, index) => {
  }

  handleFilterToggle = (key, arrayName) => {
    const { selectedData } = this.state;
    const { getFromGlobalState } = this.props;
    const newSelectedData = { ...selectedData };
    const currentIndex = newSelectedData[arrayName].indexOf(key);

    if (currentIndex === -1) {
      newSelectedData[arrayName].push(key);
    } else {
      newSelectedData[arrayName].splice(currentIndex, 1);
    }
    const blocks = getFromGlobalState('blocks');
    const metaAndfilter = this.generateMetaDataAndFilter(blocks, newSelectedData, arrayName);
    this.setState({ filteredData: metaAndfilter.filteredData, metaData: metaAndfilter.metaData, selectedData: metaAndfilter.selectedData });
  }

  render() {
    const {
      classes,
      getFromGlobalState,
    } = this.props;

    const {
      selectedTab,
      expandedFilter,
      selectedData,
      metaData,
      filteredData,
      filterSaveDialogOpen,
      activeFilter,
      selectedMonitoring,
    } = this.state;
    const activeSite = getFromGlobalState('activeSite');

    return (
      <Grid container className={classes.root} spacing={1} alignItems="flex-start">
        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
          <FarmFilterBox
            metaData={metaData}
            expandedFilter={expandedFilter}
            handleExpand={this.handleExpand}
            selectedData={selectedData}
            handleChange={this.handleChange}
            handleToggle={this.handleFilterToggle}
            handleSaveFilter={this.handleSaveFilter}
            handleFilterChange={this.handleFilterChange}
            filters={getFromGlobalState('filters')}
            activeFilter={activeFilter}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
          <Tabs value={selectedTab} handleChange={this.handleTabChange}>
            <MapDashboard
              heading="Map"
              activeSite={activeSite}
              getFromGlobalState={getFromGlobalState}
              blocks={filteredData}
            />
            <DataDashboard
              heading="Data"
              activeSite={activeSite}
              getFromGlobalState={getFromGlobalState}
              blocks={filteredData}
              handleMonitoringClick={this.handleMonitoringClick}
              handleFertilisingClick={this.handleFertilisingClick}
            />
          </Tabs>
          {selectedMonitoring && <MonitoringDetail data={selectedMonitoring} />}
        </Grid>
        {filterSaveDialogOpen
        && (
        <FilterD
          filter={selectedData}
          open={filterSaveDialogOpen}
         // currentFilter={selectedData}
          setClose={this.closeFilterSaveDialog}
          isNew={activeFilter === ''}
          handleUpdate={this.handleFilterUpdate}
          getFromGlobalState={getFromGlobalState}
        />
        )}
      </Grid>

    );
  }
}
Dashboard.propTypes = {
  getFromGlobalState: PropTypes.func.isRequired,
  setGlobalState: PropTypes.func.isRequired,
};
export default withStyles(styles)(Dashboard);
