import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '5px',
    backgroundColor: 'inherit',
  },
});

class FarmsAdminForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
  }

  render() {
    const {classes } = this.props;
    return (

      <Paper elevation={0} className={classes.mainSection}>
        <div>Hello</div>
      </Paper>
    );
  }
}
FarmsAdminForm.propTypes = {
  handleSubmit: PropTypes.func,

};
export default withStyles(styles)(FarmsAdminForm);
