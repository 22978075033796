import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import OperatorSelector from '../../components/OperatorSelector';
import TargetsList from '../../components/TargetsList';
// import { calculateApplicationTotal } from '../../libs/analytics';

import { inputDateAndTimeToLocaleDate } from '../../libs/genLib';

const styles = (theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    // alignItems: 'center',
  },

  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    fontSize: '12px',
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',

  },
});
// todo make more efficient

const filterItems = (arr, selectedBlocks) => {
  const allChems = {};
  if (!selectedBlocks) { return; }
  for (const ob of selectedBlocks) {
    const plantingCrop = ob.plantingOB.crop;
    const allowedChemicals = [];
    arr.forEach((chemical) => {
      if (chemical.chemcrops) {
        const idx = chemical.chemcrops.findIndex((c) => c.crop === plantingCrop);
        if (idx !== -1) {
          allowedChemicals.push(chemical);
        }
      }
    });
    allChems[ob.planting] = [...allowedChemicals];
  }
  return allChems;
};

/* const calculateRequiredChemicalAmount = (chemicalSK, chemicals, planting, blockSize, explain) => {
  if (!chemicalSK) { return undefined; }
  const chemical = chemicals.find((c) => c.sk === chemicalSK);
  const chemcrop = findChemCrop(chemical, planting);
  const chemAmount = { amount: 0, unit: '' };
  if (!chemcrop) { return undefined; }

  if (chemcrop.totalUnit === 'Hectare') {
    chemAmount.amount = parseFloat(blockSize) * parseFloat(chemcrop.valperUnit);
    // chemAmount.unit = chemical.unit;
    chemAmount.unit = chemical.unit;
    chemAmount.totalUnit = 'Hectare';
    chemAmount.totalVolume = (Math.round((chemcrop.valperUnit / chemcrop.valper100l * blockSize * 100 * 100), 2) / 100);

    chemAmount.totalProductMessage = `Total chemical: ${chemAmount.amount.toLocaleString()} ${chemical.unit}`;
    chemAmount.totalVolumeMessage = `Total volume: ${(Math.round((chemcrop.valperUnit / chemcrop.valper100l * blockSize * 100 * 100), 2) / 100).toLocaleString()} Liter`;
    if (explain) {
      chemAmount.message = `Total undiluted chemical for the ${blockSize} ${chemcrop.totalUnit.toLocaleString()} planting: ${chemcrop.valperUnit.toLocaleString()} ${chemical.unit} per ${chemcrop.totalUnit} X ${blockSize} ${chemcrop.totalUnit.toLocaleString()} =  ${chemAmount.amount.toLocaleString()} ${chemical.unit}. At ${chemcrop.valper100l.toLocaleString()} ${chemAmount.unit} per 100L and a reccomendation of ${chemcrop.valperUnit.toLocaleString()} ${chemical.unit} per ${chemcrop.totalUnit}. The total diluted volume to apply is estimated at:
    ${chemcrop.valperUnit.toLocaleString()} / ${chemcrop.valper100l.toLocaleString()} x ${blockSize} ${chemcrop.totalUnit} x 100L = ${(Math.round((chemcrop.valperUnit / chemcrop.valper100l * blockSize * 100 * 100), 2) / 100).toLocaleString()} Liter`;
    }
  } else if (chemcrop.totalUnit === 'Plant') {
    chemAmount.amount = parseFloat(planting[0].totalPlants) * parseFloat(chemcrop.valperUnit);
    chemAmount.unit = chemical.unit;
    chemAmount.totalUnit = 'Plant';
    chemAmount.totalVolume = (Math.round((chemcrop.valperUnit / chemcrop.valper100l * planting[0].totalPlants * 100 * 100), 2) / 100);
    chemAmount.totalProductMessage = `Total chemical: ${chemAmount.amount.toLocaleString()} ${chemAmount.unit}`;
    chemAmount.totalVolumeMessage = `Total volume: ${chemAmount.totalVolume} Liter or ${(Math.round((chemcrop.valperUnit / chemcrop.valper100l * 100 * 100), 2) / 100).toLocaleString()} per Plant`;
    if (explain) {
      chemAmount.message = `Total undiluted chemical for the ${planting[0].totalPlants.toLocaleString()} ${chemcrop.totalUnit}s: ${chemcrop.valperUnit.toLocaleString()} ${chemical.unit} per ${chemcrop.totalUnit.toLocaleString()} X ${planting[0].totalPlants.toLocaleString()} ${chemcrop.totalUnit}s =  ${chemAmount.amount.toLocaleString()} ${chemAmount.unit}. At ${chemcrop.valper100l.toLocaleString()} ${chemAmount.unit} per 100L and a reccomendation of ${chemcrop.valperUnit.toLocaleString()} ${chemical.unit} per ${chemcrop.totalUnit}. The total diluted volume to apply is estimated at:
    ${chemcrop.valperUnit.toLocaleString()} / ${chemcrop.valper100l.toLocaleString()} x ${planting[0].totalPlants.toLocaleString()} ${chemcrop.totalUnit}s x 100L = ${(Math.round((chemcrop.valperUnit / chemcrop.valper100l * planting[0].totalPlants * 100 * 100), 2) / 100).toLocaleString()} Liter or ${(Math.round((chemcrop.valperUnit / chemcrop.valper100l * 100 * 100), 2) / 100).toLocaleString()} Liter per ${chemcrop.totalUnit}`;
    }
  } else {
    return undefined;
  }
  return chemAmount;
}; */

const findChemCrop = (chem, planting) => {
  if (chem === undefined) { return undefined; }
  if (planting === undefined) { return undefined; }
  if (chem.chemcrops === undefined) { return undefined; }

  const chemCrop = chem.chemcrops.find((cc) => cc.crop === planting.crop);
  return chemCrop;
};

const generateReasonList = (allowedChemicals, product, getFromGlobalState) => {
  if (!product) {
    return [];
  }

  const monitorings = getFromGlobalState('monitorings');
  // now filter all the targets that this chemical can be used for.
  const chemical = allowedChemicals.find((f) => f.sk === product);
  console.log(chemical?.chemcrops);
  const targets = chemical?.chemcrops && chemical.chemcrops[0].targets || [];

  const monitoringFiltered = monitorings.filter((el) => targets.some((f) => f.target === el.sk));

  return monitoringFiltered;
};

const getTotalVolume = (selectedBlocks, metaData) => {

  for (var ob of selectedBlocks) {
    if (!ob.record) continue;
    if (!ob.record.chemrigs) continue;
    if (!ob.record.numRigs) continue;

    if (ob.record.chemrigs && ob.record.numRigs) {
      const rig = metaData.chemequips.find((p) => p.sk === ob.record.chemrigs);
      ob.record.volume = rig.capacity * ob.record.numRigs;
    }
  }
};

const calculatePHIEnd = (selectedBlocks, chemicals) => {
  if (!chemicals) { return; }

  for (var ob of selectedBlocks) {
    if (!ob.record.product || !ob.record.endDate || !ob.record.date) { continue; }

    const chem = chemicals.find((c) => c.sk === ob.record.product);
    const chemCrop = findChemCrop(chem, ob.plantingOB);
    if (!chemCrop) { return; }
    const phidays = (chemCrop.targets||[]).map((x)=> x.phi)?.length > 0 && chemCrop.targets.map((x)=> x.phi)[0]
    ob.record.phiLapse = inputDateAndTimeToLocaleDate(ob.record.date, ob.record.time, phidays);
  }
};

function SprayingFormBatch(props) {
  const {
    classes,
    errors,
    handleBlur,
    expandedTarget,
    getFromGlobalState,
    firstFormValid,
    handleExpand,
    onApplyAll,
    metaData,
    selectedBlocks,
    handleBatchChange,
    handleBatchApplied,
    handleBatchToggleReasons,
  } = props;

  const allowedChemicals = filterItems(metaData.chemicals, selectedBlocks);
  getTotalVolume(selectedBlocks, metaData);

  /* let reasons = [];
  if (record.product) {
    reasons = generateReasonLis(allowedChemicals, record.product, getFromGlobalState);
  } */
  // record.date = record.date ? record.date: getToday();
  // record.time = record.time ? record.time : getNow();
  // record.endDate = record.endDate ? record.endDate : getToday();
  // if (record.endDate){
  //   record.endTime = record.endTime? record.endTime : getNow();

  calculatePHIEnd(selectedBlocks, metaData.chemicals);
  let isFirst = true;
  // }

  return (
   
<>
      {selectedBlocks && selectedBlocks.map((selectedBlock) => (
        <Card style={{ width: '100%', marginBottom: '15px' }}>
          <CardContent>
          <Grid container className={classes.root} spacing={1} alignItems="flex-start">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="h6" color="inherit" noWrap className={classes.location}>
                {selectedBlock.label}
              </Typography>

            </Grid>

            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <TextField
                margin="normal"
                required
        //  fullWidth
                type="date"
    // defaultValue={new Date()}
                id="date"
                label="Date"
                name="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={selectedBlock.record.date}
                onChange={handleBatchChange.bind(this, selectedBlock.planting, selectedBlock.block)}
                onBlur={handleBlur.bind(this, selectedBlock.planting)}
                error={errors[selectedBlock.planting] && errors[selectedBlock.planting].date ? true : undefined}
                helperText={errors[selectedBlock.planting] && errors[selectedBlock.planting].date}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
              <TextField
                margin="normal"
    // required
   // fullWidth
                type="time"
    // defaultValue={new Date()}
                id="endTime"
                label="End Time"
                name="endTime"
                InputLabelProps={{
                  shrink: true,
                }}
                value={selectedBlock.record.endTime}
                onChange={handleBatchChange.bind(this, selectedBlock.planting, selectedBlock.block)}
                onBlur={handleBlur.bind(this, selectedBlock.planting)}
                error={errors[selectedBlock.planting] && errors[selectedBlock.planting].endTime ? true : undefined}
                helperText={errors[selectedBlock.planting] && errors[selectedBlock.planting].endTime}
              />

            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="product">Plant Protection</InputLabel>
                <Select
                  native
                  fullWidth
                  required
                  displayEmpty
                  value={selectedBlock.record.product}
                  onChange={handleBatchChange.bind(this, selectedBlock.planting, selectedBlock.block)}
                  onBlur={handleBlur.bind(this, selectedBlock.planting)}
                  error={errors[selectedBlock.planting] && errors[selectedBlock.planting].product ? true : undefined}
                  inputProps={{
                    name: 'product',
                    id: 'product',
                  }}
                >
                  <option value="" />
                  {allowedChemicals[selectedBlock.planting] && allowedChemicals[selectedBlock.planting].map((chemical) => (
                    <>
                      <option value={chemical.sk}>{chemical.name}</option>
                    </>
                  ))}
                </Select>
                {errors[selectedBlock.planting] && errors[selectedBlock.planting].product && <FormHelperText className={classes.helperText}>{errors[selectedBlock.planting].product}</FormHelperText>}
              </FormControl>
            </Grid>
            {/**/}

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <InputLabel shrink htmlFor="reasons">Reasons</InputLabel>
              <TargetsList
                metaData={{ monitorings: selectedBlock.record.product ? generateReasonList(allowedChemicals[selectedBlock.planting], selectedBlock.record.product, getFromGlobalState) : [] }}
                onBlur={handleBlur.bind(this, selectedBlock.planting)}
                checkedItems={selectedBlock.record.reasons}
                handleToggle={handleBatchToggleReasons}
                getFromGlobalState={getFromGlobalState}
                handleExpand={handleExpand}
                expandedTarget={expandedTarget}
                planting={selectedBlock.planting}
                readOnly
                pickApplied
                handleApplied={handleBatchApplied}
          // memberName='targetDetail.name'
                arrayName="reasons"
              />
              <FormHelperText className={classes.helperText}>{errors.reasons}</FormHelperText>
              {errors[selectedBlock.planting] && errors[selectedBlock.planting].reasons && <FormHelperText className={classes.helperText}>{errors[selectedBlock.planting].reasons}</FormHelperText>}

            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="chemrigs">Equipment</InputLabel>
                <Select
                  native
                  fullWidth
                  required
                  displayEmpty
                  value={selectedBlock.record.chemrigs}
                  onChange={handleBatchChange.bind(this, selectedBlock.planting, selectedBlock.block)}
                  onBlur={handleBlur.bind(this, selectedBlock.planting)}
                  error={errors[selectedBlock.planting] && errors[selectedBlock.planting].chemrigs ? true : undefined}
                  inputProps={{
                    name: 'chemrigs',
                    id: 'chemrigs',
                  }}
                >
                  <option value="" />
                  {metaData.chemequips && metaData.chemequips.map((rig) => (
                    <>
                      <option value={rig.sk}>{rig.name}</option>
                    </>
                  ))}
                </Select>
                {errors[selectedBlock.planting] && errors[selectedBlock.planting].chemrigs && <FormHelperText className={classes.helperText}>{errors[selectedBlock.planting].chemrigs}</FormHelperText>}
              </FormControl>
            </Grid>

            {/*    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

              <FormControl className={classes.formControl}>
                <Typography> Application equipment  *</Typography>

                <SingleListControl

                  data={metaData.chemequips}

                  onBlur={handleBlur.bind(this, selectedBlock.planting)}
                  checkedItems={selectedBlock.record.chemrigs ? selectedBlock.record.chemrigs : []}
                  handleToggle={handleToggle}
         // handleToggle =  {handleToggle.bind(this,selectedBlock.planting,selectedBlock.block)}
                  arrayName="chemrigs"
                  planting={selectedBlock.planting}
                />
                <FormHelperText className={classes.helperText}>{errors.chemrigs}</FormHelperText>
                {errors[selectedBlock.planting] && errors[selectedBlock.planting].chemrigs && <FormHelperText className={classes.helperText}>{errors[selectedBlock.planting].chemrigs}</FormHelperText>}
              </FormControl>
            </Grid> */}

<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                margin="normal"
                required
                type="number"
                disabled={!selectedBlock.record.chemrigs}
                inputProps={{ min: '0.00001', step: 'any' }}
                
                id="numRigs"
                shrink
                label="Total number applied"
                name="numRigs"
                InputLabelProps={{
                  shrink: true,
                }}

                value={selectedBlock.record.numRigs}
                onChange={handleBatchChange.bind(this, selectedBlock.planting, selectedBlock.block)}
                onBlur={handleBlur.bind(this, selectedBlock.planting)}
                error={errors[selectedBlock.planting] && errors[selectedBlock.planting].numRigs ? true : undefined}
                helperText={errors[selectedBlock.planting] && errors[selectedBlock.planting].numRigs}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                margin="normal"
                required
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
                inputProps={{ min: '0.00001', step: 'any' }}
                fullWidth
                id="volume"
                label="Total volume in liters"
                name="volume"

                value={selectedBlock.record.volume}
                //onChange={handleChange.bind(this)}
                //onBlur={handleBlur.bind(this, selectedBlock.planting)}
                error={errors[selectedBlock.planting] && errors[selectedBlock.planting].volume ? true : undefined}
                helperText={errors[selectedBlock.planting] && errors[selectedBlock.planting].volume}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                type="number"

                InputLabelProps={{
                  shrink: true,
                  min: '0.00001',
                  step: 'any',
                }}
                id="volume"
                label="Total Volume Applied [Liters]"
                name="volume"

                value={selectedBlock.record.volume}
                onChange={handleBatchChange.bind(this, selectedBlock.planting, selectedBlock.block)}
                onBlur={handleBlur.bind(this, selectedBlock.planting)}
                error={errors[selectedBlock.planting] && errors[selectedBlock.planting].volume ? true : undefined}
                helperText={errors[selectedBlock.planting] && errors[selectedBlock.planting].volume}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="weather">Weather</InputLabel>
                <Select
                  native
                  fullWidth
                  required
                  value={selectedBlock.record.weather}
                  onChange={handleBatchChange.bind(this, selectedBlock.planting, selectedBlock.block)}

                  inputProps={{
                    name: 'weather',
                    id: 'weather',
                  }}
                >
                  <option value="" />
                  <option value="Sunny">Sunny</option>
                  <option value="Cloudy">Cloudy</option>
                  <option value="Night">Night</option>
                </Select>
              </FormControl>

            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="phiLapse">Withholding period end</InputLabel>
                <TextField
                  margin="normal"
        // required
                  fullWidth
                  id="phiLapse"
        // label="Pre-harvest interval (PHI) end"
                  name="phiLapse"
                  readOnly
                  value={selectedBlock.record.phiLapse}
        // onChange={handleChange.bind(this)}   

                  onBlur={handleBlur.bind(this, selectedBlock.planting)}
                  error={errors[selectedBlock.planting] && errors[selectedBlock.planting].phiLapse ? true : undefined}
                  helperText={errors[selectedBlock.planting] && errors[selectedBlock.planting].phiLapse}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <OperatorSelector
                onChange={handleBatchChange.bind(this, selectedBlock.planting, selectedBlock.block)}
                onBlur={handleBlur.bind(this, selectedBlock.planting)}
                operators={metaData.operators}
                title="Operator"
                value={selectedBlock.record.operator}
                team={selectedBlock.record.team}

                error={errors[selectedBlock.planting] && errors[selectedBlock.planting].operator}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

              <TextField
                margin="normal"
    // required
                fullWidth
                id="notes"
                label="Notes"
                name="notes"
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
                rowsMax="4"
                value={selectedBlock.record.notes}
                onChange={handleBatchChange.bind(this, selectedBlock.planting, selectedBlock.block)}
                onBlur={handleBlur.bind(this, selectedBlock.planting)}
                error={errors[selectedBlock.planting] && errors[selectedBlock.planting].notes ? true : undefined}
                helperText={errors[selectedBlock.planting] && errors[selectedBlock.planting].notes}
              />
            </Grid>
            {isFirst && (
            <Grid style={{ marginTop: '20px' }} item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={onApplyAll.bind(this, selectedBlock.planting, selectedBlock.block, selectedBlock.record)}
                disabled={firstFormValid}
              >
                Apply to All
              </Button>
            </Grid>
            )}
            {isFirst = false}
            </Grid>
          </CardContent>
        </Card>
      ))}
   </>
  );
}

SprayingFormBatch.propTypes = {
  record: PropTypes.object.isRequired,
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  metaData: PropTypes.object.isRequired,
  planting: PropTypes.object.isRequired,
  block: PropTypes.object.isRequired,
  handleToggle: PropTypes.func.isRequired,
  handleSwitchChange: PropTypes.func.isRequired,
  overrideCalculatedProduct: PropTypes.func.isRequired,
  overrideCalculatedVolume: PropTypes.func.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  selectedBlocks: PropTypes.array.isRequired,
  handleBatchChange: PropTypes.func.isRequired,
  handleCommonChange: PropTypes.func.isRequired,
  handleCommonBlur: PropTypes.func.isRequired,
  handleBatchToggleReasons: PropTypes.func.isRequired,
  handleBatchApplied: PropTypes.func.isRequired,
  onApplyAll: PropTypes.func.isRequired,
  firstFormValid: PropTypes.bool.isRequired,
  handleExpand: PropTypes.func.isRequired,
  expandedTarget: PropTypes.func.isRequired,
};

export default withStyles(styles)(SprayingFormBatch);
