import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import uuid from 'uuid';
import ExpansionPanelDetailed from '../../components/ExpansionPanelDetail';
import FarmDetailForm from './farmDetailForm';
import CropDetailForm from './cropDetailForm';
import ChemicalDetailForm from './chemicalDetailForm';
import FertiliserDetailForm from './fertiliserDetailForm';
import MonitoringDetailForm from './monitoringDetailForm';
import CombinationDetailForm from './combinationDetailForm';
import GenSetForm from './genSetForm';
import ChemEquipmentD from '../ChemEquipmentD';
import ApiKeyD from '../ApiKeyD';
import HarvestingEquipmentD from '../HarvestingEquipmentD';
import ClusterD from '../ClusterD';
import OperatorD from '../OperatorD';
import AgronomistActD from '../AgronomistActD';
import UserDetailForm from './userDetailForm';
import BlockDetailForm from './blockDetailForm';
import FarmsD from '../FarmsD';
import FacilityD from '../FacilityD';
import Load from '../../components/Load';
import { toStore, createObject } from '../../libs/storage';
import { arrayToString } from '../../libs/genLib';
import MyProductProcessorD from '../MyProductProcessorD';

const roles = [
  { name: 'Harvest', sk: 'harvestings' },
  { name: 'Irrigate', sk: 'waterings' },
  { name: 'Fertilise', sk: 'fertilisings' },
  { name: 'Monitor', sk: 'pestings' },
  { name: 'Agronomic Activities', sk: 'activities' },
  { name: 'Plant Protection', sk: 'sprayings' }];

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  headingDropper: {
    display: 'inherit',
    marginRight: 'auto',
    marginBottom: '15px',
  },
  paperDropperBody: {
    display: 'flex',
    margin: theme.spacing(2),
  },
  heading: {
    marginTop: '4px',
    cursor: 'pointer',
    // marginTop: '10px',
  },
  header: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  paper: {
    // marginTop: theme.spacing(8),
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'inherit',
    // alignItems: 'center',
    marginBottom: theme.spacing(10),
    // overflow: 'scroll',
  },
  section: {
    // margin: theme.spacing(1),
  },
  expand: {
    transform: 'rotate(0deg)',
    padding: '4px',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedSetting: '',
      /* settings:{
        farmDetail: {},
        crops: [{}] }, */
      errors: {},
      clean: false,

    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    const isMobile = !window.matchMedia('(min-width: 850px)').matches;
    const clean = isMobile;
    // const { getFromGlobalState, setGlobalState, setSnack } = this.props;
    this.setState({ clean });
  }

updateGlobalState = (object, type) => {
  const data = { ...this.props.getFromGlobalState('settings') };
  data[type] = object;
  this.props.setGlobalState({ settings: data });
}

  handleSubmit = async (dialogObject, event) => {
    event.preventDefault();

    this.setState({ isSubmitting: true });
    this.setState({ messageText: '' });
    console.log("submiting now");
    try {
      // let dialogObject = {...this.props.dialogObject};
      if (!dialogObject.sk) {
        // give him one
        dialogObject.sk = `init_set_crops_${uuid.v1()}`;
        dialogObject.type = 'settings';
        dialogObject.version = 0;
      }
      const version = dialogObject.version ? dialogObject.version : 0;
      dialogObject.version = version + 1;
      let creation = {};
      dialogObject.activeTenant = this.props.getFromGlobalState('activeSite');
      creation = await createObject(this.props.getFromGlobalState('currentUser').username, 'calls', toStore(dialogObject), this.props.getFromGlobalState('activeSite'));
      // await createObject(toStore(dialogObject));
      if (creation.status) {
        dialogObject.cached = creation.cached;
      }

      this.setState({ isSubmitting: false });
      this.setState({ success: true });
      // todo: look at this below, doesn't look right
      this.updateGlobalState(dialogObject, 'set_crops');
      // this.props.handleUpdateDialogObject(dialogObject, this.props.parentKey);
      
      this.props.setClose();
    } catch (e) {
      this.setState(
        {
          success: false,
          isSubmitting: false,
          messageText: (e.response ? `${e.response.data.error.message}, api src: ${e.response.data.src} ` : e.message),
        },
      );
    }
  }

  handleChange = (objectName, event) => {
    const data = { ...this.props.getFromGlobalState('settings') };
    data[objectName][event.target.id] = event.target.value;
    this.props.setGlobalState({ settings: data });
  }

  handleAddChange = (object, objectName, event) => {
    const data = { ...this.props.getFromGlobalState('settings') };
    if (!data[objectName]) {
      data[objectName] = [];
    }
    data[objectName].push(object);
    this.props.setGlobalState({ settings: data });
  }

  roleString = (operatorRoles) => {
    if (!operatorRoles) {
      return '';
    }
    let newRoles = roles.filter((role) => operatorRoles.includes(role.sk));
    newRoles = newRoles.map((r) => r.name);
    return arrayToString(newRoles);
  }

  handleArrayChange = (objectName, index, event) => {
    const data = { ...this.props.getFromGlobalState('settings') };
    const idx = data[objectName].findIndex((ob) => ob.sk === index);
    if (idx !== -1) {
      data[objectName][idx][event.target.id] = event.target.value;
      this.props.setGlobalState({ settings: data });
    }
  }

   comparer = (otherArray) => (current) => otherArray.filter((other) => other.tenantId === current.farmId).length === 0

  filterSites = (sites, farms) => {
    // find the farms that this oke has access to
    // var filtered = [];
    if (!farms) { return []; }

    const myArrayFiltered = farms.filter((el) => sites.some((s) => s.tenantId === el.newTenant));

    // const myArrayFiltered = farms.filter((el) => sites.some((f) => f.tenantId !== el.farmId));

    //   var onlyInA = farms.filter(this.comparer(sites));
    // var onlyInB = farms.filter(this.comparer(sites));
    return myArrayFiltered;
  }

  handleBlur = (event) => {
    // return this.validateField (event.target.id, this.props.requiredFields);
  }

  handleSettingExpand = (setting) => {
    if (this.state.expandedSetting !== setting) {
      this.setState({ expandedSetting: setting });
    } else {
      this.setState({ expandedSetting: '' });
    }
  }

  render() {
    const { classes, getFromGlobalState, sync } = this.props;
    const { expandedSetting, errors, clean } = this.state;
    // const crops = this.props.getFromGlobalState('crops');
    const farm = getFromGlobalState('farm');
    let siteList = getFromGlobalState('siteList');
    siteList = siteList.sort((a, b) => (`${a.shortName}`).localeCompare(b.shortName, undefined, { numeric: true }));
    let farms = this.props.getFromGlobalState('farms');
    farms = farms.sort((a, b) => (`${a.name}`).localeCompare(b.name, undefined, { numeric: true }));
    const isEntity = !!(farm && (farm[0] && farm[0].entityType && farm[0].entityType === 'entity'));
    return (
      <Load isloading={this.state.isloading}>
        <Paper elevation={0} className={classes.paper}>
          {/* <Typography component="h1" variant="h5" className={classes.header}>
        Settings
    </Typography> */}
          <ExpansionPanelDetailed
            heading={isEntity ? 'Entity Detail' : 'Farm Detail'}
            expanded={expandedSetting}
            handleExpand={this.handleSettingExpand}
          >
            <FarmDetailForm
              initialState={farm}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              errors={errors}
              setGlobalState={this.props.setGlobalState}
              getFromGlobalState={this.props.getFromGlobalState}
            />
          </ExpansionPanelDetailed>

          {!isEntity && (
          <>
            {' '}

            {/* <ExpansionPanelDetailed
       heading={'Irrigation'}
       expanded={expandedSetting}
       handleExpand={this.handleSettingExpand}
       >
      <IrrigationDetailForm
       initialState={this.props.getFromGlobalState('irrigations')}
       handleSubmit={this.handleSubmit}
       isSubmitting={this.state.isSubmitting}
       setGlobalState={this.props.setGlobalState}
       getFromGlobalState={this.props.getFromGlobalState}
      />
      </ExpansionPanelDetailed>  */}

            <ExpansionPanelDetailed
              heading="Blocks"
              expanded={expandedSetting}
              handleExpand={this.handleSettingExpand}
            >
              <BlockDetailForm
                clean={clean}
                initialState={this.props.getFromGlobalState('blocks')}
                farm={this.props.getFromGlobalState('farm')}
                handleChange={this.handleChange}
                handleBlur={this.handleBlur}
                errors={errors}
                setSnack={this.props.setSnack}
                setGlobalState={this.props.setGlobalState}
                getFromGlobalState={this.props.getFromGlobalState}
              />
            </ExpansionPanelDetailed>

            <ExpansionPanelDetailed
              heading="Clusters"
              expanded={expandedSetting}
              handleExpand={this.handleSettingExpand}
            >
              <GenSetForm
                initialState={this.props.getFromGlobalState('clusters')}
                handleSubmit={this.handleSubmit}
                isSubmitting={this.state.isSubmitting}
                setGlobalState={this.props.setGlobalState}
                getFromGlobalState={this.props.getFromGlobalState}
                settingDialog={<ClusterD />}
                settingName="clusters"
                settingHeading="Add"
                headCells={[
                  {
                    id: 'name', numeric: false, disablePadding: false, label: 'Group',
                  },
                  { /* {
                    id: 'capacity', numeric: true, disablePadding: false, label: 'Capacity in Kg',
                  }, */ },

                ]}
              />
            </ExpansionPanelDetailed>

            <ExpansionPanelDetailed
              heading="Crops"
              expanded={expandedSetting}
              handleExpand={this.handleSettingExpand}
            >
              <CropDetailForm
      // initialState={crops}
                handleSubmit={this.handleSubmit}
                isSubmitting={this.state.isSubmitting}
                setGlobalState={this.props.setGlobalState}
                getFromGlobalState={this.props.getFromGlobalState}
                setSnack={this.props.setSnack}
              />
            </ExpansionPanelDetailed>
            { /*   <ExpansionPanelDetailed
              heading="Irrigation"
              expanded={expandedSetting}
              handleExpand={this.handleSettingExpand}
            >
              <GenSetForm
                initialState={this.props.getFromGlobalState('irrigations')}
                handleSubmit={this.handleSubmit}
                isSubmitting={this.state.isSubmitting}
                setGlobalState={this.props.setGlobalState}
                getFromGlobalState={this.props.getFromGlobalState}
                settingDialog={<IrrigationD />}
                settingName="irrigations"
                settingHeading="Add"
                getFromGlobalState={this.props.getFromGlobalState}
                headCells={[
                  {
                    id: 'name', numeric: false, disablePadding: false, label: 'Name',
                  },

                ]}
              />
            </ExpansionPanelDetailed> */ }

            <ExpansionPanelDetailed
              heading="Monitoring"
              expanded={expandedSetting}
              handleExpand={this.handleSettingExpand}
            >
              <MonitoringDetailForm
                initialState={this.props.getFromGlobalState('monitorings')}
                handleSubmit={this.handleSubmit}
                isSubmitting={this.state.isSubmitting}
                setGlobalState={this.props.setGlobalState}
                getFromGlobalState={this.props.getFromGlobalState}
                setSnack={this.props.setSnack}
              />
            </ExpansionPanelDetailed>

            <ExpansionPanelDetailed
              // disabled
              heading="Plant Protection Products"
              expanded={expandedSetting}
              handleExpand={this.handleSettingExpand}
            >
              <ChemicalDetailForm
                initialState={this.props.getFromGlobalState('chemicals')}
                handleSubmit={this.handleSubmit}
                isSubmitting={this.state.isSubmitting}
                setGlobalState={this.props.setGlobalState}
                getFromGlobalState={this.props.getFromGlobalState}
                metaData={this.props.getFromGlobalState('crops')}
                sync={this.props.sync}
                activeSite={this.props.activeSite}
              />
            </ExpansionPanelDetailed>

            

            {/* <ExpansionPanelDetailed
       heading={'Pest and Disease'}
       expanded={expandedSetting}
       handleExpand={this.handleSettingExpand}
       >
      <GenSetForm
       initialState={this.props.getFromGlobalState('pests')}
       metaDataName='crops'
       handleSubmit={this.handleSubmit}
       isSubmitting={this.state.isSubmitting}
       setGlobalState={this.props.setGlobalState}
       getFromGlobalState={this.props.getFromGlobalState}
       settingDialog={<PestD/>}
       settingName='pests'
       settingHeading='Add'
       getFromGlobalState={this.props.getFromGlobalState}
       headCells = {[
        {
          id: 'name', numeric: false, disablePadding: false, label: 'Name',
        },
        {
          id: 'pestOrDisease', numeric: true, disablePadding: false, label: 'Pest or Desease',
        },

      ]}
      />
    </ExpansionPanelDetailed> */}

            {/* <ExpansionPanelDetailed
       heading={'Monitoring (other)'}
       expanded={expandedSetting}
       handleExpand={this.handleSettingExpand}
       >
      <MonitoringOtherDetailForm
       initialState={this.props.getFromGlobalState('monitoringsOther')}
       handleSubmit={this.handleSubmit}

       isSubmitting={this.state.isSubmitting}
       setGlobalState={this.props.setGlobalState}
       getFromGlobalState={this.props.getFromGlobalState}
      />
     </ExpansionPanelDetailed> */}

            <ExpansionPanelDetailed
             // disabled
              heading="Fertilisers"
              expanded={expandedSetting}
              handleExpand={this.handleSettingExpand}
            >
              <FertiliserDetailForm
                initialState={this.props.getFromGlobalState('fertilisers')}
                handleSubmit={this.handleSubmit}
                isSubmitting={this.state.isSubmitting}
                setGlobalState={this.props.setGlobalState}
                getFromGlobalState={this.props.getFromGlobalState}
                metaData={this.props.getFromGlobalState('crops')}
                setSnack={this.props.setSnack}
                isMobile={this.props.isMobile}
              />
            </ExpansionPanelDetailed>

            <ExpansionPanelDetailed
             // disabled
              heading="Combinations"
              expanded={expandedSetting}
              handleExpand={this.handleSettingExpand}
            >
              <CombinationDetailForm
                initialState={this.props.getFromGlobalState('combinations')}
                handleSubmit={this.handleSubmit}
                isSubmitting={this.state.isSubmitting}
                setGlobalState={this.props.setGlobalState}
                getFromGlobalState={this.props.getFromGlobalState}
                 metaData={{ fertilisers: this.props.getFromGlobalState('fertilisers'),chemicals: this.props.getFromGlobalState('chemicals') }}
                setSnack={this.props.setSnack}
                isMobile={this.props.isMobile}
              />
            </ExpansionPanelDetailed>
            <ExpansionPanelDetailed
              heading="Plant Protection Equipment"
              expanded={expandedSetting}
             // disabled
              handleExpand={this.handleSettingExpand}
            >
              <GenSetForm
                initialState={this.props.getFromGlobalState('chemequips')}
                handleSubmit={this.handleSubmit}
                isSubmitting={this.state.isSubmitting}
                setGlobalState={this.props.setGlobalState}
                getFromGlobalState={this.props.getFromGlobalState}
                settingDialog={<ChemEquipmentD />}
                settingName="chemequips"
                settingHeading="Add"
                metaDataName="chemicals"
                headCells={[
                  {
                    id: 'name', numeric: false, disablePadding: false, label: 'Name',
                  },
                  {
                    id: 'capacity', numeric: true, disablePadding: false, label: 'Capacity in liter',
                  },

                ]}
              />
            </ExpansionPanelDetailed>

            <ExpansionPanelDetailed
              heading="Harvesting"
              expanded={expandedSetting}
              handleExpand={this.handleSettingExpand}
            >
              <GenSetForm
                initialState={this.props.getFromGlobalState('harvestequips')}
                handleSubmit={this.handleSubmit}
                isSubmitting={this.state.isSubmitting}
                setGlobalState={this.props.setGlobalState}
                getFromGlobalState={this.props.getFromGlobalState}
                settingDialog={<HarvestingEquipmentD />}
                settingName="harvestequips"
                settingHeading="Add"
                headCells={[
                  {
                    id: 'name', numeric: false, disablePadding: false, label: 'Name',
                  },
                  {
                    id: 'capacity', numeric: true, disablePadding: false, label: 'Capacity in Kg',
                  },

                ]}
              />
            </ExpansionPanelDetailed>

            {/* <ExpansionPanelDetailed
       heading={'Packing'}
       expanded={expandedSetting}
       handleExpand={this.handleSettingExpand}
       >
      <GenSetForm
       initialState={this.props.getFromGlobalState('packequips')}
       handleSubmit={this.handleSubmit}
       isSubmitting={this.state.isSubmitting}
       setGlobalState={this.props.setGlobalState}
       getFromGlobalState={this.props.getFromGlobalState}
       settingDialog={<PackingEquipmentD/>}
       settingName='packequips'
       settingHeading='Add'
       getFromGlobalState={this.props.getFromGlobalState}
       //metaDataName='chemicals'
      />
     </ExpansionPanelDetailed>  */}

            <ExpansionPanelDetailed
              heading="Agronomic activities"
              expanded={expandedSetting}
              handleExpand={this.handleSettingExpand}
            >
              <GenSetForm
                initialState={this.props.getFromGlobalState('agronomistActs')}
                handleSubmit={this.handleSubmit}
                isSubmitting={this.state.isSubmitting}
                setGlobalState={this.props.setGlobalState}
                getFromGlobalState={this.props.getFromGlobalState}
                settingDialog={<AgronomistActD />}
                settingName="agronomistActs"
                settingHeading="Add"
                displayLabelPrim="name"
                displayLabelSec="description"
                headCells={[
                  {
                    id: 'name', numeric: false, disablePadding: false, label: 'Name',
                  },
                  {
                    id: 'description', numeric: false, disablePadding: false, label: 'Description',
                  },

                ]}
              />
            </ExpansionPanelDetailed>

            <ExpansionPanelDetailed
              heading="Workforce"
              expanded={expandedSetting}
              handleExpand={this.handleSettingExpand}
            >
              <GenSetForm
                initialState={this.props.getFromGlobalState('operators').map((operator) => {
                  const ob = { ...operator };
                  ob.name = `${ob.name} ${ob.surname}`;
                  ob.rolesString = this.roleString(ob.roles);
                  ob.enabled = ob.enabled ? 'Yes' : 'No';
                  ob.leader = ob.teamLeader ? 'Yes' : 'No';
                  return ob;
                })}
                handleSubmit={this.handleSubmit}
                isSubmitting={this.state.isSubmitting}
                setGlobalState={this.props.setGlobalState}
                getFromGlobalState={this.props.getFromGlobalState}
                settingDialog={<OperatorD roles={roles} />}
                settingName="operators"
                settingHeading="Add"
                displayLabelPrim="name"
                userAvatar
                displayLabelSec="surname"
                headCells={!clean ? [

                  {
                    id: 'enabled', numeric: false, disablePadding: false, label: 'Enabled',
                  },
                  {
                    id: 'name', numeric: false, disablePadding: false, label: 'Name',
                  },
                  {
                    id: 'leader', numeric: false, disablePadding: false, label: 'Leader',
                  },
                  {
                    id: 'clockNo', numeric: false, disablePadding: false, label: 'Clock No',
                  },
                  {
                    id: 'idNo', numeric: false, disablePadding: false, label: 'ID',
                  },
                  {
                    id: 'team', numeric: false, disablePadding: false, label: 'Team',
                  },
                  {
                    id: 'rolesString', numeric: false, disablePadding: false, label: 'Role',
                  },

                ] : [
                  {
                    id: 'name', numeric: false, disablePadding: false, label: 'Name',
                  },
                ]}
              />
            </ExpansionPanelDetailed>
            <ExpansionPanelDetailed
              disabled
              heading="Processors"
              expanded={expandedSetting}
              handleExpand={this.handleSettingExpand}
            >
              <GenSetForm
                initialState={this.props.getFromGlobalState('myProductProcessors').length && this.props.getFromGlobalState('myProductProcessors').filter((f) => f.enabled).map((p) => {
                  const ob = { ...p };
                  const productProcessors = this.props.getFromGlobalState('productProcessors');
                  const processor = productProcessors.find((p) => p.sk === ob.productProcessor);
                  const processorType = processor && processor.sk.substring(0, processor.sk.indexOf('_setchild_'));
                  const processorTypes = this.props.getFromGlobalState('productProcessorTypes');
                  ob.processorType = processor && processorTypes ? processorTypes.find((h) => h.sk === processorType).name : '';
                  ob.name = processor && processor.name;
                  ob.province = processor && processor.province;
                  return ob;
                })}
                handleSubmit={this.handleSubmit}
                isSubmitting={this.state.isSubmitting}
                setGlobalState={this.props.setGlobalState}
                getFromGlobalState={this.props.getFromGlobalState}
                settingDialog={<MyProductProcessorD setSnack={this.props.setSnack} />}
                settingName="myProductProcessors"
                settingHeading="Add"
                displayLabelPrim="name"
                // userAvatar
                // displayLabelSec="surname"
                headCells={[

                  {
                    id: 'province', numeric: false, disablePadding: false, label: 'Province',
                  },
                  {
                    id: 'processorType', numeric: false, disablePadding: false, label: 'Type',
                  },
                  {
                    id: 'name', numeric: false, disablePadding: false, label: 'Name',
                  },
                ]}
              />
            </ExpansionPanelDetailed>

            <ExpansionPanelDetailed
              disabled
              heading="Facilities"
              expanded={expandedSetting}
              handleExpand={this.handleSettingExpand}
            >
              <GenSetForm
                initialState={this.props.getFromGlobalState('facilities')}
                handleSubmit={this.handleSubmit}
                isSubmitting={this.state.isSubmitting}
                setGlobalState={this.props.setGlobalState}
                getFromGlobalState={this.props.getFromGlobalState}
                settingDialog={<FacilityD />}
                settingName="facilities"
                settingHeading="Add"
                displayLabelPrim="name"
                userAvatar
                displayLabelSec="surname"
                headCells={[

                  {
                    id: 'name', numeric: false, disablePadding: false, label: 'Name',
                  },
                  {
                    id: 'description', numeric: false, disablePadding: false, label: 'Clock No',
                  },
                ]}
              />
            </ExpansionPanelDetailed>

          </>
          ) }

          <ExpansionPanelDetailed
            heading={isEntity ? 'Farms' : 'Additional Sites'}
            expanded={expandedSetting}
            handleExpand={this.handleSettingExpand}
          >
            <GenSetForm
              initialState={this.filterSites(siteList, farms).map((f) => {
                const ob = { ...f };
                ob.isDisabled = f.isDisabled ? 'Yes' : 'No';
                return ob;
              })}
              handleSubmit={this.handleSubmit}
              isSubmitting={this.state.isSubmitting}
              setGlobalState={this.props.setGlobalState}
              getFromGlobalState={this.props.getFromGlobalState}
              settingDialog={<FarmsD />}
              settingName="farms"
              settingHeading="Add"
              displayLabelPrim="name"
              displayLabelSec="description"
              headCells={[
                {
                  id: 'isDisabled', numeric: false, disablePadding: false, label: 'Disabled',
                },
                {
                  id: 'name', numeric: false, disablePadding: false, label: 'Name',
                },
                {
                  id: 'description', numeric: false, disablePadding: false, label: 'Description',
                },

              ]}
            />
          </ExpansionPanelDetailed>

          <ExpansionPanelDetailed
            heading="Users"
            expanded={expandedSetting}
            handleExpand={this.handleSettingExpand}
          >
            <UserDetailForm
              initialState={this.props.getFromGlobalState('users')}
              handleSubmit={this.handleSubmit}
              isSubmitting={this.state.isSubmitting}
              setGlobalState={this.props.setGlobalState}
              getFromGlobalState={this.props.getFromGlobalState}
              setSnack={this.props.setSnack}
            />
          </ExpansionPanelDetailed>

          <ExpansionPanelDetailed
            heading="API Keys"
            expanded={expandedSetting}
             // disabled
            handleExpand={this.handleSettingExpand}
          >
            <GenSetForm
              initialState={this.props.getFromGlobalState('apiKeys').map((apiKey) => { const ob = {...apiKey}; ob.enabled = ob.isDisabled? 'Yes' : 'No'; return ob; })}
             // handleSubmit={this.handleSubmit}
              isSubmitting={this.state.isSubmitting}
              setGlobalState={this.props.setGlobalState}
              getFromGlobalState={this.props.getFromGlobalState}
              settingDialog={<ApiKeyD />}
              settingName="apiKeys"
              settingHeading="Add"
              metaDataName="apiKeys"
              headCells={[
                {
                  id: 'enabled', numeric: false, disablePadding: false, label: 'Disabled',
                },
                {
                  id: 'consumer', numeric: false, disablePadding: false, label: 'Consumer',
                },
                {
                  id: 'apiKey', numeric: false, disablePadding: false, label: 'Key',
                },
              ]}
            />
          </ExpansionPanelDetailed>
        </Paper>
      </Load>

    );
  }
}
export default withStyles(styles)(Settings);
