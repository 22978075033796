// import { Polygon } from 'recharts';
// import chroma from 'chroma-js';
import randomColor from 'randomcolor';
import countryData from '../data/countries.json';

export function ColorLuminance(hex, lum) {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  let rgb = '#'; let c; let
    i;
  for (i = 0; i < 3; i += 1) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
    rgb += (`00${c}`).substr(c.length);
  }

  return rgb;
}
export function generateColors(baseColor, colors) {
  return randomColor({ hue: baseColor, count: colors });
  // const baseColor = '#007bff'; // Set the base color for the palette
  // for (let i = 0; i < numColors; i++) {
  //  const color = scale(cnt / (total - 1)).hex(); // Generate a color from the color scale
  // return color;
  // }
}

// create a new function to generate distinct colors in the same platte

export function format(header, value, locale) {
  let formattedValue;

  if (header.numeric === true) {
    formattedValue = value ? value.toLocaleString(locale) : value;
  } else {
    formattedValue = value;
  }
  return formattedValue;
}

export function arrayToString(arr) {
  if (!arr) {
    return '';
  }
  const len = arr.length;
  let i;
  let str = '';
  for (i = 0; i < len; i += 1) {
    str = str.concat(arr[i]);
    if (i !== len - 1) {
      str = str.concat(' ,');
    }
  }
  return str;
}

export function convertHex(hex, opacity) {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  const result = `rgba(${r},${g},${b},${opacity / 100})`;
  return result;
}

export function getShade(hexin, step) {
  const hex = hexin.replace('#', '');
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);
  // const max = Math.max(Math.max(r, Math.max(g, b)), 1);
  if (b === 255) {
    // r = r + step;
    g += step;
  } else if (r === 255) {
    g += step;
    b += step;
  } else if (g === 255) {
    r += step;
    b += step;
  }
  const result = `rgba(${r},${g},${b},1)`;
  return result;
}

// date stuff
export function getToday() {
  const today = new Date();
  const datetoday = today.toISOString().substr(0, 10);
  return datetoday;
}

export function getNow() {
  const today = new Date();
  let mins = today.getMinutes();
  const zero = '0';
  mins = (mins >= 10 ? mins : zero.concat(mins));
  const zeroH = '0';
  let hours = today.getHours();
  hours = (hours >= 10 ? hours : zeroH.concat(hours));

  const now = `${hours}:${mins}`;
  return now;
}
export function inputDateAndTimeToLocaleDate(dateStr, hourMinuteStr, days) {
  // console.log(dateStr, hourMinuteStr, days);
  const date = new Date(dateStr);
  if (hourMinuteStr !== undefined) {
    date.setHours(parseInt(hourMinuteStr.substring(0, 2)));
    date.setMinutes(parseInt(hourMinuteStr.substring(4, 6)));
  }
  if (days !== undefined) {
    date.setDate(date.getDate() + (parseInt(days) + 1));
  }
  return date.toISOString().substring(0, 10);
}

export function getLocale(farm) {
  if (!farm) {
    return 'en-ZA';
  }
  if (!farm.country) {
    return 'en-ZA';
  }
  const country = countryData.filter((p) => p.countryName === farm.country);
  
  if(!country[0].locale){
    return 'en-ZA';
  }

  if (country[0].locale) {
    return country[0].locale;
  }
  return '';
}

// name lookups
export function getNameFromKey(type, key, getFromGlobalState) {
  const metaData = getFromGlobalState(type);
  if (!metaData) {
    alert('Could not find in global state', type);
  }

  const entity = metaData.find((e) => e.sk === key);
  let name = '';
  if (entity) {
    name = entity.name;
  }
  return name;
}

export function getLocation() {
  const options = {
    enableHighAccuracy: true,
    timeout: 100000,
    maximumAge: 0,
  };
  return new Promise(((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  }));
}

export async function getAccuratePosition() {
  let id;
  const options = {
    enableHighAccuracy: true,
    timeout: 100000,
    maximumAge: 0,
  };

  function success(pos) {
    const crd = pos.coords;

    if (crd.accuracy < 50) {
      navigator.geolocation.clearWatch(id);
      return crd;
    }
  }

  function error(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }
  id = navigator.geolocation.watchPosition(success, error, options);
}

export function getCurrentLocation(setLoading, setLoaded, setLocation) {
  setLoading(true);
  setLoaded(false);
  const options = {
    enableHighAccuracy: true,
    timeout: 100000,
    maximumAge: 0,
  };
  navigator.geolocation.getCurrentPosition((position) => {
    const location = {
      lat: parseFloat(position.coords.latitude.toFixed(8)),
      lng: parseFloat(position.coords.longitude.toFixed(8)),
      accuracy: position.coords.accuracy,
      altitude: position.coords.altitude,
      altAcc: position.coords.altitudeAccuracy,
    };
    setLocation(location);
    setLoading(false);
    setLoaded(true);
  },
  (error) => {
    if (error.code === error.PERMISSION_DENIED) {
      setLoading(false);
      setLoaded(false);
    }
  },
  options);
}
export function geoStringToLatLong(geoString) {
  if (!geoString) {
    return null;
  }
  if (geoString.hasOwnProperty('lat') || geoString.hasOwnProperty('lng')) {
    return geoString;
  }
  const latEnd = geoString.indexOf('lon');
  if (latEnd === -1) {
    return null;
  }
  const lat = parseFloat(geoString.substring(4, latEnd).trim());
  const lng = parseFloat(geoString.substring(latEnd + 4, geoString.length).trim());
  return ({ lat, lng });
}

export function getObjectFromKey(type, key, getFromGlobalState) {
  const metaData = getFromGlobalState(type);
  if (!metaData) {
    alert('Could not find in global state', type);
  }
  const entity = metaData.find((e) => e.sk === key);
  return entity;
}

export function processCSV(str, delim = ',') {
  const headers = str.slice(0, str.indexOf('\n')).split(delim);
  const rows = str.slice(str.indexOf('\n') + 1).split('\n');

  const newArray = rows.map((row) => {
    const values = row.split(delim);
    const eachObject = headers.reduce((obj, header, i) => {
      obj[header] = values[i].replace('\r', '');
      return obj;
    }, {});
    return eachObject;
  });

  // setCsvArray(newArray)
  console.log('new arr', newArray);
}

// name lookups
export function getNameFromKey2(key, metaData, requiredMember) {
  // let metaData = getFromGlobalState(type);
  if (!requiredMember) requiredMember = 'name';
  if (!metaData) {
    return '';
  }
  const entity = metaData.find((e) => e.sk === key);
  let name = '';
  if (entity) {
    name = entity[requiredMember];
  }
  return name;
}


export const calculateMidpoint=(coords)=> {
  if (coords.length === 0) return null; // Handle empty array

  let totalLat = 0;
  let totalLng = 0;

  // Sum up all latitudes and longitudes
  for (const coord of coords) {
      totalLat += coord.lat;
      totalLng += coord.lng;
  }

  // Calculate the average latitude and longitude
  const midpointLat = totalLat / coords.length;
  const midpointLng = totalLng / coords.length;

  return { lat: midpointLat, lng: midpointLng };
}

export async function parseKML(plainText) {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(plainText, 'text/xml');
  const googlePolygons = [];
  const googleMarkers = [];
  const errors = [];

  if (xmlDoc.documentElement.nodeName === 'kml') {
    for (const item of xmlDoc.getElementsByTagName('Placemark')) {
      if (!item.getElementsByTagName('name') || !item.getElementsByTagName('name')[0]) {
        errors.push('Blocks found without a name. Please provide a name for each block');
        continue;
      }
      const placeMarkName = item.getElementsByTagName('name')[0].childNodes[0].nodeValue.trim();
      const polygons = item.getElementsByTagName('Polygon');
      const markers = item.getElementsByTagName('Point');

      /** POLYGONS PARSE * */
      for (const polygon of polygons) {
        const coords = polygon.getElementsByTagName('coordinates')[0].childNodes[0].nodeValue.trim();
        const points = coords.split(' ');

        const googlePolygonsPaths = [];
        for (const point of points) {
          const coord = point.split(',');
          googlePolygonsPaths.push({ lat: parseFloat(coord[1]), lng: parseFloat(coord[0]) });
        }
        googlePolygons.push({ name: placeMarkName, googlePolygonsPaths });
      }

      /** MARKER PARSE * */
      for (const marker of markers) {
        const coords = marker.getElementsByTagName('coordinates')[0].childNodes[0].nodeValue.trim();
        const coord = coords.split(',');
        googleMarkers.push({ name: placeMarkName, coord: { lat: parseFloat(coord[1]), lng: parseFloat(coord[0]) } });
      }
    }
  } else {
    throw 'error while parsing';
  }

  return { markers: googleMarkers, polygons: googlePolygons, errors };
}
export function readFileAsText(inputFile) {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result);
    };
    temporaryFileReader.readAsText(inputFile);
  });
}

export function changePolygonColors(polygons, fillColor, strokeColor, except) {
  const newPoloygons = polygons && fillColor ? polygons.map((polygon) => {
    const newPoly = { ...polygon };
    if (polygon.sk !== except) {
      newPoly.color = fillColor;
      newPoly.strokeColor = strokeColor;
    }
    return newPoly;
  }) : [];
  return newPoloygons;
}

export function parseCSVCommaInValue(textFile) {
  if (!textFile) {
    return null;
  }
  const str = textFile;
  const headString = str && str.slice(0, str.indexOf('\n'));
  const delimiterHead = headString && headString.indexOf(',') !== -1 ? ',' : ';';
  const delimiter = /;(?=(?:[^"]*"[^"]*")*[^"]*$)/;
  const headers = str.slice(0, str.indexOf('\n')).split(delimiterHead);
  const rows = str.slice(str.indexOf('\n') + 1).split('\n');

  const newArray = rows.map((row) => {
    const values = row.split(delimiter);
    const eachObject = headers.reduce((obj, header, i) => {
      let value = values[i] ? values[i].trim().replace('\r', '') : '';

      // Check if the value is enclosed in double quotes
      if (/^["“]/.test(value) && /["“]$/.test(value)) {
        // Remove the quotes and any escaped quotes
        value = value.slice(1, -1).replace(/""/g, '"');
      }
      obj[header.replace('\r', '')] = value;
      return obj;
    }, {});
    return eachObject;
  });
  return newArray;
}

export function parseCSV(textFile) {
  if (!textFile) {
    return null;
  }
  const str = textFile;
  const headString = str && str.slice(0, str.indexOf('\n'));
  const delimiter = headString && headString.indexOf(',') !== -1 ? ',' : ';';
  const headers = str.slice(0, str.indexOf('\n')).split(delimiter);
  const rows = str.slice(str.indexOf('\n') + 1).split('\n');
  // const delimiter = rows && rows.length > 0 && rows[0].indexOf(',') !== -1 ? ',' : ';';

  const newArray = rows.map((row) => {
    const values = row.split(delimiter);
    const eachObject = headers.reduce((obj, header, i) => {
      obj[header.replace('\r', '')] = values[i] ? values[i].trim().replace('\r', '') : '';
      return obj;
    }, {});
    return eachObject;
  });
  return newArray;
}

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

const ColorNames = {
  aqua: '#00ffff',
  azure: '#f0ffff',
  beige: '#f5f5dc',
  black: '#000000',
  blue: '#0000ff',
  brown: '#a52a2a',
  cyan: '#00ffff',
  darkblue: '#00008b',
  darkcyan: '#008b8b',
  darkgrey: '#a9a9a9',
  darkgreen: '#006400',
  darkkhaki: '#bdb76b',
  darkmagenta: '#8b008b',
  darkolivegreen: '#556b2f',
  darkorange: '#ff8c00',
  darkorchid: '#9932cc',
  darkred: '#8b0000',
  darksalmon: '#e9967a',
  gold: '#ffd700',
  green: '#008000',
  indigo: '#4b0082',
  khaki: '#f0e68c',
  lightblue: '#add8e6',
  lightcyan: '#e0ffff',
  lightgreen: '#90ee90',
  lightgrey: '#d3d3d3',
  lightyellow: '#ffffe0',
  lime: '#00ff00',
  magenta: '#ff00ff',
  maroon: '#800000',
  navy: '#000080',
  olive: '#808000',
  orange: '#ffa500',
  red: '#ff0000',
  silver: '#c0c0c0',
};

export const recordTypes = {
  pestings: 'Monitoring',
  fertilisings: 'Fertiliser',
  waterings: 'Irrigation',
  sprayings: 'Plant Protection',
  harvestings: 'Harvesting',
  activities: 'Agronomic Activity',
};

export function randomColor2() {
  let result;
  let count = 0;
  for (const prop in ColorNames) { if (Math.random() < 1 / ++count) result = prop; }
  return result;
}

export function handleVariableTChange(currentTarget, event, obsIdx, stageIdx, month, range) {
  const target = { ...currentTarget };

  if (target.cropSpecific) {
    if (!target.observableOn[obsIdx].stages[stageIdx].variableThreshold) {
      target.observableOn[obsIdx].stages[stageIdx].variableThreshold = {};
    }
    if (!target.observableOn[obsIdx].stages[stageIdx].variableThreshold[month]) {
      target.observableOn[obsIdx].stages[stageIdx].variableThreshold[month] = {};
    }
    if (!target.observableOn[obsIdx].stages[stageIdx].variableThreshold[month][range]) {
      target.observableOn[obsIdx].stages[stageIdx].variableThreshold[month][range] = {};
    }
    target.observableOn[obsIdx].stages[stageIdx].variableThreshold[month][range] = event.target.value;
  } else {
    if (!target.nonCropDetail.variableThreshold) {
      target.nonCropDetail.variableThreshold = {};
    }
    if (!target.nonCropDetail.variableThreshold[month]) {
      target.nonCropDetail.variableThreshold[month] = {};
    }
    if (!target.nonCropDetail.variableThreshold[month][range]) {
      target.nonCropDetail.variableThreshold[month][range] = {};
    }
    target.nonCropDetail.variableThreshold[month][range] = event.target.value;
  }
  // this.setState({ target, changed: true });
  return target;
}
