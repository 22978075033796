import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { validate, validateBatchForm, validateForm } from '../../libs/validate';
import GenDialog from '../../components/GenDialog';
import WateringFormBatch from './wateringFormBatch';
import PestingFormBatch from './pestingFormBatch';
import AgronomyActFormBatch from './agronomicActFormBatch';
import HarvestingFormBatch from './harvestingFormBatch';
import FertilisingFormBatch from './fertilisingFormBatch';
import SprayingFormBatch from './sprayingFormBatch';
import { getNow } from '../../libs/genLib';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 220,
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  helperText: {
    color: 'red',
  },

});

/* const requiredFields = [
    {name:'crop', min: 1, max: 40},
    {name:'variety', min: 1, max: 40},
    {name:'plantSpacing', min: 1, max: 40},
    {name:'plantDensity', min: 1, max: 40},
    {name:'plantDate', min: 1, max: 40},

  ]; */

class RecordDBatch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      isNew: this.props.isNew,
      record: {},
      errors: { count: 0 },
      changed: false,
      selectedBlocks: [],
      expandedTarget: '',

    };
  }

  componentDidMount() {
    if (this.props.isNew) {
      const today = new Date();
      const record = { ...this.state.record };
      record.date = today.toISOString().substr(0, 10);
      const now = `${today.getHours()}:${today.getMinutes()}`;
      record.time = now;
      const blocksIn = this.props.selectedBlocks;
      blocksIn && blocksIn.forEach((block) => {
        block.record.date = today.toISOString().substr(0, 10);
        if (this.props.recordType === 'fertilisings' || this.props.recordType === 'sprayings') {
          block.record.endDate = today.toISOString().substr(0, 10);
          block.record.endTime = getNow();
          block.record.time = getNow();
          block.record.date = today.toISOString().substr(0, 10);
          record.endDate = today.toISOString().substr(0, 10);
        }
      });
      this.setState({ selectedBlocks: blocksIn, changed: false, record });
    }
  }

  validateField(field, requiredFields, plantingSK) {
    const block = this.state.selectedBlocks.find((b) => (b.planting === plantingSK));
    const error = validate(field, block.record, requiredFields);
    const errors = { ...this.state.errors };
    errors[plantingSK] = {};
    errors[plantingSK][field] = error.msg;
    this.setState({ errors });
  }

  handleBlur = (plantingSK, event) => this.validateField(event.target.id, this.props.requiredFields, plantingSK)

  onApplyAll = (planting, blockSK, firstRecord, event) => {
    const newBlocks = [...this.state.selectedBlocks];

    for (const ob of newBlocks) {
      // const g = true;
      ob.record = { ...firstRecord };
    }

    // let plant = newBlocks.find(blk=>(blk.planting===planting));
    // if (plant)
    // {
    //     plant.record[event.target.id] = event.target.value;
    // }
    this.setState({ selectedBlocks: newBlocks, changed: true });
  }

  handleCommonBlur = (event) => {
    // validate for each block
    const retVal = { isValid: true };
    this.state.selectedBlocks.forEach((block) => {
      retVal.isValid = retVal.isValid && validate(event.target.id, block.record, this.props.requiredFields).isValid;
    });

    return retVal;
  }

  overrideCalculatedVolume = (event) => {
    const record = { ...this.state.record };
    record.calculatedVolumeOverride = !record.calculatedVolumeOverride;
    this.setState({ record, changed: true });
  }

  overrideCalculatedProduct = (event) => {
    const record = { ...this.state.record };
    record.calculatedProductOverride = !record.calculatedProductOverride;
    this.setState({ record, changed: true });
  }

  handleChange = (event) => {
    const record = { ...this.state.record };
    record[event.target.id] = event.target.value;
    this.setState({ record, changed: true });
  }


  //
  handleBatchApplied = (event, value, arrayName, planting) => {
    const newBlocks = [...this.state.selectedBlocks];
    const plant = newBlocks.find((blk) => (blk.planting === planting));
    if (plant) {
      const { record } = plant;
      const recordNew = { ...record };
      if (!recordNew[arrayName]) {
        recordNew[arrayName] = [];
      }
      const currentIndex = recordNew[arrayName].findIndex((v) => v.target === value);
      if (currentIndex !== -1) {
        // make all the rest false.
        //const appliedList = recordNew[arrayName].filter((f) => f.applied === true);
        for (let v = 0; v < recordNew[arrayName].length; v += 1) {
          recordNew[arrayName][v].applied = false;
        }
        recordNew[arrayName][currentIndex].applied = event.target.checked;
        this.setState({ record: recordNew, changed: true });
      }
    }
  }

  handleBatchToggleReasons = (value, arrayName, planting) => {
    const newBlocks = [...this.state.selectedBlocks];
    const plant = newBlocks.find((blk) => (blk.planting === planting));
    if (plant) {
      const { record } = plant;
      const { getFromGlobalState } = this.props;
      const recordNew = record;
      if (!recordNew[arrayName]) {
        recordNew[arrayName] = [];
      }
      const currentIndex = recordNew[arrayName].findIndex((i) => i.target === value);
      for (let r = 0; r < recordNew[arrayName].length; r += 1) {
        recordNew[arrayName][r].applied = false;
      }

      if (currentIndex === -1) {
      // const chemicals = getFromGlobalState('chemicals');
        const chemicals = getFromGlobalState('chemicals');
        const chemical = chemicals.find((f) => f.sk === record.product);
        const chemcrop = chemical.chemcrops.find((c) => c.crop === plant.plantingOB.crop);
        const targetSpes = chemcrop.targets.find((t) => t.target === value);
        targetSpes.applied = true;
        // have the chmical - now find the reason.

        recordNew[arrayName].push(targetSpes);
      } else {
        recordNew[arrayName].splice(currentIndex, 1);
      }
    }

    this.setState({ selectedBlocks: newBlocks, changed: true });
  };

  handleBatchChange = (planting, blockSK, event) => {
    const newBlocks = [...this.state.selectedBlocks];
    const plant = newBlocks.find((blk) => (blk.planting === planting));
    if (plant) {
      plant.record[event.target.id] = event.target.value;
    }
   
    this.setState({ selectedBlocks: newBlocks, changed: true });
  }

  handlePestingsMonitored =(planting, blockSK, event,stageSk,monitoringSk,targetDetailSk,cropSpecific,observableOnSK,index,target)=>{
    const newBlocks = [...this.state.selectedBlocks];
    const plant = newBlocks.find((blk) => (blk.planting === planting));
    if (plant) {
      // plant.record[event.target.id] = event.target.value;
      plant.record.geoLocations = plant?.record?.geoLocation && [plant?.record?.geoLocation];
      const entry = parseInt(event.target.value);
      const stages = [];
      stages[target] = [index];
      stages[target][index] = event.target.value;
      plant.record.stages = stages;
      plant.record.target= target;
      plant.record.observations = cropSpecific === "Yes" ?  [
        {
            "cropSpecific": true,
            "stageSK": stageSk,
            "monitoringSK": monitoringSk,
            "targetDetailSK": targetDetailSk,
            "entries": [[entry]],
            "observableOnSK": observableOnSK
        }
    ] : [
      {
          "cropSpecific": false,
          "monitoringSK": monitoringSk,
          "targetDetailSK": targetDetailSk,
          "entries": [[entry]],
      }
  ]
    }

    this.setState({ selectedBlocks: newBlocks, changed: true });

  }

  // record[event.target.id] = !record[event.target.id];
  overrideTotalBatch= (planting, blockSK, event) => {
    const newBlocks = [...this.state.selectedBlocks];
    const plant = newBlocks.find((blk) => (blk.planting === planting));
    if (plant) {
      plant.record[event.target.id] = !plant.record[event.target.id];
    }
    this.setState({ selectedBlocks: newBlocks, changed: true });
  }

  handleLocationBatchChange = (planting, blockSK, location) => {
    const newBlocks = [...this.state.selectedBlocks];
    const plant = newBlocks.find((blk) => (blk.planting === planting));
    if (plant) {
      plant.record.geoLocation = location;
    }
    this.setState({ selectedBlocks: newBlocks, changed: true });
  }

  handleBatchCheckChange = (planting, blockSK, event) => {
    const newBlocks = [...this.state.selectedBlocks];
    const plant = newBlocks.find((blk) => (blk.planting === planting));
    if (plant) {
      plant.record[event.target.id] = event.target.checked;
    }
    this.setState({ selectedBlocks: newBlocks, changed: true });
  }

  overrideHarvestTotal = (planting, blockSK, event) => {
    const newBlocks = [...this.state.selectedBlocks];
    const errors = { ...this.state.errors };
    const plant = newBlocks.find((blk) => (blk.planting === planting));
    if (plant) {
      plant.record[event.target.id] = !plant.record[event.target.id];
    }
    if (plant.record[event.target.id]) {
      errors.harvestEquip = undefined;
      errors.harvestUnits = undefined;
    }
    this.setState({ errors, selectedBlocks: newBlocks, changed: true });
  }

  handleCommonChange = (event) => {
    const newBlocks = [...this.state.selectedBlocks];
    const { record } = this.state;
    newBlocks && newBlocks.forEach((block) => {
      block.record[event.target.id] = event.target.value;
    });
    record[event.target.id] = event.target.value;

    this.setState({ selectedBlocks: newBlocks, changed: true, record });
  }

   handleSwitchChange = (name) => (event) => {
     const record = { ...this.state.record };
     record[name] = event.target.checked;
     this.setState({ record, changed: true });
   };

  handleComplexChange = (inputDataField, fieldToUpdate, event) => {
    const record = { ...this.state.record };
    record[event.target.id] = event.target.value;
    record[fieldToUpdate] = event.target.value * this.props.inputData[inputDataField];
    this.setState({ record, changed: true });
  }

  updateParent(attribute, value) {
    const record = { ...this.state.record };
    record[attribute] = value;
    this.setState({ record });
  }

  handleExpand = (setting) => {
    const { expandedTarget } = this.state;
    if (expandedTarget !== setting) {
      this.setState({ expandedTarget: setting });
    } else {
      this.setState({ expandedTarget: '' });
    }
  }

  // todo will have to move to  redux
  handleToggleChemrigs = (value, arrayName, planting) => {
    const newBlocks = [...this.state.selectedBlocks];
    const plant = newBlocks.find((blk) => (blk.planting === planting));
    if (plant) {
      if (!plant.record[arrayName]) {
        plant.record[arrayName] = [];
      }
      const currentIndex = plant.record[arrayName].indexOf(value);

      if (currentIndex === -1) {
        plant.record[arrayName].push(value);
      } else {
        plant.record[arrayName].splice(currentIndex, 1);
      }
    }
    this.setState({ selectedBlocks: newBlocks, changed: true });
  };

  render() {
    const {
      open, isNew, setClose, handleUpdateRecord, allowDelete, handleDeleteRecord, requiredFields, recordType, title, newTitle, plantingSK, getFromGlobalState, selectedBlocks,
    } = this.props;
    const { record, errors, expandedTarget } = this.state;
    // Change here to enable add button after form valid - just changed so they can't submit batch!!!!! 
    const formValid = validateBatchForm(selectedBlocks, requiredFields);
    const firstFormValid = selectedBlocks && selectedBlocks[0] && validateForm(selectedBlocks[0].record, requiredFields);

    // const test = getFromGlobalState('blocks').find(b=>b.sk === blockSK).plantings.filter(p=>p.sk===plantingSK);

    return (

      <GenDialog
        open={open}
        dialogObject={{ items: this.state.selectedBlocks }}
        setClose={setClose}
        isNew={isNew}
        handleUpdateDialogObject={handleUpdateRecord.bind(this)}
      // skPrimary={'recordType'}
        skPrimary="_record_"
        dynamoType={recordType}
        valid={formValid}
        changed={this.state.changed}
        getFromGlobalState={getFromGlobalState}
        newTitle={newTitle}
        title={title}
        batchInserting
        lastUpdatedBy={{
          createdByEmail: record?.createdByEmail ? record?.createdByEmail : '',
          createdAt: record?.createdAt,
        }}
     // subTitle={`Block ${block.number} v.${record.version ? record.version: '0' }`}
        subTitle="New Batch Record"
        parentKey={plantingSK}
        plantingKey={plantingSK}
        allowDelete={allowDelete && handleDeleteRecord}
        handleDeleteDialogObject={handleDeleteRecord ? handleDeleteRecord.bind(this) : undefined}
      >

        {recordType === 'waterings'
         && (
         <WateringFormBatch
           record={record}
           errors={errors}
           getFromGlobalState={getFromGlobalState}
           onApplyAll={this.onApplyAll.bind(this)}
           selectedBlocks={selectedBlocks}
           handleCommonChange={this.handleCommonChange.bind(this)}
           handleBatchChange={this.handleBatchChange.bind(this)}
           handleBlur={this.handleBlur.bind(this)}
           handleCommonBlur={this.handleCommonBlur.bind(this)}
           metaData={{ operators: getFromGlobalState('operators').filter((o) => o.roles.indexOf('waterings') !== -1) }}
        // handleComplexChange={this.handleComplexChange.bind(this)}
           updateParent={this.updateParent.bind(this)}
        // planting={block.plantings.filter(p=>p.sk===plantingSK)}
           handleSwitchChange={this.handleSwitchChange}
           firstFormValid={!firstFormValid}
         />
         )}

        {recordType === 'pestings'
         && (
         <PestingFormBatch
           record={record}
           errors={errors}
           selectedBlocks={selectedBlocks}
           handlePestingsMonitored={this.handlePestingsMonitored.bind(this)}
           handleBatchCheckChange={this.handleBatchCheckChange.bind(this)}
           handleLocationBatchChange={this.handleLocationBatchChange.bind(this)}
           handleCommonChange={this.handleCommonChange.bind(this)}
           handleBatchChange={this.handleBatchChange.bind(this)}
           getFromGlobalState={getFromGlobalState}
           handleBlur={this.handleBlur.bind(this)}
           handleCommonBlur={this.handleCommonBlur.bind(this)}
           metaData={{ monitorings: getFromGlobalState('monitorings'), 
            operators: getFromGlobalState('operators').filter((o) => o.roles.indexOf('pestings') !== -1), 
            crops:getFromGlobalState('crops'),
            pests:getFromGlobalState('pests'),
            targetTypes:getFromGlobalState('targetsTypesSetting')
           }}
        // handleComplexChange={this.handleComplexChange.bind(this)}
           updateParent={this.updateParent.bind(this)}
        // planting={block.plantings.filter(p=>p.sk===plantingSK)}
           handleSwitchChange={this.handleSwitchChange}
           firstFormValid={!firstFormValid}
           onApplyAll={this.onApplyAll.bind(this)}
         />
         )}

        {recordType === 'activities'
         && (
         <AgronomyActFormBatch
           record={record}
           errors={errors}
           selectedBlocks={selectedBlocks}
           handleCommonChange={this.handleCommonChange.bind(this)}
           onApplyAll={this.onApplyAll.bind(this)}
           handleBatchChange={this.handleBatchChange.bind(this)}
           getFromGlobalState={getFromGlobalState}
           handleBlur={this.handleBlur.bind(this)}
           handleCommonBlur={this.handleCommonBlur.bind(this)}
           metaData={{ agronomistActs: getFromGlobalState('agronomistActs'), operators: getFromGlobalState('operators').filter((o) => o.roles.indexOf('activities') !== -1) }}
        // handleComplexChange={this.handleComplexChange.bind(this)}
           updateParent={this.updateParent.bind(this)}
        // planting={block.plantings.filter(p=>p.sk===plantingSK)}
           handleSwitchChange={this.handleSwitchChange}
           firstFormValid={!firstFormValid}
         />
         )}

        {recordType === 'harvestings'
         && (
         <HarvestingFormBatch
           record={record}
           errors={errors}
           selectedBlocks={selectedBlocks}
           handleCommonChange={this.handleCommonChange.bind(this)}
           handleBatchChange={this.handleBatchChange.bind(this)}
           overrideHarvestTotal={this.overrideHarvestTotal.bind(this)}
           handleBlur={this.handleBlur.bind(this)}
           getFromGlobalState={getFromGlobalState}
           handleCommonBlur={this.handleCommonBlur.bind(this)}
           metaData={getFromGlobalState('harvestequips')}
        // handleComplexChange={this.handleComplexChange.bind(this)}
           updateParent={this.updateParent.bind(this)}
        // planting={block.plantings.filter(p=>p.sk===plantingSK)}
           handleSwitchChange={this.handleSwitchChange}
         />
         )}

        {recordType === 'fertilisings'
         && (
         <FertilisingFormBatch
           record={record}
           errors={errors}
           onApplyAll={this.onApplyAll.bind(this)}
           selectedBlocks={selectedBlocks}
           getFromGlobalState={getFromGlobalState}
           handleCommonChange={this.handleCommonChange.bind(this)}
           handleBatchChange={this.handleBatchChange.bind(this)}
           overrideTotalBatch={this.overrideTotalBatch.bind(this)}
           handleBlur={this.handleBlur.bind(this)}
           handleCommonBlur={this.handleCommonBlur.bind(this)}
           metaData={{ fertilisers: getFromGlobalState('fertilisers'), operators: getFromGlobalState('operators').filter((o) => o.roles.indexOf('fertilisings') !== -1), inventory: getFromGlobalState('inv_fertiliser') }}
        // handleComplexChange={this.handleComplexChange.bind(this)}
           updateParent={this.updateParent.bind(this)}
        // planting={block.plantings.filter(p=>p.sk===plantingSK)}
           handleSwitchChange={this.handleSwitchChange}
           firstFormValid={!firstFormValid}
         />
         )}
        {recordType === 'sprayings'
         && (
         <SprayingFormBatch
           record={record}
           errors={errors}
           onApplyAll={this.onApplyAll.bind(this)}
           getFromGlobalState={getFromGlobalState}
           selectedBlocks={selectedBlocks}
           handleBatchToggleReasons={this.handleBatchToggleReasons.bind(this)}
           handleBatchApplied={this.handleBatchApplied.bind(this)}
           handleExpand={this.handleExpand.bind(this)}
           handleCommonChange={this.handleCommonChange.bind(this)}
           handleBatchChange={this.handleBatchChange.bind(this)}
           handleBlur={this.handleBlur.bind(this)}
           handleCommonBlur={this.handleCommonBlur.bind(this)}
           expandedTarget={expandedTarget}
           metaData={{
             chemicals: getFromGlobalState('chemicals'), chemequips: getFromGlobalState('chemequips'), pests: getFromGlobalState('pests'), operators: getFromGlobalState('operators').filter((o) => o.roles.indexOf('sprayings') !== -1), inventory: getFromGlobalState('inv_chemical'),
           }}
        // handleComplexChange={this.handleComplexChange.bind(this)}
           updateParent={this.updateParent.bind(this)}
        // planting={block.plantings.filter(p=>p.sk===plantingSK)}
           handleSwitchChange={this.handleSwitchChange}
           handleToggle={this.handleToggleChemrigs}
           firstFormValid={!firstFormValid}
         />
         )}

      </GenDialog>

    );
  }
}
RecordDBatch.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  planting: PropTypes.object,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  handleUpdateRecord: PropTypes.func.isRequired,
  handleDeleteRecord: PropTypes.func.isRequired,
  blockSK: PropTypes.string.isRequired,
  plantingSK: PropTypes.string.isRequired,
  requiredFields: PropTypes.array.isRequired,
  recordType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  newTitle: PropTypes.string.isRequired,
  inputData: PropTypes.object,
  getFromGlobalState: PropTypes.func.isRequired,
  seletedBlocks: PropTypes.array.isRequired,
  allowDelete: PropTypes.bool,

};
export default withStyles(styles)(RecordDBatch);
