import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { getNow, getToday } from '../../libs/genLib';
// import { calculateApplicationTotal } from '../../libs/analytics';
import OperatorSelector from '../../components/OperatorSelector';

const styles = (theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    // alignItems: 'center',
  },
  tooltip: {
    backgroundColor: 'white',
  },
});
// todo make more efficient
const filterItems = (arr, planting) => {
  const plantingCrop = planting[0].crop;
  const allowedFertilisers = [];
  arr.forEach((fertiliser) => {
    const idx = fertiliser.fertcrops.findIndex((c) => c.crop === plantingCrop);
    if (idx !== -1) {
      allowedFertilisers.push(fertiliser);
    }
  });
  return allowedFertilisers;
};

const findChemCrop = (fert, planting) => {
  const fertCrop = fert.fertcrops.find((cc) => cc.crop === planting[0].crop);
  return fertCrop;
};

const findFertUnits = (fertiliserSK, planting, fertilisers) => {
  if (!fertiliserSK) { return undefined; }
  const fertiliser = fertilisers.find((c) => c.sk === fertiliserSK);
  const fertcrop = findChemCrop(fertiliser, planting);
  return fertcrop;
};
const findFertiliser = (fertiliserSK, fertilisers) => {
  if (!fertiliserSK) { return undefined; }
  const fertiliser = fertilisers.find((c) => c.sk === fertiliserSK);
  return fertiliser;
};

/* const calculateRequiredFertiliserAmount = (fertiliserSK,fertilisers,planting,blockSize,explain) => {
  if (!fertiliserSK) {return undefined;};
  let fertiliser = fertilisers.find((c) => c.sk === fertiliserSK);
  let fertcrop = findChemCrop(fertiliser,planting);
  let chemAmount ={amount:0,unit:''};
  if (!fertcrop) {return undefined};

  if (fertcrop.totalUnit === "Hectare")
  {
    chemAmount.amount =  parseFloat(blockSize)*parseFloat(fertcrop.valperUnit);
    chemAmount.unit = fertiliser?.unit;
    chemAmount.totalProduct=`Total fertiliser: ${chemAmount.amount.toLocaleString()} ${fertiliser?.unit}`;
    chemAmount.totalVolume=(Math.round((fertcrop.valperUnit/fertcrop.valper100l*blockSize*100*100),2)/100);
    chemAmount.volumePerPlant=undefined;
    chemAmount.totalVolumeMessage=`Total volume: ${(Math.round((fertcrop.valperUnit/fertcrop.valper100l*blockSize*100*100),2)/100).toLocaleString()} Liter`
    if (explain) {
    chemAmount.message = `Total undiluted fertiliser for the ${blockSize} ${fertcrop.totalUnit.toLocaleString()} block: ${fertcrop.valperUnit.toLocaleString()} ${fertiliser?.unit} per ${fertcrop.totalUnit} X ${blockSize} ${fertcrop.totalUnit.toLocaleString()} =  ${chemAmount.amount.toLocaleString()} ${chemAmount.unit}. At ${fertcrop.valper100l.toLocaleString()} ${chemAmount.unit} per 100L and a reccomendation of ${fertcrop.valperUnit.toLocaleString()} ${fertiliser?.unit} per ${fertcrop.totalUnit}. The total diluted volume to apply is estimated at:
    ${fertcrop.valperUnit.toLocaleString()} / ${fertcrop.valper100l.toLocaleString()} x ${blockSize} ${fertcrop.totalUnit} x 100L = ${(Math.round((fertcrop.valperUnit/fertcrop.valper100l*blockSize*100*100),2)/100).toLocaleString()} Liter`
    }

  }
  else if (fertcrop.totalUnit === "Plant") {
    chemAmount.amount = parseFloat(planting[0].totalPlants) * parseFloat(fertcrop.valperUnit);
    chemAmount.unit = fertiliser?.unit;
    chemAmount.totalProduct=`Total fertiliser: ${chemAmount.amount.toLocaleString()} ${chemAmount.unit}`;
    chemAmount.totalVolume=(Math.round((fertcrop.valperUnit/fertcrop.valper100l*planting[0].totalPlants*100*100),2)/100);
    chemAmount.volumePerPlant=(Math.round((fertcrop.valperUnit/fertcrop.valper100l*100*100),2)/100);
    chemAmount.totalVolumeMessage=`Total volume: ${(Math.round((fertcrop.valperUnit/fertcrop.valper100l*planting[0].totalPlants*100*100),2)/100).toLocaleString()} Liter`;
    if (explain) {
    chemAmount.message = `Total undiluted fertiliser for the ${planting[0].totalPlants.toLocaleString()} ${fertcrop.totalUnit}s: ${fertcrop.valperUnit.toLocaleString()} ${fertiliser?.unit} per ${fertcrop.totalUnit.toLocaleString()} X ${planting[0].totalPlants.toLocaleString()} ${fertcrop.totalUnit}s =  ${chemAmount.amount.toLocaleString()} ${chemAmount.unit}. At ${fertcrop.valper100l.toLocaleString()} ${chemAmount.unit} per 100L and a reccomendation of ${fertcrop.valperUnit.toLocaleString()} ${fertiliser?.unit} per ${fertcrop.totalUnit}. The total diluted volume to apply is estimated at:
    ${fertcrop.valperUnit.toLocaleString()} / ${fertcrop.valper100l.toLocaleString()} x ${planting[0].totalPlants.toLocaleString()} ${fertcrop.totalUnit}s x 100L = ${(Math.round((fertcrop.valperUnit/fertcrop.valper100l*planting[0].totalPlants*100*100),2)/100).toLocaleString()} Liter or ${(Math.round((fertcrop.valperUnit/fertcrop.valper100l*100*100),2)/100).toLocaleString()} Liter per ${fertcrop.totalUnit}`
  }
  }
  else {
    return undefined;
  }
  return chemAmount;

} */


/* const hasInventoryRecord = (blockSK, plantingSK, inventoryName, record, getFromGlobalState) => {
  // if (record.completed === true) { return true; }
  let applicationTotal = 0; // block, planting, recordType, applicationType, sk
  const retVals = calculateApplicationTotal(blockSK, plantingSK, record.type, inventoryName, record.sk, getFromGlobalState);
  if (retVals) {
    applicationTotal = retVals.value;
  }
  if (applicationTotal === record.calculatedProduct) {
    return { status: true, amountTaken: applicationTotal };
  }
  return { status: false, amountTaken: applicationTotal };
}; */

function FertilisingForm(props) {
  const {
    classes, record, errors, handleBlur, metaData, overrideTotal, handleChange, planting, // block,
  } = props;
  const allowedFertilisers = metaData.fertilisers.filter((f) => !f.isDisabled);
  
  record.endDate = record.endDate ? record.endDate : getToday();
  record.startDate = record.startDate ? record.startDate : getToday();
  if (record.endDate) {
    record.endTime = record.endTime ? record.endTime : getNow();
  }
  let fertCrop;
  let fertUnit;
  if (record.product) {
    //fertCrop = findFertUnits(record.product, planting, metaData.fertilisers);
    const fertiliser = findFertiliser(record.product, metaData.fertilisers);
    fertUnit = fertiliser?.unit;
  }
 /* const totalFertiliser = calculateRequiredFertiliserAmount(record.product,metaData.fertilisers,planting,(planting[0].size ? planting[0].size : block.size),true)
 record.completed = record.completed?record.completed: false;

 if (record.completed && record.completed === true) {
  record.endDate = record.endDate? record.endDate : getToday();
  record.endTime = record.endTime? record.endTime : getNow();
 }
 if (record.completed === false) {
  record.endDate=undefined;
  record.endTime=undefined;
  record.volume=undefined;
}
 if (record.product) {
  record.calculatedProduct=totalFertiliser.amount;
  record.calculatedVolume=totalFertiliser.totalVolume;
  record.productUnit=totalFertiliser.unit;
  if (totalFertiliser.volumePerPlant) {
    record.volumePerPlant=totalFertiliser.volumePerPlant
  }
}
const hasInventoryEntry = hasInventoryRecord(block.sk, planting[0].sk, 'inv_fertiliser', record, props.getFromGlobalState);

*/
  if (record.unit) {
    if (record.unit === 'plant') {
    // record.total = record.volume * planting[0].totalPlants;
      record.totalUnit = fertUnit === 'kg' ? 'g' : 'ml';
    } else {
      record.totalUnit = fertUnit === 'kg' ? 'kg' : 'l';
    }
  }
  if (record.volume && !record.totalOverride) {
    if (record.unit === 'plant') {
      record.total = record.volume * planting[0].totalPlants;
    } else if (record.unit === 'ha') {
      record.total = record.volume * planting[0].size;
    } else {
      record.total = record.volume;
    }
  }

  return (
    <Grid container className={classes.root} spacing={1} alignItems="flex-start">
      <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
        <TextField
          margin="normal"
          required
          fullWidth
          type="date"
    // defaultValue={new Date()}
          id="date"
          label="Date"
          name="date"
          InputLabelProps={{
            shrink: true,
          }}
          value={record.date}
          onChange={handleChange.bind(this)}
          onBlur={handleBlur}
          error={errors.date ? true : undefined}
          helperText={errors.date}
        />
      </Grid>

      {/* } <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
<TextField
    margin="normal"
    //required
    fullWidth
    type="date"
   // defaultValue={new Date()}
    id="endDate"
    label="End Date"
    name="endDate"
    InputLabelProps={{
      shrink: true,
    }}
    value={record.endDate}
    onChange={handleChange.bind(this)}
    onBlur={handleBlur}
    error={errors.endDate ? true : undefined}
    helperText={errors.endDate}
  />
  </Grid> */}
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <TextField
          margin="normal"
    // required
          fullWidth
          type="time"
    // defaultValue={new Date()}
          id="endTime"
          label="End Time"
          name="endTime"
          InputLabelProps={{
            shrink: true,
          }}
          value={record.endTime}
          onChange={handleChange.bind(this)}
          onBlur={handleBlur}
          error={errors.endTime ? true : undefined}
          helperText={errors.endTime}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="product">Fertiliser</InputLabel>
          <Select
            native
            fullWidth
            required
            displayEmpty
            value={record.product}
            onChange={handleChange.bind(this)}
            onBlur={handleBlur.bind(this)}
            error={errors.product ? true : undefined}
            inputProps={{
              name: 'product',
              id: 'product',
            }}
          >
            <option value="" />
            {allowedFertilisers && allowedFertilisers.map((chemical) => (
              <>
                <option value={chemical.sk}>{chemical.name}</option>
              </>
            ))}
          </Select>
          {errors.product && <FormHelperText className={classes.helperText}>{errors.product}</FormHelperText>}
        </FormControl>
      </Grid>

      {/* {record.product && <>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
          margin="normal"
          readOnly
          required
          type="number"
          inputProps={{ 'min': '0.00001', 'step':'any' }}
          fullWidth
          id="calculatedProduct"
          label={`Calculated Product in ${totalFertiliser.unit} / block`}
          name="calculatedProduct"

          value={record.calculatedProduct}
          onChange={handleChange.bind(this)}
          onBlur={handleBlur.bind(this)}
          error={errors.calculatedProduct ? true : undefined}
          helperText={errors.calculatedProduct}
          />
          </Grid>
          <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
        <TextField
        margin="normal"
        readOnly
        required
        type="number"
        // inputProps={{ 'min': '0.00001', 'step':'any' }}
        fullWidth
        id="amountTaken"
        label="Amount taken from inventory"
        name="amountTaken"
        disabled

        value={hasInventoryEntry.amountTaken}
        //onChange={handleChange.bind(this)}
        //onBlur={handleBlur.bind(this)}
        //error={errors.calculatedVolume ? true : undefined}
        //helperText={errors.calculatedVolume}
      />

      </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
          margin="normal"
          readOnly
          required
          type="number"
          inputProps={{ 'min': '0.00001', 'step':'any' }}
          fullWidth
          id="calculatedVolume"
          label="Calculated Volume in Liters / block"
          name="calculatedVolume"

          value={record.calculatedVolume}
          onChange={handleChange.bind(this)}
          onBlur={handleBlur.bind(this)}
          error={errors.calculatedVolume ? true : undefined}
          helperText={errors.calculatedVolume}
          />
          </Grid>
          {record.volumePerPlant &&
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
          margin="normal"
          readOnly
          required
          type="number"
          inputProps={{ 'min': '0.00001', 'step':'any' }}
          fullWidth
          id="volumePerPlant"
          label="Calculated Volume in Liters / plant"
          name="volumePerPlant"

          value={record.volumePerPlant}
          onChange={handleChange.bind(this)}
          onBlur={handleBlur.bind(this)}
          error={errors.volumePerPlant ? true : undefined}
          helperText={errors.volumePerPlant}
          />

          </Grid>
          }

      </> }
        */}

     
   

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <FormControl className={classes.formControl}>
            <InputLabel shrink htmlFor="product">Application per</InputLabel>
            <Select
              native
              fullWidth
              required
              displayEmpty
              value={record.unit}
              onChange={handleChange.bind(this)}
              onBlur={handleBlur.bind(this)}
              error={errors.unit ? true : undefined}
              inputProps={{
                name: 'unit',
                id: 'unit',
              }}
            >
              <option value=''></option>
              <option value="planting">Planting</option>
              <option value="plant">Plant</option>
              <option value="ha">ha</option>

            </Select>
            {errors.unit && <FormHelperText className={classes.helperText}>{errors.unit}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

          <TextField
            margin="normal"
            required
            type="number"
           // inputProps={{ min: '0.00001', step: 'any' }}
            InputLabelProps={{
              shrink: true,
            }}
          // fullWidth
            id="volume"
            label="Applied quantity"
            name="volume"
            InputProps={{
              endAdornment: <InputAdornment position="end">{record.totalUnit ? (record.totalUnit === 'l' ? 'liters' : record.totalUnit)  : ''}</InputAdornment>,
              min: '0.00001',
              step: 'any',
            }}

            value={record.volume}
            onChange={handleChange.bind(this)}
            onBlur={handleBlur.bind(this)}
            error={errors.volume ? true : undefined}
            helperText={errors.volume}
          />

        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

          <TextField
            margin="normal"
            required
            type="number"
          // inputProps={{ 'min': '0.00001', 'step':'any' }}
            InputLabelProps={{
              shrink: true,
              min: '0.00001',
              step: 'any',
            }}
         // fullWidth
            id="total"
            InputProps={{
              endAdornment: <InputAdornment position="end">{record.totalUnit ? (record.totalUnit === 'l' ? 'liters' : record.totalUnit)  : ''}</InputAdornment>,
              min: '0.00001',
              step: 'any',
            }}
            label="Total"
            name="total"
            value={record.total}
            onChange={handleChange.bind(this)}
            onBlur={handleBlur.bind(this)}
            error={errors.total ? true : undefined}
            helperText={errors.total}
          />

        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <FormControlLabel
            value="totalOverride"
            control={(
              <Radio
                color="secondary"
                name="totalHarvestOverride"
                checked={record.totalOverride}
                id="totalOverride"
                onClick={overrideTotal.bind(this)}
              />
)}
            label="Override"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <OperatorSelector
            onChange={handleChange.bind(this)}
            operators={metaData.operators}
            title="Operator"
            value={record.operator}
            team={record.team}
            onBlur={handleBlur}
            error={errors.operator}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            margin="normal"
    // required
            fullWidth
            id="notes"
            label="Notes"
            name="notes"
            multiline
            rowsMax="4"
            value={record.notes}
            onChange={handleChange.bind(this)}
            onBlur={handleBlur.bind(this)}
            error={errors.notes ? true : undefined}
            helperText={errors.notes}
          />
        </Grid>
    

    </Grid>
  );
}

FertilisingForm.propTypes = {
  record: PropTypes.object.isRequired,
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  metaData: PropTypes.object.isRequired,
  planting: PropTypes.object.isRequired,
  block: PropTypes.object.isRequired,
  handleToggle: PropTypes.func.isRequired,
  overrideTotal: PropTypes.func.isRequired,
  metaData: PropTypes.object.isRequired,
};

export default withStyles(styles)(FertilisingForm);
