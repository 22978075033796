import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { validate, validateForm } from '../../libs/validate';
import { addSite } from '../../libs/storage';
import GenDialog from '../../components/GenDialog';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  helperText: {
    color: 'red',
  },
});

const requiredFields = [
  { name: 'name', min: 1, max: 25 },
];

class FarmsD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isNew: this.props.isNew,
      farms: {},
      errors: { count: 0 },
      changed: false,
    };
  }

  componentDidMount() {
    const { isNew, getFromGlobalState, selectedSetting } = this.props;
    if (isNew) {
      const parentFarm = getFromGlobalState('farm') && getFromGlobalState('farm')[0] ? getFromGlobalState('farm')[0].sk : '';
      this.setState({ farms: { parentFarm, settingsToCopy: {}, entityType: 'farm' } });
    } else {
      const farms = selectedSetting;
      this.setState({ farms });
    }
  }

  handleToggle = (value, array) => {
    const { farms } = this.state;
    const newFarms = { ...farms };
    const currentIndex = newFarms.settingsToCopy[array] ? newFarms.settingsToCopy[array].indexOf(value) : -1;

    if (currentIndex === -1) {
      if (!newFarms.settingsToCopy[array]) {
        newFarms.settingsToCopy[array] = [];
      }
      newFarms.settingsToCopy[array].push(value);
    } else {
      newFarms.settingsToCopy[array].splice(currentIndex, 1);
    }

    this.setState({ farms: newFarms, changed: true });
  }

  handleBlur = (event) => this.validateField(event.target.id, requiredFields)

  handleChange = (event) => {
    const { farms } = this.state;
    const newFarms = { ...farms };
    newFarms[event.target.id] = event.target.value;
    this.setState({ farms: newFarms, changed: true });
  }

  handleCheckChange = (event) => {
    const { farms } = this.state;
    const newFarms = { ...farms };
    newFarms[event.target.id] = event.target.checked;
    this.setState({ farms: newFarms, changed: true });
  }

  async handlePreExecute(farmIn) {
    const { isNew } = this.state;
    const { getFromGlobalState } = this.props;

    try {
      farmIn.isNew = isNew;
      farmIn.activeTenant = getFromGlobalState('activeSite');
      const res = await addSite(farmIn);
      farmIn.newTenant = res.newTenant.item.tenantId;
      const siteList = getFromGlobalState('siteList');
      const updatedIndex = siteList.findIndex((i) => i.tenantId === res.newTenant.item.tenantId);
      if (updatedIndex === -1) {
        siteList.push(res.newTenant.item);
      } else {
        siteList[updatedIndex] = res.newTenant.item;
      }
      this.props.setGlobalState({ siteList });
    } catch (e) {
      throw (e);
    }

    return true;
  }

  validateField(field, requiredFields) {
    const error = validate(field, this.state.farms, requiredFields);
    const errors = { ...this.state.errors };
    errors[field] = error.msg;
    this.setState({ errors });
  }

  render() {
    const {
      open, isNew, setClose, handleUpdate, getFromGlobalState,
    } = this.props;
    const { farms, errors } = this.state;

    const formValid = validateForm(farms, requiredFields);

    return (

      <GenDialog
        open={open}
        dialogObject={farms}
        setClose={setClose}
        isNew={isNew}
        handleUpdateDialogObject={handleUpdate.bind(this)}
        skPrimary="init_setting_"
        dynamoType="farms"
        valid={formValid}
        changed={this.state.changed}
        newTitle="New Farm"
        title={farms.name}
        getFromGlobalState={getFromGlobalState}
        handlePreExecute={this.handlePreExecute.bind(this)}
      >
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Short Name"
              name="name"
              autoFocus
              value={farms.name}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.name ? true : undefined}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
           // required
              fullWidth
              id="description"
              label="Description"
              name="description"
              value={farms.description}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.description ? true : undefined}
              helperText={errors.description}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={farms.isDisabled}
                 // disabled={isInvite}
                  id="isDisabled"
                  onChange={this.handleCheckChange}
                  value="isDisabled"
                  color="secondary"
                />
        )}
              label="Disabled"
            />
          </Grid>
        </Grid>
        {/* {this.state.isNew && <Typography>Settings to copy to new farm (optional)</Typography>}

           {this.state.isNew && availableSettings && availableSettings.map ((setting) =>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
         <Typography>{setting.name}</Typography>
           <SingleListControl
            data= {getFromGlobalState(setting.key)}
            checkedItems ={farms.settingsToCopy && farms.settingsToCopy[setting.key] ? farms.settingsToCopy[setting.key] : []}
            handleToggle =  {this.handleToggle}
            arrayName={setting.key}
          />
          </Grid>
           )} */}

      </GenDialog>

    );
  }
}
FarmsD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  selectedSetting: PropTypes.object,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  handleUpdate: PropTypes.func.isRequired,
  metaData: PropTypes.array,
  getFromGlobalState: PropTypes.func.isRequired,

};
export default withStyles(styles)(FarmsD);
