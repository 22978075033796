import React from 'react';
import PropTypes from 'prop-types';
import EnhancedTable from '../Etable';
import settings from '../../libs/settings';
import { getDataByRecordType } from '../../libs/analytics';

const { RECENTS_LEN } = settings.constants;

export default function ReportByActivity(props) {
  const {
    recordType, handleSelect, getFromGlobalState, blocks, clean, displayToolbar, noPagination, hideHeader, handleAddItem, selectedRow, showAll, handleShowDetail, showDetail,
  } = props;
  const data = getDataByRecordType(recordType, blocks, getFromGlobalState, clean, showAll, RECENTS_LEN, undefined, undefined, true, true);
  const userDetail = getFromGlobalState('userDetail');
  console.log(data.rows);
  return (
    <div style={{ paddingLeft: '2px', paddingRight: '2px' }}>
      {/* {!clean && <ReportHeader block={block} planting={planting} farm={farm} getFromGlobalState={getFromGlobalState}></ReportHeader>} */}

      <EnhancedTable
        displayToolbar={displayToolbar}
        noPagination={noPagination}
        hideHeader={hideHeader}
        heading={data.title}
        rows={showAll ? data.rows : data.mostRecent}
        headCells={data.headCells}
        handleSelect={handleSelect}
        handleAddItem={userDetail && userDetail.rights && !userDetail.rights.readOnly ? handleAddItem : null}
             // parent={planting}
              // grandParentSK={block.sk}
        actionType="actionType"
        rowKeyName="sk"
        recordType={recordType}
        clean={clean}
        selectedRow={selectedRow}
        ancestorsInRow
        showDetailSelected={showDetail}
        handleShowDetail={handleShowDetail}
        maxHeight="350px"
        dense={!!showDetail}
      />
    </div>
  );
}
ReportByActivity.propTypes = {
  isloading: PropTypes.bool.isRequired,
  children: PropTypes.any,
  recordType: PropTypes.string.isRequired,
  blocks: PropTypes.array.isRequired,
  handleSelect: PropTypes.func.isRequired,
  clean: PropTypes.bool,
  displayToolbar: PropTypes.bool,
  noPagination: PropTypes.bool,
  hideHeader: PropTypes.bool,
  handleAddItem: PropTypes.func,
  selectedRow: PropTypes.string,
  showAll: PropTypes.bool,
  handleShowDetail: PropTypes.func,
  showDetail: PropTypes.bool,
  noReload: PropTypes.bool,
  getFromGlobalState: PropTypes.func,

};
