import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Badge from '@material-ui/core/Badge';
import InfoIcon from '@material-ui/icons/InfoRounded';
import PhotoIcon from '@material-ui/icons/PhotoCameraRounded';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MinusIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import PlusIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import AbsentIcon from '@material-ui/icons/CancelOutlined';
import PresentIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import MoveRightIcon from '@material-ui/icons/ArrowForwardOutlined';
import MoveLeftIcon from '@material-ui/icons/ArrowBackOutlined';
import UpIcon from '@material-ui/icons/ArrowUpwardOutlined';
import DownIcon from '@material-ui/icons/ArrowDownwardOutlined';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import MobileStepper from '@material-ui/core/MobileStepper';
import Tabs from '../../components/Tabs';
import LoaderButton from '../../components/LoaderButton';
import TargetDetailD from '../TargetDetailD';
import MonitoringPictureD from '../MonitoringPictureD';

import ExpansionPanelDetailed from '../../components/ExpansionPanelDetail';

const styles = (theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  formControl: {
    maxWidth: '70px',
    marginTop: '0px',
    marginBottom: '0px',
  },
  group: {
    flexDirection: 'row',
  },
  formControlRadio: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1),
  },
  fabProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: 12,
    left: 0,
    zIndex: 1,
  },
});

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 0,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);

/* const filterItems = (arr, planting, target, fieldOrFixed) => {
  const plantingCrop = planting[0].crop;
  let allowedPests = [];
  if (!target) {
    return allowedPests;
  }
  if (target === 'Other') {
    allowedPests = arr.filter((f) => f.monitorType === fieldOrFixed && f.target === target);
  } else {
    // find the crop
    arr.forEach((pest) => {
      const idx = pest.targetDetail.observableOn.findIndex((c) => c.sk === plantingCrop);
      if (idx !== -1) {
        allowedPests.push(pest);
      }
    });
  }
  return allowedPests;
}; */
const findEntry = (record, set, subSet, s) => {
  const currentIdx = record.observations ? record.observations.findIndex((r) => r.stageSK === s.sk) : -1;
  const hasEntry = !!(currentIdx !== -1 && record.observations[currentIdx].entries.length > (set) && record.observations[currentIdx].entries[set].length > (subSet));
  return hasEntry;
};

const getCounterIcon = (direction, unitNum, notReady) => {
  let incrementIcon;
  if (direction === 'right') {
    if (unitNum === 'Number' || unitNum === 'Percentage') {
      incrementIcon = <PlusIcon color={notReady ? 'primary' : 'secondary'} style={{ fontSize: '36px' }} size={36} />;
    } else if (unitNum === 'Present') {
      incrementIcon = <PresentIcon color={notReady ? 'primary' : 'secondary'} style={{ fontSize: '36px' }} size={36} />;
    } else {
      incrementIcon = <UpIcon color={notReady ? 'primary' : 'secondary'} style={{ fontSize: '36px' }} size={36} />;
    }
  } else if (direction === 'left') {
    if (unitNum === 'Number' || unitNum === 'Percentage') {
      incrementIcon = <MinusIcon color={notReady ? 'primary' : 'secondary'} style={{ fontSize: '36px' }} size={36} />;
    } else if (unitNum === 'Present') {
      incrementIcon = <AbsentIcon color={notReady ? 'primary' : 'secondary'} style={{ fontSize: '36px' }} size={36} />;
    } else {
      incrementIcon = <DownIcon color={notReady ? 'primary' : 'secondary'} style={{ fontSize: '36px' }} size={36} />;
    }
  }
  return incrementIcon;
};

export const renderCounter = (monitoring,
  s,
  setInfo,
  record,
  onChangeCounter,
  handleBlur,
  onSubtract,
  onAdd,
  disabledSubract,
  disableAdd,
  classes,
  onForwardSubset,
  onBackSubset,
  planting,
  onTakePhoto,
  onShowTargetDetail,
  currentLocation,
  isMobile,
  hasLocation,
  ignoreLocation) => {
  let currentValue = '';
  const currentIdx = record.observations ? record.observations.findIndex((r) => (r.stageSK === s.sk) || (r.targetDetailSK === s.sk )) : -1;
  const set = setInfo && setInfo.set ? setInfo.set : 0;
  const subSet = setInfo[s.sk] && setInfo[s.sk].subSet ? setInfo[s.sk].subSet : 0;
  const hasEntry = findEntry(record, set, subSet, s);
  const notReady = !ignoreLocation && !hasLocation && ((!currentLocation) || (currentLocation && isMobile && currentLocation.accuracy > 50));
  let imageCount = 0;
  currentValue = hasEntry ? record.observations[currentIdx].entries[set][subSet] : '';
  if (currentIdx !== -1) {
    if (s.unitNum === 'Present') {
      if (currentValue === 1) {
        currentValue = 'Present';
      }
      if (currentValue === 0) {
        currentValue = 'Absent';
      }
    }else{
      const currentV = currentValue.length >= 1 ? currentValue[0]:0;
      currentValue = record.observations[currentIdx].entries[set][subSet];
      console.log(currentValue,currentV,record.observations[currentIdx].entries[set][subSet]);
    }
    imageCount = record.observations[currentIdx].pictures && record.observations[currentIdx].pictures[set] && record.observations[currentIdx].pictures[set][subSet] ? record.observations[currentIdx].pictures[set][subSet].length : 0;
  }else{
    console.log(record.observations,s);
  }


  const rightIcon = getCounterIcon('right', s.unitNum, notReady);// (s.unitNum === 'Number' || s.unitNum === 'Percentage') ? <PlusIcon color="secondary" style={{ fontSize: '36px' }} size={36} /> : <PresentIcon color="secondary" style={{ fontSize: '36px' }} size={36} />;
  const leftIcon = getCounterIcon('left', s.unitNum, notReady); // (s.unitNum === 'Number' || s.unitNum === 'Percentage') ? <MinusIcon color="secondary" style={{ fontSize: '36px' }} size={36} /> : <AbsentIcon color="secondary" style={{ fontSize: '36px' }} size={36} />;
  return (
    <>
      <div>
        <div style={{
          overflow: 'hidden', display: 'inline-block', maxWidth: '65%', whiteSpace: 'nowrap', textOverflow: 'ellipsis',
        }}
        >
          <InputLabel style={{ display: 'inline', maxWidth: '60px' }} shrink>{s.stageDescription ? `${s.stageDescription} (${s.unit ? s.unit : ''})` : ''}</InputLabel>
        </div>
        <div style={{ float: 'right' }}>
          <IconButton onClick={(event) => onTakePhoto(event, monitoring, s.sk, set, subSet, s.unitNum)}>
            <StyledBadge color="primary" badgeContent={imageCount}>
              <PhotoIcon
                color="secondary"
              />
            </StyledBadge>
          </IconButton>
          <IconButton onClick={(event) => onShowTargetDetail(event, monitoring, s.sk, set, subSet, s.unitNum)}>
            <StyledBadge color="primary" badgeContent={0}>
              <InfoIcon
                color="secondary"
              />
            </StyledBadge>
          </IconButton>
        </div>
      </div>
      <div style={{
        width: '100%', textAlign: 'center', whiteSpace: 'nowrap', marginTop: '12px',
      }}
      >

        {s.quantityOfUnit && parseInt(s.quantityOfUnit) > 1 && (
        <div style={{ display: 'inline-block', marginTop: '14px', marginBottom: '14px' }}>
          <IconButton
            edge="start"
            color="inherit"
            disabled={subSet === 0}
       // style={{color:'white'}}
            onClick={(event) => onBackSubset(event, s.sk)}
         // style={{ marginLeft: '0px' }}
     // className={buttonClassname}
            aria-label="save"
           // disabled={subset === 0}
          >
            <MoveLeftIcon color={subSet === 0 ? 'primary' : 'secondary'} style={{ fontSize: '36px' }} size={36} />
          </IconButton>
        </div>
        )}
        <Badge
          color={s.quantityOfUnit && parseInt(s.quantityOfUnit) > 1
        && subSet + 1 === parseInt(s.quantityOfUnit) && hasEntry
        || (hasEntry && subSet + 1 >= parseInt(s.quantityOfUnit)) ? 'primary' : 'error'}
          showZero
          invisible={!s.quantityOfUnit || (s.quantityOfUnit && parseInt(s.quantityOfUnit) === 1)}
         // badgeContent={s.quantityOfUnit && parseInt(s.quantityOfUnit) > 1 ? `${subSet + 1}/${parseInt(s.quantityOfUnit)} ${s.unit}` : 0}
         badgeContent={s.quantityOfUnit && parseInt(s.quantityOfUnit) > 1 ? `${subSet + 1}/${parseInt(s.quantityOfUnit)} Obs` : 0}
          className={classes.badge}
        >
          <Paper elevation={3}>
            <div style={{ display: 'inline-block' }}>
              <IconButton
                edge="end"
                disabled={notReady}
                // disabled={!hasLocation && ((!currentLocation) || (currentLocation && isMobile && currentLocation.accuracy > 10))}
          // style={{ marginRight: '24px' }}
                onClick={(event) => onSubtract(event, monitoring, s.sk, set, subSet, s.unitNum)}
                aria-label="save"
               // disabled={disabledSubract}
              >
                {leftIcon}
              </IconButton>
            </div>
            <div style={{ display: 'inline-block' }}>
              <FormControl className={classes.formControl}>

                <TextField
                  margin="normal"
                  required
                  disabled={notReady}
                  // disabled={(!currentLocation) || (currentLocation && isMobile && currentLocation.accuracy > 10)}
                  shrink
                  style={{
                    marginTop: '0px', marginBottom: '0px', marginLeft: '6px', marginRight: '6px',
                  }}
                  id={s.sk}
                  name={s.sk}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={currentValue}
                  onChange={(event) => onChangeCounter(event, monitoring, s.sk, set, subSet, s.unitNum)}
                  onBlur={handleBlur}
                />
              </FormControl>
            </div>
            <div style={{ display: 'inline-block' }}>
              <IconButton
                edge="start"
                disabled={notReady}
                // disabled={!hasLocation && ((!currentLocation) || (currentLocation && isMobile && currentLocation.accuracy > 10))}
                onClick={(event) => onAdd(event, monitoring, s.sk, set, subSet, s.unitNum)}
                aria-label="save"
               // disabled={disableAdd}
              >
                {rightIcon}
              </IconButton>
            </div>
          </Paper>
        </Badge>
        {s.quantityOfUnit && s.quantityOfUnit > 1 && (
        <div style={{ display: 'inline-block' }}>
          <IconButton
            edge="end"
            color="inherit"
       // style={{color:'white'}}
            onClick={(event) => onForwardSubset(event, s.sk)}
         // style={{ paddingLeft: '24px' }}
     // className={buttonClassname}
            aria-label="save"
            disabled={!hasEntry}
          >
            <MoveRightIcon color={hasEntry ? 'secondary' : 'primary'} style={{ fontSize: '36px' }} size={36} />
          </IconButton>
        </div>
        )}
      </div>
    </>
  );
};

export const hasStage = (monitoring) => {
  if (!monitoring) {
    return false;
  }
  if (monitoring.cropSpecific === 'No') {
    return true;
  }
  if (!monitoring.targetDetail
    || !monitoring.targetDetail.observableOn.length
    || !monitoring.targetDetail.observableOn[0].stages.length
    || !monitoring.targetDetail.observableOn[0].stages.filter((s) => s.selected).length) {
    return false;
  }

  return true;
};

const filterMonitorings = (arr, monitorType, planting) => {
  const plantingCrop = planting[0].crop;
  const monitorings = [];
  for (let i = 0; i < arr.length; i += 1) {
    if (arr[i].targetDetail) {
      if (arr[i].targetDetail.observableOn[0]) {
        if (arr[i].cropSpecific === 'Yes' && arr[i].targetDetail.observableOn[0].sk === plantingCrop) {
          const stages = arr[i].targetDetail.observableOn[0].stages.filter((f) => f.monitorType === monitorType);
          if (stages.length > 0) {
            const localMonitor = { ...arr[i] };
            localMonitor.targetDetail.observableOn[0].stages = [...stages];
            monitorings.push(localMonitor);
          }
        }
      } else if (arr[i].targetDetail.nonCropDetail.monitorType === monitorType) {
        const localMonitor = { ...arr[i] };
        monitorings.push(localMonitor);
      }
    }
  }
  return monitorings;
};
function PestingForm(props) {
  const {
    classes, record,
    getFromGlobalState,
    handleTabChange,
    selectedTab,
    handleSettingExpand,
    expandedSetting,
    errors,
    handleBlur,
    handleChange,
    onAdd,
    onSubtract,
    metaData,
    planting,
    onForwardSubset,
    onBackSubset,
    setInfo,
    onForwardSet,
    onBackSet,
    isMobile,
    onChangeCounter,
    currentLocation,
    onTakePhoto,
    onShowTargetDetail,
    displayTargetDetail,
    selectedTargetDetail,
    onCloseTargetDetail,
    setGlobalState,
    displayMonitoringPictures,
    monitoringPicture,
    onCloseMonitoringPicture,
    fileUploaded,
    uploading,
    onFileUploadStart,
    isInBlock,
    block,
    foundBlock,
    handleSwitchChange,
    isNew,
    setSelectedTab
  } = props;


  // const monitorings = filterItems(metaData.monitorings, planting, record.target, 'Field');
  const hasLocation = !!((record && record.geoLocations && record.geoLocations[setInfo.set] && record.geoLocations[setInfo.set].accuracy));
  const monitorings = filterMonitorings(metaData.monitorings.filter((f) => f.isDisabled !== true), 'Field', planting);
  const numPlantsToObserverve = Math.round(parseInt(planting[0].totalPlants) * 2 / 100);
  const targetTypes = metaData.targetTypes ? metaData.targetTypes.filter((el) => monitorings.some((m) => m.targetType === el.sk)) : [];
 
  // const selectedPest = record.pest ? monitorings.find(((p) => p.targetDetail.sk === record.pest)) : null;
  //  if (selectedPest && !record.numPointsScoutedOverride) {
  //    record.numPointsScouted = selectedPest.quantityOfUnit;
  //  }

  useEffect(()=>{
    if(!isNew){
      const monitorings = filterMonitorings(metaData.monitorings.filter((f) => f.isDisabled !== true), 'Field', planting);
      const targetTypes = metaData.targetTypes ? metaData.targetTypes.filter((el) => monitorings.some((m) => m.targetType === el.sk)) : [];
      const filterdtargetTypes = targetTypes.map((x,index)=>x.sk);
      const monitoringSk = record?.observations[0]?.monitoringSK || null;
      const monitoring = monitorings?.find((x)=>x.sk === monitoringSk).targetType;
      const TargetType = filterdtargetTypes.indexOf(monitoring);
      setSelectedTab(TargetType)
    }
  },[])

 
  return (
    <Grid container className={classes.root} spacing={1} alignItems="flex-end">
      {displayTargetDetail && (
        <div style={{ zIndex: 2000 }}>
          <TargetDetailD
            targetStage={selectedTargetDetail}
            getFromGlobalState={getFromGlobalState}
            onClose={onCloseTargetDetail}
            setGlobalState={setGlobalState}
          />
        </div>
      )}

      {displayMonitoringPictures && (
      <div style={{ zIndex: 2000 }}>
        <MonitoringPictureD
          monitoring={monitoringPicture}
          getFromGlobalState={getFromGlobalState}
          onClose={onCloseMonitoringPicture}
          setGlobalState={setGlobalState}
          fileUploaded={fileUploaded}
          record={record}
          uploading={uploading}
          onFileUploadStart={onFileUploadStart}
        />
      </div>
      )}

      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <TextField
          margin="normal"
          required

          fullWidth
          type="date"
   // defaultValue={new Date()}
          id="date"
          label="Date"
          name="date"
          InputLabelProps={{
            shrink: true,
          }}
          value={record.date}
          onChange={handleChange.bind(this)}
          onBlur={handleBlur}
          error={errors.date ? true : undefined}
          helperText={errors.date}
        />

        <FormControlLabel
          control={(
            <Switch
              checked={record.ignoreLocation}
              onChange={handleSwitchChange.bind(this)}
              name="ignoreLocation"
              id="ignoreLocation"
              color="primary"
            />
        )}
          label="Ignore location"
        />
      </Grid>
      { currentLocation && (
      <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
        <Typography style={{ margin: '5px', float: 'right' }} variant="subtitle1">
          {/* {`${currentLocation && currentLocation ? `lat: ${currentLocation.latitude} lng: ${currentLocation.longitude} acc: ${Math.round(currentLocation.accuracy)} m` : 'Locating...'}`} */}
          {`${currentLocation && currentLocation ? `Accuracy: ${currentLocation.accuracy.toFixed(2)} m` : 'Locating...'}`}
          <MyLocationIcon style={!currentLocation ? { color: 'red', paddingLeft: '8px' } : (currentLocation && currentLocation?.accuracy < 50 ? { color: 'green', paddingLeft: '8px' } : { color: 'orange', paddingLeft: '8px' })} />
        </Typography>

      </Grid>
      )}
      {!isInBlock && !record.ignoreLocation && (
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography color={!isInBlock ? 'error' : undefined} style={{ margin: '5px' }} variant="subtitle1">
          { `You are not in block: ${block.number}, you are in block: ${foundBlock ? `${foundBlock.number}. ${' Please move to that block or monitor for the block you are in.'}` : 'finding block...'}`}
        </Typography>
      </Grid>
      )}

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

        <Tabs value={selectedTab} handleChange={handleTabChange}>
          {targetTypes && targetTypes.map((type) => (
            <div key={type.sk} heading={type.name} name={type.name} style={{ width: '100%' }}>
              {monitorings && monitorings.filter((m) => m.targetType === type.sk).map((monitoring) => (
                hasStage(monitoring) && (
                <ExpansionPanelDetailed
                  heading={monitoring.targetDetail.name}
                  key={monitoring.targetDetail.sk}
                  overwriteDisplay
                  expanded={expandedSetting}
                  handleExpand={handleSettingExpand}
                >
                  {expandedSetting && expandedSetting === monitoring.targetDetail.name && monitoring.cropSpecific === 'Yes' && monitoring && monitoring.targetDetail && monitoring.targetDetail.observableOn && monitoring.targetDetail.observableOn.length && monitoring.targetDetail.observableOn[0].stages.filter((ff) => ff.selected).map((s) => (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      {renderCounter(monitoring,
                        s,
                        setInfo,
                        record,
                        onChangeCounter,
                        handleBlur,
                        onSubtract,
                        onAdd,
                        false,
                        false,
                        classes,
                        onForwardSubset,
                        onBackSubset,
                        planting,
                        onTakePhoto,
                        onShowTargetDetail,
                        currentLocation,
                        isMobile,
                        hasLocation,
                        record.ignoreLocation)}
                    </Grid>
                  ))}
                  {expandedSetting && expandedSetting === monitoring.targetDetail.name && monitoring.cropSpecific === 'No' && monitoring && monitoring.targetDetail && monitoring.targetDetail.nonCropDetail
                    && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      {renderCounter(monitoring,
                        monitoring.targetDetail,
                        setInfo,
                        record,
                        onChangeCounter,
                        handleBlur,
                        onSubtract,
                        onAdd,
                        false,
                        false,
                        classes,
                        onForwardSubset,
                        onBackSubset,
                        planting,
                        onTakePhoto,
                        onShowTargetDetail,
                        currentLocation,
                        isMobile,
                        hasLocation,
                        record.ignoreLocation)}
                    </Grid>
                    )}
                </ExpansionPanelDetailed>
                )
              ))}

            </div>
          ))}

        </Tabs>

      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} />
      {/*  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Location
          onChange={handleLocationChange.bind(this)}
          value={record.geoLocation}
          onBlur={handleBlur.bind(this)}
          id="geoLocation"
          required
          name="geoLocation"
          setSnack={setSnack}
        />
                      </Grid> */}
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <MobileStepper
          variant="progress"
          style={isMobile ? {
            position: 'absolute',
            width: '80%',
            zIndex: 1000,
            bottom: 0,
            color: setInfo.set + 1 < numPlantsToObserverve ? 'red' : 'green',
          } : { color: setInfo.set + 1 < numPlantsToObserverve ? 'red' : 'green' }}
          backgroundColor="inherit"
          steps={numPlantsToObserverve}
          nextButton={(
            <>
              <Typography style={{ margin: '5px' }} variant="subtitle1">
                {`${setInfo.set ? setInfo.set + 1 : 1}/${numPlantsToObserverve}`}
              </Typography>

              <LoaderButton
            // type="submit"
                onClick={() => onForwardSet(false)}
                variant="contained"
                color="primary"
                className={classes.submit}
               // disabled={!hasLocation && (loadingSet || (!currentLocation) || (currentLocation && isMobile && currentLocation.accuracy > 10) || (setInfo.set && setInfo.set + 1 === numPlantsToObserverve))}
                disabled={!record.ignoreLocation && !hasLocation && ((!currentLocation) || (currentLocation && isMobile && currentLocation.accuracy > 50))}
                // text={setInfo.set && setInfo.set === numPlantsToObserverve ? 'Done' : 'Next'}
                text="Next"
                loadingText="Next"
              />

            </>
      )}
          backButton={(
            <Button
              variant="contained"
              color="secondary"
              className={classes.submit}
              onClick={onBackSet}
              disabled={setInfo && setInfo.set ? setInfo.set === 0 : true}
            >
              Back
            </Button>
      )}
          position="static"
          activeStep={setInfo && setInfo.set ? (setInfo.set + 1 > numPlantsToObserverve ? numPlantsToObserverve : setInfo.set) : 0}
          className={classes.stepper}
        />

      </Grid>

      {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <OperatorSelector
          onChange={handleChange.bind(this)}
          operators={metaData.operators}
          title="Operator"
          value={record.operator}
          team={record.team}
          onBlur={handleBlur.bind(this)}
          error={errors.operator}
        />
          </Grid> */}
      <Grid item xs={4} sm={4} md={8} lg={8} xl={8} />
    </Grid>
  );
}

PestingForm.propTypes = {
  record: PropTypes.object.isRequired,
  errors: PropTypes.object,
  selectedTab: PropTypes.number.isRequired,
  setSelectedTab:PropTypes.func,
  expandedSetting: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSettingExpand: PropTypes.func.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  handleLocationChange: PropTypes.func.isRequired,
  handleSwitchChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  metaData: PropTypes.object.isRequired,
  planting: PropTypes.object.isRequired,
  onAdd: PropTypes.func.isRequired,
  onSubtract: PropTypes.func.isRequired,
  onForwardSubset: PropTypes.func.isRequired,
  onBackSubset: PropTypes.func.isRequired,
  setInfo: PropTypes.object.isRequired,
  onForwardSet: PropTypes.func.isRequired,
  onBackSet: PropTypes.func.isRequired,
  setSnack: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  currentLocation: PropTypes.object,
  onChangeCounter: PropTypes.func.isRequired,
  onTakePhoto: PropTypes.func.isRequired,
  onShowTargetDetail: PropTypes.func.isRequired,
  displayTargetDetail: PropTypes.bool.isRequired,
  selectedTargetDetail: PropTypes.object.isRequired,
  onCloseTargetDetail: PropTypes.func.isRequired,
  setGlobalState: PropTypes.func.isRequired,
  displayMonitoringPictures: PropTypes.bool.isRequired,
  monitoringPicture: PropTypes.bool.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  onCloseMonitoringPicture: PropTypes.func.isRequired,
  fileUploaded: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
  onFileUploadStart: PropTypes.func.isRequired,
  isInBlock: PropTypes.bool.isRequired,
  block: PropTypes.object.isRequired,
  foundBlock: PropTypes.object,
};

export default withStyles(styles)(PestingForm);
