/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { Link } from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Badge from '@material-ui/core/Badge';
import BlockIcon from '@material-ui/icons/ViewWeekOutlined';
import DashboardIcon from '@material-ui/icons/EqualizerOutlined';
import RecordsIcon from '@material-ui/icons/FolderOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import InventoryIcon from '@material-ui/icons/LocalMallOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import RegisterOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import HelpIcon from '@material-ui/icons/HelpOutlineOutlined';
import AboutIcon from '@material-ui/icons/InfoOutlined';
import FolderIcon from '@material-ui/icons/AttachFileOutlined';
import EmailIcon from '@material-ui/icons/EmailOutlined';

import Divider from '@material-ui/core/Divider';

import { withStyles } from '@material-ui/core/styles';
import { MenuList, MenuItem } from '@material-ui/core';
import Can from '../Can';

const drawerWidth = 240;

const styles = (theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  badgePadding: {
    // padding: theme.spacing(0, 2),
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  menuList: {
    paddingTop: '30px',
    paddingBottom: '0px',
    color: 'inherit',
  },
  selected: {
    color: theme.palette.secondary.main,
  },
  icon: {
    color: 'inherit',
  },
});

const StyledBadge = withStyles((theme) => ({
  badge: {
    top: '30%',
    right: -15,
    color: theme.palette.secondary.main,
    // The border color match the background color.
    border: `1px solid ${
      theme.palette.secondary.main
    }`,
  },
}))(Badge);

class ResponsiveDrawer extends React.Component {
  render() {
    const {
      classes,
      theme,
      mobileopen,
      handleDrawerToggle,
      userDetail,
      online,
      data,
      industryView,
      location: { pathname },
      isAuthenticated, handleLogout,
    } = this.props;

    const drawer = (
      <div>

        <Hidden xsDown>
          <div className={classes.toolbar} />
        </Hidden>
        <MenuList className={classes.menuList}>

          {isAuthenticated && !industryView && (
            <>
              {' '}
              <MenuItem
                classes={{ selected: classes.selected }}
                component={Link}
                to="/"
                selected={pathname === '/'}
                onClick={handleDrawerToggle}
              >
                <ListItemIcon className={classes.icon}>
                  <BlockIcon color="inherit" />
                </ListItemIcon>
                Blocks
              </MenuItem>
              <MenuItem
                classes={{ selected: classes.selected }}
                component={Link}
                onClick={handleDrawerToggle}
                to="/records"
                selected={pathname === '/records'}
              >
                <ListItemIcon className={classes.icon}>
                  <RecordsIcon color="inherit" />
                </ListItemIcon>
                Records
              </MenuItem>
              <Can
                role={userDetail && userDetail.rights && userDetail.rights.viewDashboard ? 'viewDashboard' : 'noAccess'}
                perform="viewDashboard"
                isAdministrator={userDetail && userDetail.rights && userDetail.rights.administrator}
                yes={() => (
                  <MenuItem
                    classes={{ selected: classes.selected }}
                    component={Link}
                    to="/dashboard"
                    selected={pathname === '/dashboard'}
                    onClick={handleDrawerToggle}
                  >
                    <ListItemIcon className={classes.icon}>
                      <DashboardIcon color="inherit" />
                    </ListItemIcon>
                    Dashboard
                  </MenuItem>
                )}
              />
              <Can
                role={userDetail && userDetail.rights && userDetail.rights.manageInventory ? 'manageInventory' : 'noAccess'}
                perform="openInventoryPage"
                yes={() => (
                  <MenuItem
                    classes={{ selected: classes.selected }}
                    component={Link}
               // disabled
                    to="/inventory"
                    selected={pathname === '/inventory'}
                    onClick={handleDrawerToggle}
                  >
                    <ListItemIcon className={classes.icon}>
                      <InventoryIcon color="inherit" />
                    </ListItemIcon>
                    Inventory
                  </MenuItem>
                )}
              />
              <Can
                role={userDetail && userDetail.rights && userDetail.rights.changeSettings ? 'changeSettings' : 'noAccess'}
                perform="openDocumentsPage"
                yes={() => (
                  <MenuItem
                    classes={{ selected: classes.selected }}
                    component={Link}
                    to="/documents"
                    selected={pathname === '/documents'}
                    onClick={handleDrawerToggle}
                  >
                    <ListItemIcon className={classes.icon}>
                      <FolderIcon color="inherit" />
                    </ListItemIcon>
                    Documents
                  </MenuItem>
                )}
              />

              {industryView && (
              <MenuItem
                classes={{ selected: classes.selected }}
                component={Link}
                to="/census"
                selected={pathname === '/census'}
                onClick={handleDrawerToggle}
              >
                <ListItemIcon className={classes.icon}>
                  <FolderIcon color="inherit" />
                </ListItemIcon>
                Census
              </MenuItem>
              )}
              <Can
                role={userDetail && userDetail.rights && userDetail.rights.changeSettings ? 'changeSettings' : 'noAccess'}
                perform="openSettingsPage"
                yes={() => (
                  <MenuItem
                    classes={{ selected: classes.selected }}
                    component={Link}
                    to="/settings"
                    selected={pathname === '/settings'}
                    onClick={handleDrawerToggle}
                  >
                    <ListItemIcon className={classes.icon}>
                      <SettingsIcon color="inherit" />
                    </ListItemIcon>
                    Settings
                  </MenuItem>
                )}
              />

              <MenuItem
                classes={{ selected: classes.selected }}
                component={Link}
                to="/help"
                selected={pathname === '/help'}
                onClick={handleDrawerToggle}
              >
                <ListItemIcon className={classes.icon}>
                  <HelpIcon color="inherit" />
                </ListItemIcon>
                Help
              </MenuItem>

            </>
          )}
          <Divider />
          {isAuthenticated ? (
            <>
              <MenuItem
                classes={{ selected: classes.selected }}
                component={Link}
                to="/messages"
                selected={pathname === '/messages'}
                onClick={handleDrawerToggle}
              >
                <ListItemIcon className={classes.icon}>
                  <EmailIcon color="inherit" />
                </ListItemIcon>
                <StyledBadge invisible={data && data.messages ? (data.messages.length <= 0) : true} color="primary" badgeContent={data && data.messages && data.messages.length}>
                  Messages
                </StyledBadge>
              </MenuItem>
              <MenuItem
                classes={{ selected: classes.selected }}
                onClick={handleLogout}
                disabled={!online}
              >
                <ListItemIcon className={classes.icon}>
                  <LockOutlinedIcon color="inherit" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </>
          )

            : (
              <>
                <MenuItem disabled component={Link} to="/signup">
                  <ListItemIcon>
                    <RegisterOutlinedIcon color="default" />
                  </ListItemIcon>
                  Sign Up
                </MenuItem>
                <MenuItem component={Link} to="/login" classes={{ selected: classes.selected }}>
                  <ListItemIcon className={classes.icon}>
                    <LockOpenOutlinedIcon color="inherit" />
                  </ListItemIcon>
                  Login
                </MenuItem>
              </>
            )}
          {!industryView && (
          <MenuItem
            component={Link}
            to="/version"
            classes={{ selected: classes.selected }}
            selected={pathname === '/version'}
            onClick={handleDrawerToggle}
          >
            <ListItemIcon className={classes.icon}>
              <AboutIcon color="inherit" />
            </ListItemIcon>
            About
          </MenuItem>
          )}
          {isAuthenticated && userDetail && userDetail.rights && userDetail.rights.ldadmin
            && (
            <>
              <Divider />
              <MenuItem
                classes={{ selected: classes.selected }}
                component={Link}
                to="/admin"
                selected={pathname === '/admin'}
                onClick={handleDrawerToggle}
              >
                <ListItemIcon className={classes.icon}>
                  <InventoryIcon color="inherit" />
                </ListItemIcon>
                Admin
              </MenuItem>
            </>
            )}
        </MenuList>
      </div>
    );
    return (
      <nav className={classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileopen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            onClick={handleDrawerToggle}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="persistent"
            open={mobileopen}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    );
  }
}

ResponsiveDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  // container: PropTypes.object,
  theme: PropTypes.object.isRequired,
  mobileopen: PropTypes.bool.isRequired,
  industryView: PropTypes.bool.isRequired,
  userDetail: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  handleLogout: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  online: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles, { withTheme: true })(ResponsiveDrawer);
