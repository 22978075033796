import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import FertiliserD from '../FertiliserD';
import FertCropD from '../FertCropD';
import { getNameFromKey } from '../../libs/genLib';
import EnhancedTable from '../../components/Etable';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  aligner: {
    // display:'inline-flex',
    // marginRight:'auto',
    // marginBottom:'15px',
  },
  paperDropperBody: {
    display: 'flex',
    margin: theme.spacing(2),
  },
  heading: {
    marginTop: '4px',
    cursor: 'pointer',
    // marginTop: '10px',
  },
  submit: {
    margin: theme.spacing(1),
  },
  paper: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    marginBottom: theme.spacing(10),
    // overflow: 'scroll',
  },
  mainSection: {
    // display: 'flex',
    width: '100%',
    // display: 'flex',
    flexWrap: 'wrap',
    // flexDirection: 'column',
  },
  parentSection: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
    },

  },
  childSection: {
    marginLeft: theme.spacing(5),
  },
  childSectionChildren: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(4),
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    padding: '4px',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

const headCells = [
  {
    id: 'isDisabled', numeric: false, disablePadding: false, label: 'Disabled', maxWidth: '80px',
  },
  {
    id: 'name', numeric: false, disablePadding: false, label: 'Name',
  },
  {
    id: 'supplier', numeric: false, disablePadding: false, label: 'Supplier',
  },
  {
    id: 'unit', numeric: false, disablePadding: false, label: 'Unit',
  },
  {
    id: 'price', numeric: false, disablePadding: false, label: 'Unit Price (R)',
  },
  {
    id: 'description', numeric: false, disablePadding: false, label: 'Description',
  },
];

const mobileHeadCells = [
  {
    id: 'name', combine: [{ id: 'unit', opacity: 1 }, { id: 'supplier', opacity: 0.6 }], numeric: false, disablePadding: true, label: 'Name', maxWidth: '100px',
  },
  {
    id: 'isDisabled', numeric: false, disablePadding: true, label: 'Disabled',
  },
];

const fertMap = {
  nitrogenP: 'N', phosphorusP: 'P', potassiumP: 'K', caP: 'Ca', mgP: 'Mg', sP: 'S', bP: 'B', feP: 'Fe', mnP: 'Mn', znP: 'Zn', cuP: 'Cu', moP: 'Mo',
};

class FertiliserDetailform extends Component {
  constructor(props) {
    super(props);
    this.handleClickShowFertiliser = this.handleClickShowFertiliser.bind(this);
    this.handleClickAdd = this.handleClickAdd.bind(this);

    this.state = {
      expandedSetting: '',
      // fertilisers: this.props.initialState,
      selectedFertiliser: null,
      selectedFertiliserCrop: null,
      modalObject: null,
      isNew: false,
      fertiliserDetailOpen: false,
      fertiliserCropDetailOpen: false,
      errors: {},
      openModal: '',

    };
  }

  handleClickShow = () => {
    this.setState({ fertiliserDetailOpen: true });
  }

  handleBlur = (event) => {
    // return this.validateField (event.target.id, this.props.requiredFields);
  }

  handleClickAdd() {
    this.setState({
      fertiliserDetailOpen: true,
      isNew: true,
      pricePerUnit:0,
      selectedFertiliser: {
        nitrogenP: 0,
        phosphorusP: 0,
        potassiumP: 0,
        caP: 0,
        mgP: 0,
        sP: 0,
        bP: 0,
        feP: 0,
        mnP: 0,
        znP: 0,
        cuP: 0,
        moP: 0,
        nitrogenG: 0,
        phosphorusG: 0,
        potassiumG: 0,
        caG: 0,
        mgG: 0,
        sG: 0,
        bG: 0,
        feG: 0,
        mnG: 0,
        znG: 0,
        cuG: 0,
        moG: 0,
      },
    });
  }


handleClickAddNewFertiliserCrop = (FertiliserSK, selectedFertiliser) => {
  this.setState({
    fertiliserCropDetailOpen: true, isNew: true, selectedFertiliserSK: FertiliserSK, selectedFertiliser, selectedFertiliserCrop: {},
  });
}

handleClickShowFertiliser(event, parent, selectedFertiliserID) {
  const selectedFertiliser = this.props.getFromGlobalState('fertilisers').find((c) => c.sk === selectedFertiliserID);
  this.setState({
    selectedFertiliser, selectedFertiliserSK: selectedFertiliserID, fertiliserDetailOpen: 'true', isNew: false,
  });
}

handleClickShowFertiliserCrop = (selectedFertiliserId, selectedFertiliserCropId) => {
  const selectedFertiliser = this.props.getFromGlobalState('fertilisers').find((c) => c.sk === selectedFertiliserId);
  const selectedFertiliserCrop = selectedFertiliser.fertcrops.find((cc) => cc.sk === selectedFertiliserCropId);
  this.setState({ selectedFertiliserCrop, selectedFertiliser, selectedFertiliserSK: selectedFertiliser.sk });
  this.setState({ fertiliserCropDetailOpen: true, isNew: false });
}

handleClickEdit() {
  this.setState({ fertiliserDetailOpen: true, isNew: true });
}

getFertiliserRows = (fertiliserDetail) => {
  if (!fertiliserDetail || !fertiliserDetail.length) {
    return [];
  }
  const fertiliserRows = fertiliserDetail.map((fertiliser) => {
    const ob = { ...fertiliser };
    ob.isDisabled = ob.isDisabled ? 'Yes' : 'No';
    // the description should be a concateanation of all the rest of the object attributes in a fertilier. use the object attribute name as well

    ob.description = '';
    Object.keys(fertMap).forEach((key) => {
      if (fertMap[key] && ob[key]) {
        ob.description += `${fertMap[key]}:${parseFloat(ob[key]).toFixed(2)} `; 
      }
    });
    return ob;
  });
  return fertiliserRows;
}

setClose() {
  this.setState({ fertiliserDetailOpen: false });
}

setCloseVariety() {
  this.setState({ fertiliserCropDetailOpen: false });
}

handleUpdate(ferteliserIn) {
  const updatedIndex = this.props.getFromGlobalState('fertilisers').findIndex((i) => i.sk === ferteliserIn.sk);
  if (updatedIndex === -1) {
    const newFertilisers = this.props.getFromGlobalState('fertilisers');
    newFertilisers.push(ferteliserIn);
    this.props.setGlobalState({ fertilisers: newFertilisers });
    // this.state.blocks.push(blockIn);
  } else {
    const newFertilisers = this.props.getFromGlobalState('fertilisers');
    newFertilisers[updatedIndex] = ferteliserIn;
    this.props.setGlobalState({ fertilisers: newFertilisers });
  }
}

handleUpdateFertiliserCrop(fertiliserCropIn, FertiliserSK) {
  // see if we have the block
  const updatedFertiliserIndex = this.props.getFromGlobalState('fertilisers').findIndex((i) => i.sk === FertiliserSK);
  if (updatedFertiliserIndex === -1) {
    alert("impossible - we don't have this fertiliser!");
    return;
  }
  // find the planting
  const fertilisers = this.props.getFromGlobalState('fertilisers');
  const fertiliserCrops = fertilisers[updatedFertiliserIndex].fertcrops;

  if (!fertiliserCrops) {
    fertilisers[updatedFertiliserIndex].fertcrops = [];
  }

  const updatedFertCropIndex = (fertilisers ? fertilisers[updatedFertiliserIndex].fertcrops.findIndex((i) => i.sk === fertiliserCropIn.sk) : -1);
  if (updatedFertCropIndex === -1) {
    fertilisers[updatedFertiliserIndex].fertcrops.push(fertiliserCropIn);
    this.props.setGlobalState({ fertilisers });
  } else {
    fertilisers[updatedFertiliserIndex].fertcrops[updatedFertCropIndex] = fertiliserCropIn;
    this.props.setGlobalState({ fertilisers });
  }
}

getCropsNotAddedYet(crops, fertiliser, isNew, selectedFertiliserCrop) {
  // need to make sure we don't allow duplicate crops
  // in the fertilisers.chemprops array.
  if (!fertiliser) { return []; }
  if (!fertiliser.fertcrops) { return []; }
  const fertFilter = fertiliser.fertcrops.map((chem) => chem.crop);

  // Use filter and "not" includes to filter the full dataset by the filter dataset's val.
  const filteredCrops = crops.filter((crop) => !fertFilter.includes(crop.sk));
  if (!isNew) {
    // get this one and add it in.
    const fert = crops.filter((c) => c.sk === selectedFertiliserCrop.crop);
    if (fert) {
      filteredCrops.push(fert[0]);
    }
  }

  return filteredCrops;
}

render() {
  const { classes, initialState, setSnack, isMobile, getFromGlobalState } = this.props;
  const { selectedFertiliser } = this.state;
  const fertilisers = initialState;
  const rows = this.getFertiliserRows(fertilisers);
  const userDetail = getFromGlobalState('userDetail');

  return (

    <Paper elevation={0} className={classes.mainSection}>

      {(this.state.fertiliserDetailOpen)
          && (
          <FertiliserD
            handleUpdate={this.handleUpdate.bind(this)}
            open={this.state.fertiliserDetailOpen}
            setClose={this.setClose.bind(this)}
            isNew={this.state.isNew}
            getFromGlobalState={this.props.getFromGlobalState}
            fertiliser={this.state.selectedFertiliser}
            autoSearch
            setSnack={setSnack}
            fertilisers={fertilisers}
          />
          )}

      {(this.state.fertiliserCropDetailOpen)
          && (
          <FertCropD
            handleUpdate={this.handleUpdateFertiliserCrop.bind(this)}
            open={this.state.fertiliserCropDetailOpen}
            setClose={this.setCloseVariety.bind(this)}
            isNew={this.state.isNew}
            fertCrop={this.state.selectedFertiliserCrop}
            parentSK={this.state.selectedFertiliserSK}
            parent={this.state.selectedFertiliser}
            getFromGlobalState={this.props.getFromGlobalState}
         // metaData={this.props.metaData} />}
            metaData={this.getCropsNotAddedYet(this.props.metaData, this.state.selectedFertiliser, this.state.isNew, this.state.selectedFertiliserCrop)}
          />
          )}

<EnhancedTable
              heading="Fertilisers"
              displayToolbar
              hideHeading
              id="fertilisersTable"
              noPagination //= { rows && rows.length > 5 ? false:true}
              rows={rows}
              headCells={!isMobile ? headCells : mobileHeadCells}
              handleSelect={this.handleClickShowFertiliser}
              handleAddItem={userDetail && userDetail.rights && !userDetail.rights.readOnly ? this.handleClickAdd : null}
              actionType="actionType"
              rowKeyName="sk"
              disablePadFirstCol
             // padFirstCol="5px"
              clean
              dense
        // parent
       // ancestorsInRow
        // parentSK="parentSK"
              maxHeight="350px"
              selectedRow={selectedFertiliser ? selectedFertiliser.sk : ''}
            />

    

    </Paper>
  );
}
}
FertiliserDetailform.propTypes = {
  onSave: PropTypes.func.isRequired,
  initialState: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  setGlobalState: PropTypes.func.isRequired,
  metaData: PropTypes.array,
  isMobile: PropTypes.bool,

};
export default withStyles(styles)(FertiliserDetailform);
