import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import { validate, validateForm } from '../../libs/validate';
import { getAdminSettings, getErrorMessage } from '../../libs/storage';
import GenDialog from '../../components/GenDialog';
import SearchBox from '../../components/SearchBox';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  formControlA: {
    minWidth: 220,
    // marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  helperText: {
    color: 'red',
  },
  group: {
    flexDirection: 'row',
  },
  formControlRadio: {
    marginTop: theme.spacing(2),
    width: '100%',
  },

});

const requiredFields = [
  {
    name: 'name', min: 3, max: 1000,
  },
  {
    name: 'unit', min: 1, max: 1000,
  },
  {
    name: 'nitrogenP', min: 0, max: 1000, type: 'number',
  },
  {
    name: 'nitrogenG', min: 0, max: 1000, type: 'number',
  },
  {
    name: 'phosphorusP', min: 0, max: 1000, type: 'number',
  },
  {
    name: 'phosphorusG', min: 0, max: 1000, type: 'number',
  },
  {
    name: 'potassiumP', min: 0, max: 1000, type: 'number',
  },
  {
    name: 'potassiumG', min: 0, max: 1000, type: 'number',
  },
  {
    name: 'caP', min: 0, max: 1000, type: 'number',
  },
  {
    name: 'caG', min: 0, max: 1000, type: 'number',
  },
  {
    name: 'mgP', min: 0, max: 1000, type: 'number',
  },
  {
    name: 'mgG', min: 0, max: 1000, type: 'number',
  },
  {
    name: 'sP', min: 0, max: 1000, type: 'number',
  },
  {
    name: 'sG', min: 0, max: 1000, type: 'number',
  },
  {
    name: 'bP', min: 0, max: 1000, type: 'number',
  },
  {
    name: 'bG', min: 0, max: 1000, type: 'number',
  },
  {
    name: 'feP', min: 0, max: 1000, type: 'number',
  },
  {
    name: 'feG', min: 0, max: 1000, type: 'number',
  },
  {
    name: 'mnP', min: 0, max: 1000, type: 'number',
  },
  {
    name: 'mnG', min: 0, max: 1000, type: 'number',
  },
  {
    name: 'znP', min: 0, max: 1000, type: 'number',
  },
  {
    name: 'znG', min: 0, max: 1000, type: 'number',
  },
  {
    name: 'cuP', min: 0, max: 1000, type: 'number',
  },
  {
    name: 'cuG', min: 0, max: 1000, type: 'number',
  },
  {
    name: 'moP', min: 0, max: 1000, type: 'number',
  },
  {
    name: 'moG', min: 0, max: 1000, type: 'number',
  },
  //  {name:'npkUnit',min:1, max:6},
  //  {name:'unit',min:1, max: 40},
  /* {name:'minStock',min:1, max: 40}, */

];

class FertiliserD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      isNew: this.props.isNew,
      fertiliser: this.props.fertiliser,
      errors: { count: 0 },
      changed: false,
      searchedFertilisers: [],
      selectedGenFertiliser: null,
      searchString: '',
      isSearching: false,

    };
  }

  componentDidMount() {
    // if (this.props.isNew) {
    //  this.setState({ fertiliser: { fertcrops: [] } });
    // } else {
    //  const { fertiliser } = this.props;
    //  if (!fertiliser.fertcrops) {
    //    fertiliser.fertcrops = [];
    //  }
    //  this.setState({ fertiliser });
    // }
  }

  handleBlur = (event) => this.validateField(event.target.id, requiredFields)


handleInputChange = (event) => {
  const { getFromGlobalState, setSnack, fertilisers } = this.props;
  const activeSite = getFromGlobalState('activeSite');
  if (event.target.value.length > 0) {
    this.setState({ searchString: event.target.value, isSearching: true }, () => {
      getAdminSettings(activeSite, 'startswith', 'genFertilisers', encodeURIComponent(this.state.searchString))
        .then((searchedFertilisers) => {
          // filter the searchedFertilisers to remove disabled ones and ones already in fertilisers using es6 syntax
          const filtered = searchedFertilisers.filter((fert) => !fert.isDisabled && !fertilisers.find((f) => f.loadedFromSettingSK && f.loadedFromSettingSK === fert.sk));
          //const filtered = searchedFertilisers.filter((fert) => !fert.isDisabled);
          this.setState({ searchedFertilisers: filtered, isSearching: false });
        })
        .catch((e) => {
          setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
        });
    });
  } else {
    this.setState({ searchedFertilisers: [{ sk: '', name: 'Type the first letter to search' }] });
  }
}

  handleNameBlur = (event) => {
    const { fertiliser, errors } = this.state;
    //if (!isNew) {
   //   this.validateField(event.target.id, requiredFields);
  //  } else {
      const { fertilisers } = this.props;
      const error = validate(event.target.id, fertiliser, requiredFields);
      if (error.isValid) {
        if (fertilisers) {
          const idx = fertilisers.filter((c) => c.sk !== fertiliser.sk).findIndex((f) => f.name === event.target.value);
          if (idx !== -1) {
            error.isValid = false;
            error.msg = 'Name already exists';
          } else {
            error.isValid = true;
            error.msg = '';
          }
        }
      }
      const newErrors = { ...errors };
      newErrors[event.target.id] = error.msg;
      this.setState({ errors: newErrors });
  //  }
  }

  handleChange = (event) => {
    const fertiliser = { ...this.state.fertiliser };
    fertiliser[event.target.id] = event.target.value;
    this.setState({ fertiliser, changed: true });
  }

  handleGenFertChange = (value) => {
    const { searchedFertilisers } = this.state;
    // const { skPrimary } = this.props;
    console.log('event value', value);
    if (value && value.value){
      const selectedGenFertiliser = searchedFertilisers.find((fert) => fert.sk === value.value);
      const fertiliser = { ...selectedGenFertiliser };
      delete fertiliser.sk;
      delete fertiliser.isGeneral;
      fertiliser.loadedFromSettingSK = selectedGenFertiliser.sk;
      // fertiliser.sk = skPrimary.concat(uuid.v1());
      this.setState({ fertiliser, selectedGenFertiliser });
    }
  }

  handleCheckChange = (event) => {
    const { fertiliser } = this.state;
    const newFertiliser = { ...fertiliser };
    newFertiliser[event.target.id] = event.target.checked;
    this.setState({ fertiliser: newFertiliser, changed: true });
  }

  handleChangeP = (event) => {
    const fertiliser = { ...this.state.fertiliser };
    fertiliser[event.target.id] = event.target.value && event.target.value.length && parseFloat(event.target.value);
    const gEquiv = event.target.id.replace('P', 'G');
    fertiliser[gEquiv] = event.target.value * 1000 / 100;
    this.setState({ fertiliser, changed: true });
  }

  handleChangeG = (event) => {
    const fertiliser = { ...this.state.fertiliser };
    fertiliser[event.target.id] = event.target.value && event.target.value.length && parseFloat(event.target.value);
    const gEquiv = event.target.id.replace('G', 'P');
    fertiliser[gEquiv] = (event.target.value / 1000) * 100;
    this.setState({ fertiliser, changed: true });
  }

  handleRadioChange = (name, event) => {
    const fertiliser = { ...this.state.fertiliser };
    fertiliser[name] = event.target.value;
    this.setState({ fertiliser, changed: true });
  }

  handleToggle = (value, relation, parentId) => {
    const currentIndex = this.state.fertiliser.allowedVarieties.indexOf(value);
    const newFertiliser = { ...this.state.fertiliser };

    if (currentIndex === -1) {
      newFertiliser.allowedVarieties.push(value);
    } else {
      newFertiliser.allowedVarieties.splice(currentIndex, 1);
    }

    this.setState({ fertiliser: newFertiliser, changed: true });
  }

  validateField(field) {
    const { fertiliser, errors } = this.state;
    const error = validate(field, fertiliser, requiredFields);
    const newErrors = { ...errors };
    newErrors[field] = error.msg;
    this.setState({ errors: newErrors });
  }

  getGenFertsOptions = (searchedFertilisers) => {
    const fertiliserRows = searchedFertilisers.map((fertiliser) => {
      const ob = {};
      ob.value = fertiliser.sk;
      ob.key = fertiliser.sk;
      ob.label = fertiliser.name;
      return ob;
    });
    return fertiliserRows;
  }

  renderElement = (errors, fertiliser, element, GorP) => (
    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
      <TextField
        margin="normal"
       // required
     //  fullWidth
        id={`${element}${GorP}`}
        label={GorP === 'G' ? fertiliser?.unit ? fertiliser?.unit === 'kg' ? ' Ca g/kg' : ' Ca g/L' : ' Ca g' : '%'}
        name={`${element}${GorP}`}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">{fertiliser?.unit ? fertiliser?.unit === 'kg' ? ' g/kg' : ' g/L' : ' g'}</InputAdornment>,
          min: '0.00001',
          step: 'any',
        }}

        value={fertiliser[`${element}${GorP}`]}
        onChange={this.handleChangeG}
        onBlur={this.handleBlur}
        error={errors[`${element}${GorP}`] ? true : undefined}
        helperText={errors[`${element}${GorP}`]}
      />
    </Grid>
  )

  getOptionLabel = (option, value) => {
    console.log('optionLabeloption',option, 'value', value);
    return option.label ? option.label : '';
  }

  getOptionValue = (option) => {
    return option.value;
  }

  getOptionSelected = (option, value) => {
    console.log('optionSelectedoption', option, 'value', value);
    return option.value === value;
  }

  getOptions = (options) => {
 
    const { searchString, isSearching } = this.state;
    if (searchString.length === 0) {
      return [{ value: '', label: 'Type to search', key: 'type' }];
    }
    if (isSearching) {
      return [{ value: '', label: 'Searching...', key: 'searching' }];
    }
    if (!isSearching && options.length === 0) {
      return [{ value: '', label: 'No results', key: 'searching' }];
    }
    return options;
  }

  render() {
    const {
      classes, open, isNew, setClose, handleUpdate, getFromGlobalState, dynamoType, skPrimary, autoSearch,
    } = this.props;
    const {
      fertiliser, errors, searchedFertilisers, selectedGenFertiliser, isSearching,
    } = this.state;
    const formValid = validateForm(fertiliser, requiredFields) && (errors.name ? errors.name === '' : true);
    const genFerts = this.getGenFertsOptions(searchedFertilisers);
    const readOnly = selectedGenFertiliser || (fertiliser && fertiliser.loadedFromSettingSK);

    return (

      <GenDialog
        open={open}
        dialogObject={fertiliser}
        setClose={setClose}
        isNew={isNew}
        handleUpdateDialogObject={handleUpdate.bind(this)}
        skPrimary={skPrimary}
        dynamoType={dynamoType}
        valid={formValid}
        changed={this.state.changed}
        newTitle="New Fertiliser"
        title={fertiliser.name}
        getFromGlobalState={getFromGlobalState}
      >
        <Grid container className={classes.root} spacing={1} alignItems="flex-end">
          {!isNew && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={fertiliser.isDisabled}
                 // disabled={isInvite}
                  id="isDisabled"
                  onChange={this.handleCheckChange}
                  value="isDisabled"
                  color="secondary"
                />
        )}
              label="Disabled"
            />
          </Grid>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {autoSearch && (
              <SearchBox
                options={this.getOptions(genFerts)}
                onInputChange={this.handleInputChange}
                onChange={this.handleGenFertChange}
                label={isSearching ? 'Searching...' : 'Search for fertiliser'}
              />
            )}
            <TextField
              margin="normal"
              required
              disabled={readOnly}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              id="name"
              label="Trade Name"
              name="name"
              //autoFocus
              value={fertiliser.name}
              onChange={this.handleChange}
              onBlur={this.handleNameBlur }
              error={errors.name ? true : undefined}
              helperText={errors.name}
            />
          </Grid>
          {/* <TextField
            margin="normal"
            //required
            fullWidth
            id="regnum"
            label="Registration Number"
            name="regnum"

            value={fertiliser.regnum}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            error={errors.regnum ? true : undefined}
            helperText={errors.regnum}
         /> */}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl component="fieldset" className={classes.formControlRadio}>
              <FormLabel required>State</FormLabel>
              <RadioGroup
                aria-label="unit"
                name="unit"
                id="unit"
                disabled={readOnly}
                className={classes.group}
                value={fertiliser?.unit || ''}
                onChange={this.handleRadioChange.bind(this, 'unit')}
              >
                <FormControlLabel value="kg" control={<Radio />} label="kg (granular)" />
                <FormControlLabel value="liter" control={<Radio />} label="liter (liquid)" />
                {errors.unit && <FormHelperText className={classes.helperText}>{errors.unit}</FormHelperText>}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
            // required
           // fullWidth
              id="nitrogenP"
              label="Nitrogen (N) %"
              name="nitrogenP"
              disabled={readOnly}
              InputLabelProps={{
                shrink: true,
              }}

              value={fertiliser.nitrogenP}
              onChange={this.handleChangeP}
              onBlur={this.handleBlur}
              error={errors.nitrogenP ? true : undefined}
              helperText={errors.nitrogen}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
            // required
          //  fullWidth
              id="nitrogenG"
              disabled={readOnly}
              label={fertiliser?.unit ? fertiliser?.unit === 'kg' ? ' Nitrogen (N) g/kg' : ' Nitrogen (N) g/L' : ' Nitrogen (N) g'}
              name="nitrogenG"
              InputLabelProps={{
                shrink: true,
              }}

              value={fertiliser.nitrogenG}
              onChange={this.handleChangeG}
              onBlur={this.handleBlur}
              error={errors.nitrogenG ? true : undefined}
              helperText={errors.nitrogen}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
            // required
          //  fullWidth
              id="phosphorusP"
              label="Phosphorus (P) %"
              name="phosphorusP"
              disabled={readOnly}
              InputLabelProps={{
                shrink: true,
              }}
              value={fertiliser.phosphorusP}
              onChange={this.handleChangeP}
              onBlur={this.handleBlur}
              error={errors.phosphorusP ? true : undefined}
              helperText={errors.phosphorusP}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
            // required
           // fullWidth
              id="phosphorusG"
              label={fertiliser?.unit ? fertiliser?.unit === 'kg' ? ' Phosphorus (P) g/kg' : ' Phosphorus (P) g/L' : ' Phosphorus (P) g'}
              name="phosphorusG"
              disabled={readOnly}
              InputLabelProps={{
                shrink: true,
              }}
              value={fertiliser.phosphorusG}
              onChange={this.handleChangeG}
              onBlur={this.handleBlur}
              error={errors.phosphorusG ? true : undefined}
              helperText={errors.phosphorusG}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
            // required
           // fullWidth
              id="potassiumP"
              label="Potassium (K) %"
              name="potassiumP"
              disabled={readOnly}
              InputLabelProps={{
                shrink: true,
              }}
              value={fertiliser.potassiumP}
              onChange={this.handleChangeP}
              onBlur={this.handleBlur}
              error={errors.potassiumP ? true : undefined}
              helperText={errors.potassiumP}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
            // required
         //   fullWidth
              id="potassiumG"
              label={fertiliser?.unit ? fertiliser?.unit === 'kg' ? ' Potassium (K) g/kg' : ' Potassium (K) g/L' : ' Potassium (K) g'}
              name="potassiumG"
              disabled={readOnly}
              InputLabelProps={{
                shrink: true,
              }}
              value={fertiliser.potassiumG}
              onChange={this.handleChangeG}
              onBlur={this.handleBlur}
              error={errors.potassiumG ? true : undefined}
              helperText={errors.potassiumG}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
            // required
           // fullWidth
              id="caP"
              label="Ca %"
              name="caP"
              disabled={readOnly}
              InputLabelProps={{
                shrink: true,
              }}

              value={fertiliser.caP}
              onChange={this.handleChangeP}
              onBlur={this.handleBlur}
              error={errors.caP ? true : undefined}
              helperText={errors.caP}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
            // required
          //  fullWidth
              id="caG"
              label={fertiliser?.unit ? fertiliser?.unit === 'kg' ? ' Ca g/kg' : ' Ca g/L' : ' Ca g'}
              name="caG"
              disabled={readOnly}
              InputLabelProps={{
                shrink: true,
              }}

              value={fertiliser.caG}
              onChange={this.handleChangeG}
              onBlur={this.handleBlur}
              error={errors.caG ? true : undefined}
              helperText={errors.caG}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
            // required
           // fullWidth
              id="mgP"
              label="Mg %"
              name="mgP"
              disabled={readOnly}
              InputLabelProps={{
                shrink: true,
              }}

              value={fertiliser.mgP}
              onChange={this.handleChangeP}
              onBlur={this.handleBlur}
              error={errors.mgP ? true : undefined}
              helperText={errors.mgP}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
            // required
          //  fullWidth
              id="mgG"
              label={fertiliser?.unit ? fertiliser?.unit === 'kg' ? ' Mg g/kg' : ' Mg g/L' : ' Mg g'}
              name="mgG"
              disabled={readOnly}
              InputLabelProps={{
                shrink: true,
              }}

              value={fertiliser.mgG}
              onChange={this.handleChangeG}
              onBlur={this.handleBlur}
              error={errors.mgG ? true : undefined}
              helperText={errors.mgG}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
            // required
           // fullWidth
              id="sP"
              label="S %"
              name="sP"
              disabled={readOnly}
              InputLabelProps={{
                shrink: true,
              }}

              value={fertiliser.sP}
              onChange={this.handleChangeP}
              onBlur={this.handleBlur}
              error={errors.sP ? true : undefined}
              helperText={errors.sP}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
            // required
          //  fullWidth
              id="sG"
              disabled={readOnly}
              label={fertiliser?.unit ? fertiliser?.unit === 'kg' ? ' S g/kg' : ' S g/L' : ' S g'}
              name="sG"
              InputLabelProps={{
                shrink: true,
              }}

              value={fertiliser.sG}
              onChange={this.handleChangeG}
              onBlur={this.handleBlur}
              error={errors.sG ? true : undefined}
              helperText={errors.sG}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
            // required
           // fullWidth
              id="bP"
              label="B %"
              name="bP"
              disabled={readOnly}
              InputLabelProps={{
                shrink: true,
              }}

              value={fertiliser.bP}
              onChange={this.handleChangeP}
              onBlur={this.handleBlur}
              error={errors.bP ? true : undefined}
              helperText={errors.bP}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
            // required
          //  fullWidth
              id="bG"
              label={fertiliser?.unit ? fertiliser?.unit === 'kg' ? ' B g/kg' : ' B g/L' : ' B g'}
              name="bG"
              disabled={readOnly}
              InputLabelProps={{
                shrink: true,
              }}

              value={fertiliser.bG}
              onChange={this.handleChangeG}
              onBlur={this.handleBlur}
              error={errors.bG ? true : undefined}
              helperText={errors.bG}
            />
          </Grid>

          {/* Fe */}
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
            // required
           // fullWidth
              id="feP"
              label="Fe %"
              name="feP"
              disabled={readOnly}
              InputLabelProps={{
                shrink: true,
              }}

              value={fertiliser.feP}
              onChange={this.handleChangeP}
              onBlur={this.handleBlur}
              error={errors.feP ? true : undefined}
              helperText={errors.feP}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
            // required
          //  fullWidth
              id="feG"
              disabled={readOnly}
              label={fertiliser?.unit ? fertiliser?.unit === 'kg' ? ' Fe g/kg' : ' Fe g/L' : ' Fe g'}
              name="feG"
              InputLabelProps={{
                shrink: true,
              }}

              value={fertiliser.feG}
              onChange={this.handleChangeG}
              onBlur={this.handleBlur}
              error={errors.feG ? true : undefined}
              helperText={errors.feG}
            />
          </Grid>

          {/* Mn */}
          {/* Fe */}
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
            // required
           // fullWidth
              id="mnP"
              label="Mn %"
              name="mnP"
              disabled={readOnly}
              InputLabelProps={{
                shrink: true,
              }}

              value={fertiliser.mnP}
              onChange={this.handleChangeP}
              onBlur={this.handleBlur}
              error={errors.mnP ? true : undefined}
              helperText={errors.mnP}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
            // required
          //  fullWidth
              id="mnG"
              disabled={readOnly}
              label={fertiliser?.unit ? fertiliser?.unit === 'kg' ? ' Mn g/kg' : ' Mn g/L' : ' Mn g'}
              name="mnG"
              InputLabelProps={{
                shrink: true,
              }}

              value={fertiliser.mnG}
              onChange={this.handleChangeG}
              onBlur={this.handleBlur}
              error={errors.mnG ? true : undefined}
              helperText={errors.mnG}
            />
          </Grid>

          {/* Zn */}
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
            // required
           // fullWidth
              id="znP"
              label="Zn %"
              name="znP"
              disabled={readOnly}
              InputLabelProps={{
                shrink: true,
              }}

              value={fertiliser.znP}
              onChange={this.handleChangeP}
              onBlur={this.handleBlur}
              error={errors.znP ? true : undefined}
              helperText={errors.znP}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
            // required
          //  fullWidth
              id="znG"
              disabled={readOnly}
              label={fertiliser?.unit ? fertiliser?.unit === 'kg' ? ' Zn g/kg' : ' Zn g/L' : ' Zn g'}
              name="znG"
              InputLabelProps={{
                shrink: true,
              }}

              value={fertiliser.znG}
              onChange={this.handleChangeG}
              onBlur={this.handleBlur}
              error={errors.znG ? true : undefined}
              helperText={errors.znG}
            />
          </Grid>

          {/* Cu */}
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
            // required
           // fullWidth
              id="cuP"
              label="Cu %"
              name="cuP"
              disabled={readOnly}
              InputLabelProps={{
                shrink: true,
              }}

              value={fertiliser.cuP}
              onChange={this.handleChangeP}
              onBlur={this.handleBlur}
              error={errors.cuP ? true : undefined}
              helperText={errors.cuP}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
            // required
          //  fullWidth
              id="cuG"
              disabled={readOnly}
              label={fertiliser?.unit ? fertiliser?.unit === 'kg' ? ' Cu g/kg' : ' Cu g/L' : ' Cu g'}
              name="cuG"
              InputLabelProps={{
                shrink: true,
              }}

              value={fertiliser.cuG}
              onChange={this.handleChangeG}
              onBlur={this.handleBlur}
              error={errors.cuG ? true : undefined}
              helperText={errors.cuG}
            />
          </Grid>

          {/* Mo */}
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
            // required
           // fullWidth
              id="moP"
              label="Mo %"
              name="moP"
              disabled={readOnly}
              InputLabelProps={{
                shrink: true,
              }}
              value={fertiliser.moP}
              onChange={this.handleChangeP}
              onBlur={this.handleBlur}
              error={errors.moP ? true : undefined}
              helperText={errors.moP}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
            // required
          //  fullWidth
              id="moG"
              label={fertiliser?.unit ? fertiliser?.unit === 'kg' ? ' Mo g/kg' : ' Mo g/L' : ' Mo g'}
              name="moG"
              disabled={readOnly}
              InputLabelProps={{
                shrink: true,
              }}

              value={fertiliser.moG}
              onChange={this.handleChangeG}
              onBlur={this.handleBlur}
              error={errors.moG ? true : undefined}
              helperText={errors.moG}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              fullWidth
              id="supplier"
              label="Supplier"
              name="supplier"
              value={fertiliser.supplier}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.supplier ? true : undefined}
              helperText={errors.supplier}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="advisor"
              label="Advisor Name and Surname"
              name="advisor"
              value={fertiliser.advisor}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.advisor ? true : undefined}
              helperText={errors.advisor}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              fullWidth
              id="price"
              label="Price Per Unit"
              name="price"
              value={fertiliser.price}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.advisor ? true : undefined}
              helperText={errors.advisor}
            />
          </Grid>

          {/* } <TextField
            margin="normal"
            required
            fullWidth
            type="number"
            inputProps={{ 'min': '0.00001', 'step':'any' }}
            id="minStock"
            label={`Min stock level in ${(fertiliser?.unit && (fertiliser?.unit))}`}
            name="minStock"

            value={fertiliser.minStock}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            error={errors.minStock ? true : undefined}
            helperText={errors.minStock}
        /> */}
        </Grid>
      </GenDialog>

    );
  }
}
// set default proptypes
FertiliserD.defaultProps = {
  dynamoType: 'fertilisers',
  skPrimary: 'init_setting_',
  autoSearch: false,
  searchedFertilisers: [],
};

FertiliserD.propTypes = {
  classes: PropTypes.object.isRequired,
  dynamoType: PropTypes.string,
  open: PropTypes.bool.isRequired,
  fertiliser: PropTypes.object,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  handleUpdate: PropTypes.func.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  skPrimary: PropTypes.string,
  fertilisers: PropTypes.array.isRequired,
  autoSearch: PropTypes.bool,
  setSnack: PropTypes.func.isRequired,
};
export default withStyles(styles)(FertiliserD);
