import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import Link from '@material-ui/core/Link';
import Fab from '@material-ui/core/Fab';
import MobileStepper from '@material-ui/core/MobileStepper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import FileUploader from '../FileUploader';
import {
  storeAsset, getErrorMessage, createFolder, createObject2, getDownloadUrl, deleteFile,
} from '../../libs/storage';
import ExpansionPanelDetailed from '../../components/ExpansionPanelDetail';
import LoaderFab from '../../components/LoaderFab';
import FolderD from '../FolderD';
import Load from '../../components/Load';
import EnhancedTable from '../../components/Etable';
import FileIcon from '../../components/FileIcon';
import FileD from '../FileD';

const MAX_STORAGE = 1000; // MB
const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    // marginTop: theme.spacing(8),
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'inherit',
    // alignItems: 'center',
    marginBottom: theme.spacing(10),
    // overflow: 'scroll',
  },
  heading: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  section: {
    // margin: 0,
  },
  stepper: {
    width: 200,
    flexGrow: 1,
    backgroundColor: 'inherit',
    progress: { color: 'red', backgroundColor: 'red' },
  },
});

class Documents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addFolderDialogOpen: false,
      expandedFolder: '',
      expandedSection: '',
      selectedFolder: null,
      selectedParent: null,
      isNew: false,
      headCells: [],
      selectedFile: null,
      fileDialogOpen: false,
      link: null,
      urlLoading: false,
      selectedDownloadKey: '',
      isMobile: false,
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    const isMobile = !window.matchMedia('(min-width: 850px)').matches;
    let headCells = { ...this.state };
    if (isMobile) {
      headCells = [
        {
          id: 'icon', numeric: false, disablePadding: false, label: '', maxWidth: '20px',
        },
        {
          id: 'descrName', combine: [{ id: 'descrName', opacity: 1 }, { id: 'filename', opacity: 0.6 }, { id: 'sizeString', opacity: 0.4 }], numeric: false, disablePadding: false, label: 'Name', maxWidth: '100px',
        },
        {
          id: 'downloadLink', numeric: false, disablePadding: false, label: '',
        },
      ];
    } else {
      headCells = [
        {
          id: 'icon', numeric: false, disablePadding: true, label: '', maxWidth: '20px',
        },
        {
          id: 'descrName', combine: [{ id: 'descrName', opacity: 1 }, { id: 'filename', opacity: 0.6 }, { id: 'sizeString', opacity: 0.4 }], numeric: false, disablePadding: false, label: 'Name',
        },
        {
          id: 'uploaded', numeric: false, disablePadding: false, label: 'Modified',
        },
        {
          id: 'downloadLink', numeric: false, disablePadding: false, label: '',
        },
      ];
    }
    const clean = isMobile;
    this.setState({ headCells, clean, isMobile });
  }

  handleAddFolder = (parentSK) => {
    // let addFolderDialogOpen = this.state.addFolderDialogOpen;
    if (parentSK) {
      const folders = this.props.getFromGlobalState('folders');
      const idx = folders.findIndex((fldr) => fldr.sk === parentSK);
      if (idx === -1) {
        alert('impossible folder not found');
        return null;
      }
      this.setState({ addFolderDialogOpen: true, isNew: true, selectedParent: folders[idx] });
    } else {
      this.setState({ addFolderDialogOpen: true, isNew: true, selectedParent: null });
    }
  }

  setClose = () => {
    // let addFolderDialogOpen = this.state.addFolderDialogOpen;
    this.setState({ addFolderDialogOpen: false, selectedFolder: null, selectedParent: null });
  }

  setCloseFileDialog = () => {
    // let addFolderDialogOpen = this.state.addFolderDialogOpen;
    this.setState({ fileDialogOpen: false });
  }

  handleUpdate = (folder) => {
    const folders = this.props.getFromGlobalState('folders');
    const idx = folders.findIndex((fldr) => fldr.sk === folder.sk);
    if (idx === -1) {
      folders.push(folder);
    } else {
      folders[idx] = folder;
    }
    this.props.setGlobalState({ folders });

    // let addFolderDialogOpen = this.state.addFolderDialogOpen;
    // this.setState({ addFolderDialogOpen: true});
  }

  handleFileUpdate = (file) => {
    const files = this.props.getFromGlobalState('files');
    const idx = files.findIndex((file) => file.sk === this.state.selectedFile.sk);
    if (idx === -1) {
      files.push(file);
    } else {
      files[idx] = file;
    }
    this.props.setGlobalState({ files });

    // let addFolderDialogOpen = this.state.addFolderDialogOpen;
    // this.setState({ addFolderDialogOpen: true});
  }

  handleFolderExpand = (folder) => {
    if (this.state.expandedFolder !== folder) {
      this.setState({ expandedFolder: folder });
    } else {
      this.setState({ expandedFolder: '' });
    }
  }

  handleSectionExpand = (section) => {
    if (this.state.expandedSection !== section) {
      this.setState({ expandedSection: section });
    } else {
      this.setState({ expandedSection: '' });
    }
  }

  handleDeleteFileUpdate(dialogObject) {
    const files = this.props.getFromGlobalState('files');
    const idx = files.findIndex((f) => f.sk === dialogObject.sk);
    if (idx !== -1) {
      files.splice(idx, 1);
      this.props.setGlobalState({ files });
    }
  }

  async handleFileDeletePreExecute(fileIn) {
    try {
      const activeTenant = this.props.getFromGlobalState('activeSite');
      const params = {
        activeTenant,
        Key: fileIn.Key,
        type: 'files',
      };
      // this.setState({ isloading: true });
      const res = await deleteFile(params);
      // this.setState({ isloading: false });
      if (res.status) {
        return true;
      }

      return false;
    } catch (e) {
      this.props.setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
      return false;
    }
  }

  async handlePreExecute(folderIn) {
    const activeTenant = this.props.getFromGlobalState('activeSite');
    folderIn.activeTenant = activeTenant;
    if (this.state.isNew) {
      try {
        const res = await createFolder(folderIn);
        this.setState({ isloading: false });
        // this.props.setSnack({ open: true, variant: 'info', autoHideDuration: 4000, messageText: 'User invitation sent. You can view the user post acceptance.' });
        return false;
      } catch (e) {
        throw (e);
      }
    } else {
      return true;
    }
  }

  getSizeString(size) {
    let sizeString;
    if (size < 1000) {
      return `${size.toLocaleString()} bytes`;
    }
    if (size < 1000000) {
      return `${(size / 1000).toLocaleString()} KB`;
    }
    return `${(size / 1000000).toLocaleString()} MB`;
  }

  async fileUploaded(fileIn) {
    try {
      if (!fileIn.serverId) {
        console.log('received undefined key');
        return;
      }
      const key = fileIn.serverId;

      const asset = {};

      asset.sk = `init_${key}`;
      asset.lastModified = fileIn.file.lastModified;
      asset.Key = key;
      asset.lastModifiedDate = fileIn.file.lastModifiedDate ? fileIn.file.lastModifiedDate : new Date(fileIn.file.lastModified);
      asset.fileExtension = fileIn.fileExtension;
      asset.filename = fileIn.filename;
      asset.filenameWithoutExtension = fileIn.filenameWithoutExtension;
      asset.fileSize = fileIn.fileSize;
      asset.fileType = fileIn.fileType;
      asset.createdAt = new Date().valueOf();
      asset.version = 1;
      asset.type = 'files';
      asset.activeTenant = this.props.getFromGlobalState('activeSite');
      // console.log('asset!!!', asset);
      const retVal = await createObject2(asset);

      const files = this.props.getFromGlobalState('files');
      // let newItem = {...asset};
      // newItem.date = new Date(fileIn.file.lastModified).toLocaleString();
      //  newItem.fileName = fileIn.filename;
      // newItem.sizeString = this.getSizeString(fileIn.file.size);
      // newItem.contentType = fileIn.fileExtension;
      // newItem.canPreview = this.canPreview(newItem.contentType);

      const currentIndex = files.findIndex((i) => i.Key === key);
      if (currentIndex === -1) {
        files.push(asset);
      } else {
        files[currentIndex] = asset;
      }
      this.props.setGlobalState({ files });
    } catch (e) {
      console.log('Error from dialog', e);
      this.props.setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
    }
  }

  handleClickShowFile(event, planting, fileKey, actionType, dunno, recordType) {
    const selectedFile = this.props.getFromGlobalState('files').find((file) => file.sk === fileKey);
    this.setState({ selectedFile, fileDialogOpen: 'true', isNew: false });
  }

  getIcon = (type) => (
    <FileIcon size="small" contentType={type}> </FileIcon>
  )

  handleDownload = async (event, selectedFileKey) => {
    event.preventDefault();
    event.stopPropagation();
    event.persist();
    this.setState({ urlLoading: true, selectedDownloadKey: selectedFileKey });
    const selectedFile = this.props.getFromGlobalState('files').find((file) => file.Key === selectedFileKey);
    if (!selectedFile) {
      alert('impossible file not in state');
    }
    const params = {
      activeTenant: this.props.getFromGlobalState('activeSite'),
      type: 'files',
      path: selectedFileKey,
      contentType: 'application/octet-stream', // selectedFile.fileType,
    };
    try {
      const url = await getDownloadUrl(params);
      this.link.href = url;
      // event.persist();
      this.link.download = selectedFile.filename;
      this.setState({ urlLoading: false, selectedDownloadKey: '' });
      this.link.click();
      return true;
    } catch (e) {
      this.props.setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
    }
    //
  }

  handleClick = (event) => {
    event.stopPropagation();
  }

  isLoading = (path) => {
    if (path === this.state.selectedDownloadKey) {
      return true;
    }
    return false;
  }

  getDownloadLink = (fileName, path, contentType) => (

    <a href="" style={{ color: 'inherit' }} ref={(ref) => (this.link = ref)} onClick={(event) => this.handleClick(event)} download={fileName}>
      <Tooltip title="Download">
        <LoaderFab
          onClick={(event) => this.handleDownload(event, path)}
          color="inherit"
          success //= {this.state.locationLoading && this.state.locationLoaded}
          icon={<DownloadIcon />}
          loading={this.state.urlLoading && this.isLoading(path)}
        />

      </Tooltip>
    </a>

  )

  handleEditFolder = (folder, event) => {
    event.stopPropagation();
    this.setState({ addFolderDialogOpen: true, isNew: false, selectedFolder: folder });
  }

  getFolderAction = (folder) => {
    const b = 1;
    return (
      <IconButton
        style={{ padding: '0px', float: 'right' }}
        aria-label="Edit"
          // color="primary"
        className={this.props.classes.menuButton}
        onClick={this.handleEditFolder.bind(this, folder)}
      >
        <EditIcon />
      </IconButton>
    );
  }

  renderFolder = (folder, maxReached) => {
    const { isMobile } = this.state;
    let expanded = false;
    if (folder.name === this.state.expandedFolder) {
      expanded = true;
    }
    let sections = [];
    if (expanded) {
      sections = this.props.getFromGlobalState('folders');
      if (!sections) {
        sections = [];
      }
      sections = sections.filter((section) => section.path.startsWith(folder.path) && section.parentSK);
      if (!sections) {
        sections = [];
      }
    }
    const userDetail = this.props.getFromGlobalState('userDetail');

    return (

      <ExpansionPanelDetailed
        heading={folder.name}
        key={folder.sk}
        detailHeading={isMobile ? undefined : folder.description}
        action={this.getFolderAction(folder)}
        expanded={this.state.expandedFolder}
        handleExpand={this.handleFolderExpand}
      >

        {/* } <Tooltip title="Add">
            <IconButton size='medium' color="secondary" aria-label="add" onClick={this.handleAddFolder.bind(this,folder.sk)}>
              <AddIcon  />
            </IconButton>
    </Tooltip> */}
        <Grid container spacing={2} alignItems="flex-start">
          <Grid style={{ padding: '10px' }} item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Fab
              variant="extended"
              size="medium"
              style={{
                float: 'right', marginLeft: 'auto', color: 'black', backgroundColor: 'transparent',
              }}
              color="primary"
              aria-label="add"
              disabled={userDetail && userDetail.rights && userDetail.rights.readOnly}
              className={this.props.classes.margin}
              onClick={this.handleAddFolder.bind(this, folder.sk)}
            >
              <AddIcon className={this.props.classes.extendedIcon} />
              Section
            </Fab>
          </Grid>
          <Grid style={{ padding: '10px' }} item xs={12} sm={12} md={12} lg={12} xl={12}>
            {expanded && sections.map((section) => (
              <>
                {this.renderSection(section, maxReached)}
              </>
            ))}

          </Grid>
        </Grid>

      </ExpansionPanelDetailed>
    );
  }

  renderSection = (section, maxReached) => {
    // get the folder children
    // const folders = this.props.getFromGlobalState('folders');

    // const children = folders.filter((child) => child.sk.startsWith(`${folder.sk}init`));
    const { isMobile } = this.state;
    let rows = [];
    if (section.name === this.state.expandedSection) {
      const files = this.props.getFromGlobalState('files');
      const filteredFiles = this.props.getFromGlobalState('files').filter((file) => file.Key.startsWith(section.path));

      rows = filteredFiles && filteredFiles.map((row) => {
        const ob = { ...row };
        ob.uploaded = new Date(ob.createdAt).toLocaleDateString();
        ob.sizeString = this.getSizeString(ob.fileSize);
        ob.descrName = ob.descrName ? ob.descrName : ob.filenameWithoutExtension;
        ob.icon = this.getIcon(row.fileExtension);
        ob.downloadLink = this.getDownloadLink(ob.filename, ob.Key, ob.fileType);
        return ob;
      });
    }
    rows = rows && rows.sort((a, b) => (`${a.descrName}`).localeCompare(b.descrName, undefined, { numeric: true }));
    const { headCells } = this.state;

    return (
      <>
        <ExpansionPanelDetailed
          heading={section.name}
          key={section.sk}
          disablePadding
          style={{ margingLeft: '10px' }}
          detailHeading={isMobile ? undefined : section.description}
          action={this.getFolderAction(section)}
          expanded={this.state.expandedSection}
          handleExpand={this.handleSectionExpand}
        >

          {/* } <Tooltip title="Add">
            <IconButton size='medium' color="secondary" aria-label="add" onClick={this.handleAddFolder.bind(this,folder.sk)}>
              <AddIcon  />
            </IconButton>
    </Tooltip> */}

          <Grid container spacing={2} alignItems="flex-start">

            <Grid style={{ padding: '10px' }} item xs={12} sm={12} md={12} lg={12} xl={12}>
              <EnhancedTable

                heading="Files"
                     // hideHeader
                    // displayToolbar
                hideHeading
                id="fileTable"
                noPagination //= { rows && rows.length > 5 ? false:true}
                rows={rows}
                headCells={headCells}
                handleSelect={this.handleClickShowFile.bind(this)}
                    /// / handleAddItem={this.handleClickAdd.bind(this)}
                    // parent={}
                     // actionType='actionType'
                rowKeyName="sk"
                disablePadFirstCol
                padFirstCol="5px"
                clean
                dense
                maxHeight="350px"
                selectedRow={this.state.selectedFile}
              />
            </Grid>

            <Grid style={{ padding: '10px' }} item xs={12} sm={12} md={12} lg={12} xl={12}>
              {maxReached && <Typography>Maximum storage of 1GB reached</Typography>}
              {!maxReached && (
              <FileUploader
                activeTenant={this.props.getFromGlobalState('activeSite')}
                generateThumbnail={false}
                generatePreview={false}
                onFileUploaded={this.fileUploaded.bind(this)}
                folder={section.path}
                getFromGlobalState={this.props.getFromGlobalState}
              />
              )}
            </Grid>
          </Grid>

        </ExpansionPanelDetailed>
      </>
    );
  }

  render() {
    const { classes, getFromGlobalState } = this.props;
    const {
      addFolderDialogOpen, expandedFolder, folder, fileDialogOpen, isNew,
    } = this.state;
    let folders = this.props.getFromGlobalState('folders');
    folders = folders && folders.sort((a, b) => (`${a.name}`).localeCompare(b.name, undefined, { numeric: true }));
    if (!folders) {
      return null;
    }
    const userDetail = getFromGlobalState('userDetail');
    folders = folders.filter((f) => !f.parentSK);
    const files = this.props.getFromGlobalState('files');
    let fileSize = files.reduce((prev, cur) => prev + parseFloat(cur.fileSize), 0);
    fileSize = (fileSize / 1000000).toFixed(2);
    const maxReached = fileSize > MAX_STORAGE;

    const roundedFileSize = maxReached ? 100 : Math.round(100 * (fileSize) / MAX_STORAGE);
    const fileSizeStr = `${fileSize} MB out of 1GB used.`;
    const rows = [];
    const headCells = [];
    return (
      <Load isloading={this.state.isloading}>
        <Paper elevation={0} className={classes.paper}>

          <div style={{ float: 'right', marginLeft: 'auto' }}>

            <Tooltip title="Add Folder">
              <Fab
                variant="extended"
                size="medium"
                color="primary"
                disabled={userDetail && userDetail.rights && userDetail.rights.readOnly}
                aria-label="add"
                className={classes.margin}
                onClick={this.handleAddFolder.bind(this, null)}
              >
                <AddIcon className={classes.extendedIcon} />
                Folder
              </Fab>

            </Tooltip>
          </div>
          <div>
            {folders && folders.map((folder, index) => this.renderFolder(folder, maxReached))}
          </div>

          {addFolderDialogOpen && (
          <FolderD
            handleUpdate={this.handleUpdate}
            handlePreExecute={isNew ? this.handlePreExecute.bind(this) : undefined}

            parent={this.state.selectedParent}
            open
            folder={isNew ? undefined : this.state.selectedFolder}
            isNew={isNew}
            setClose={this.setClose}
            getFromGlobalState={this.props.getFromGlobalState}
          />
          )}

          {fileDialogOpen && (
          <FileD
            handleUpdate={this.handleFileUpdate}
        // handlePreExecute={this.handlePreExecute.bind(this)}
            handleDeletePreExecute={this.handleFileDeletePreExecute.bind(this)}
            handleDeleteDialogObject={this.handleDeleteFileUpdate.bind(this)}
            allowDelete
        // parent={this.state.seletedFolder}
            open
            file={isNew ? undefined : this.state.selectedFile}
            isNew={isNew}
            setClose={this.setCloseFileDialog}
            getFromGlobalState={this.props.getFromGlobalState}
          />
          )}
          <div style={{ margin: '10px', width: '100%', textAlign: 'center' }}>
            <div style={{ display: 'inline-block' }}>

              <Tooltip title={fileSizeStr}>
                <MobileStepper
                  variant="progress"
                  backgroundColor="inherit"
                  steps={100}
                  nextButton={(
                    <Typography style={{ margin: '5px' }} variant="subtitle1">
                      1GB
                    </Typography>
      )}
                  backButton={(
                    <Typography style={{ margin: '5px' }} variant="subtitle1">
                      {`${fileSize}MB`}
                    </Typography>
      )}
                  position="static"
                  activeStep={roundedFileSize}
                  className={classes.stepper}
                />
              </Tooltip>
            </div>
          </div>

        </Paper>
      </Load>

    );
  }
}
export default withStyles(styles)(Documents);
