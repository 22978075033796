import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { getSiteList } from '../../libs/storage';
import EnhancedTable from '../../components/Etable';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paperDropperBody: {
    display: 'flex',
    margin: theme.spacing(2),
  },
  heading: {
    marginTop: '4px',
    cursor: 'pointer',
  },
  submit: {
    margin: theme.spacing(1),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(10),
  },
  mainSection: {
    width: '100%',
    flexWrap: 'wrap',
  },
  parentSection: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
    },

  },
  childSection: {
    marginLeft: theme.spacing(5),
  },
  childSectionChildren: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(4),
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    padding: '4px',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

const ChildWrapper = ({
  children,
  settingDialog,
  handleUpdate,
  open,
  setClose,
  isNew,
  selectedSetting,
  metaData, getFromGlobalState, setGlobalState,
}) => (
  React.cloneElement(settingDialog, {
    handleUpdate,
    open,
    setClose,
    isNew,
    selectedSetting,
    metaData,
    getFromGlobalState,
    setGlobalState,
  })

);

class GenSetForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSetting: null,
      isNew: false,
      settingDialogOpen: false,
    };
  }

  handleClickShow = () => {
    this.setState({ settingDialogOpen: true });
  }

  handleBlur = () => {
    // return this.validateField (event.target.id, this.props.requiredFields);
  }

  handleClickAdd() {
    this.setState({ settingDialogOpen: true, isNew: true, selectedSetting: {} });
  }

  handleClickShowSetting(none1, none, selectedSettingId) {
    const { getFromGlobalState, settingName } = this.props;
    const selectedSetting = getFromGlobalState(settingName).find((s) => s.sk === selectedSettingId);
    this.setState({
      selectedSetting, settingDialogOpen: 'true', isNew: false,
    });
  }

  handleClickEdit() {
    this.setState({ settingDialogOpen: true, isNew: true });
  }

  setClose() {
    this.setState({ settingDialogOpen: false });
  }

  handleUpdate = async (settingIn) => {
    const { getFromGlobalState, settingName, setGlobalState } = this.props;
    const updatedIndex = getFromGlobalState(settingName).findIndex((i) => i.sk === settingIn.sk);
    if (updatedIndex === -1) {
      const newSetting = getFromGlobalState(settingName);
      newSetting.push(settingIn);
      setGlobalState({ [settingName]: newSetting });
      // this.state.blocks.push(blockIn);
    } else {
      const newSetting = getFromGlobalState(settingName);
      newSetting[updatedIndex] = settingIn;
      setGlobalState({ [settingName]: newSetting });
    }

    if (settingName === 'farms') {
      const siteList = await getSiteList();
      setGlobalState({ siteList });
    }
  }

  render() {
    const {
      classes, settingDialog, userAvatar, settingHeading, headCells, initialState, getFromGlobalState,
    } = this.props;
    const { settingDialogOpen } = this.state;
    const setting = initialState;
    const userDetail = getFromGlobalState('userDetail');

    return (

      <Paper elevation={0} className={classes.mainSection}>

        {(settingDialogOpen)
          && (
          <ChildWrapper
            settingDialog={settingDialog}
            metaData={this.props.metaDataName ? this.props.getFromGlobalState(this.props.metaDataName) : undefined}
            handleUpdate={this.handleUpdate.bind(this)}
            open={this.state.settingDialogOpen}
            setClose={this.setClose.bind(this)}
            isNew={this.state.isNew}
            selectedSetting={this.state.selectedSetting}
            setGlobalState={this.props.setGlobalState}
            getFromGlobalState={this.props.getFromGlobalState}
          />
          )}

        <EnhancedTable
          userAvatar={userAvatar}
          displayToolbar
          id={settingHeading}
          heading=""
          noPagination
          rows={setting}
          headCells={headCells}
          handleSelect={this.handleClickShowSetting.bind(this)}
          handleAddItem={userDetail && userDetail.rights && !userDetail.rights.readOnly ? this.handleClickAdd.bind(this): null}
                         // parent={}
                         // actionType='actionType'
          rowKeyName="sk"
                          // disablePadFirstCol
          clean
          maxHeight="350px"
                          // dense = {showDetail ? true : false}
          selectedRow={this.state.selectedSetting}
        />

      </Paper>
    );
  }
}
GenSetForm.defaultProps = {
  displayLabelPrim: 'name',
};
GenSetForm.propTypes = {
  initialState: PropTypes.array.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  setGlobalState: PropTypes.func.isRequired,
  settingDialog: PropTypes.object.isRequired,
  settingName: PropTypes.string.isRequired,
  settingHeading: PropTypes.string.isRequired,
  metaDataName: PropTypes.string,
  headCells: PropTypes.array.isRequired,
  userAvatar: PropTypes.any,

};
export default withStyles(styles)(GenSetForm);
