import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  getCropDetails, getAdminSettings, getErrorMessage, getFertiliserDetails, getPPPDetails,
} from '../../libs/storage';
import Tabs from '../../components/Tabs';
import ExpansionPanelDetailed from '../../components/ExpansionPanelDetail';
import FramsAdminForm from './farmsForm';
import CropsAdminForm from './cropsForm';
import TargetsAdminForm from './targetsForm';
import FertiliserAdminForm from './fertiliserForm';
import PPPAdminForm from './pppForm';
import ProductProcessorD from '../ProductProcessorD';
import ProductProcessorTypeD from '../ProductProcessorTypeD';
import GenSetForm from './genSetFormAdmin';
import { getNameFromKey } from '../../libs/genLib';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    backgroundColor: 'inherit',
  },
  paper2: {
    marginLeft: '4px',
    marginTop: '10px',
  },
  section: {
    margin: theme.spacing(2, 4),
  },
});

class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 2,
      isloading: false,
      isloadingTable: false,
      crops: {},
      cropsDetail: {},
      selectedCropType: null,
      columns: [],
      targetsDetail: {},
      selectedTargetsType: null,
      isSubmitting: false,
      expandedSetting: '',
      fertiliserColumns: [],
      genFertilisers: [],
      genPPPs: [],
    };
  }

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    const { getFromGlobalState, setGlobalState, setSnack } = this.props;
    this.setState({ isloading: true });
    this.setState({ isloading: false });
  }

  setAdminState = (stateObject) => {
    this.setState(stateObject);
  }

  handleSettingExpand = (setting) => {
    const { expandedSetting } = this.state;
    if (expandedSetting !== setting) {
      this.setState({ expandedSetting: setting });
    } else {
      this.setState({ expandedSetting: '' });
    }
  }

  getAdminState = (stateMember) => this.state[stateMember];

  getTargetDetails = async (event) => {
    const targetType = event.target.value;
    this.setState({ isloadingTable: true, selectedTargetsType: targetType });
    const {
      getFromGlobalState, setSnack,
    } = this.props;
    const { targetsDetail } = this.state;

    if (targetsDetail && targetsDetail[targetType] && targetsDetail[targetType].length) {
      this.setState({ isloadingTable: false });
      return;
    }
    let activeSite = getFromGlobalState('activeSite');
    if (activeSite === '' || !activeSite) {
      activeSite = 'default';
    }
    try {
      const newTargetsDetail = await getAdminSettings(activeSite, targetType.concat('_'));

      // const cropsNew = newTargetsDetail.filter((target) => target.type === 'targetsSetting');
      const targetsDetailNew = newTargetsDetail.filter((detail) => detail.type === 'targetDetail');
      // const columnsNew = this.getHeader(crops).sort((a, b) => ("" + a.name).localeCompare(b.name, undefined, { numeric: false }));
      const newTargetsDetailOb = { ...targetsDetail };
      // newCrops[cropType] = cropsNew;
      // let newTargetsDetail = { ...cropsDetail };
      newTargetsDetailOb[targetType] = targetsDetailNew;
      // if (!noUpdateState) {
      this.setState({ targetsDetail: newTargetsDetailOb, isloadingTable: false });
      // }

      // return { targetsDetail: newTargetsDetailOb[targetType] };
    } catch (e) {
      setSnack({ open: true, variant: 'error', messageText: getErrorMessage(e) });
      this.setState({ isloadingTable: false });
      return false;
    }
  }

  getCropDetailsLocal = async (cropType) => {
    this.setState({ isloadingTable: true });
    const { getFromGlobalState, setGlobalState, setSnack } = this.props;
    const cropDetails = await getCropDetails(getFromGlobalState, setGlobalState, setSnack, cropType);
    this.setState({ isloadingTable: true });
    return cropDetails;
  }

  getFertiliserDetailsLocal = async () => {
    this.setState({ isloadingTable: true });
    const { getFromGlobalState, setGlobalState, setSnack } = this.props;
    const fertiliserDetail = await getFertiliserDetails(getFromGlobalState, setGlobalState, setSnack);
    this.setState({ genFertilisers: fertiliserDetail, isloadingTable: false });
    return fertiliserDetail;
  }

  getPPPDetailsLocal = async () => {
    this.setState({ isloadingTable: true });
    const { getFromGlobalState, setGlobalState, setSnack } = this.props;
    const pPPDetail = await getPPPDetails(getFromGlobalState, setGlobalState, setSnack);
    this.setState({ genPPPs: pPPDetail, isloadingTable: false });
    return pPPDetail;
  }

  /* constructHierarchy = (settings, filter) => {
    if (!settings)
    {
      return false;
    }
      //set our general settings
    let cropTypesList = settings.filter((record) => record.type === 'cropTypesSetting');
    if (filter) {
      cropTypesList = cropTypesList.filter((record) => record.sk === filter);
    }
    const cropList = settings.filter((record) => record.type === 'cropsSetting');
    const cropsDetail = settings.filter((record) => record.type === 'cropsDetail');
    if (!cropTypesList) {
      cropTypesList = [];
    }
    cropTypesList.forEach(function (cropType) {
      cropType.crops = cropList.filter (record => record.sk.startsWith(cropType.sk));
      if (!cropTypesList.crops)
      {
        cropTypesList.crops = [];
      }
    });
    return { cropTypes: cropTypesList, cropsDetail };

  } */

  handleTabChange = (event, newValue) => {
    const currentTab = this.state.selectedTab;
    if (newValue !== currentTab) {
      this.setState({ selectedTab: newValue });
    }
  }

  render() {
    const {
      classes, reload, isAuthenticated, getFromGlobalState, setGlobalState, setSnack, isMobile,
    } = this.props;
    const {
      selectedTab, columns, fertiliserColumns, targetsDetail, genFertilisers, genPPPs, selectedTargetsType, isSubmitting, expandedSetting,
    } = this.state;

    const cropTypes = getFromGlobalState('cropTypesSetting');
    const crops = getFromGlobalState('genCrops');
    const cropsDetail = getFromGlobalState('cropsDetail');

    return (

      <Paper elevation={0} className={classes.paper}>
        <Tabs value={selectedTab} handleChange={this.handleTabChange} selectedTab={selectedTab}>
          <FramsAdminForm
            heading="Farms"
          />
          <CropsAdminForm
            heading="Crops"
            getFromGlobalState={getFromGlobalState}
            setGlobalState={setGlobalState}
            setSnack={setSnack}
            isAuthenticated
            data={{ cropTypes, crops }}
            rows={cropsDetail}
            setAdminState={this.setAdminState}
            getAdminState={this.getAdminState}
            getCropDetails={this.getCropDetailsLocal}
            columns={columns}
          />
          <TargetsAdminForm
            heading="Targets"
            data={{ cropTypes, crops }}
            getFromGlobalState={getFromGlobalState}
            setGlobalState={setGlobalState}
            setSnack={setSnack}
            rows={selectedTargetsType ? targetsDetail[selectedTargetsType] : {}}
            getCropDetails={this.getCropDetailsLocal}
            isAuthenticated
            getTargetDetails={this.getTargetDetails}
            setAdminState={this.setAdminState}
            getAdminState={this.getAdminState}
            onTargetTypeChange={this.getTargetDetails}
            selectedTargetsType={selectedTargetsType}
          />
          <FertiliserAdminForm
            heading="Fertiliser"
            getFromGlobalState={getFromGlobalState}
            setGlobalState={setGlobalState}
            setSnack={setSnack}
            isAuthenticated
            data={{ cropTypes, crops }}
            setAdminState={this.setAdminState}
            getAdminState={this.getAdminState}
            fertilisers={genFertilisers}
            getFertiliserDetails={this.getFertiliserDetailsLocal}
            columns={fertiliserColumns}
          />
          <PPPAdminForm
            heading="PPP"
            getFromGlobalState={getFromGlobalState}
            setGlobalState={setGlobalState}
            setSnack={setSnack}
            isAuthenticated
            setAdminState={this.setAdminState}
            getAdminState={this.getAdminState}
            ppps={genPPPs}
            getPPPDetails={this.getPPPDetailsLocal}
          />

          <Paper heading="Other" elevation={0} style={{ marginLeft: '4px' }} className={classes.paper2}>
            {/* <ExpansionPanelDetailed
              heading="Product Processor Types"
              expanded={expandedSetting}
              handleExpand={this.handleSettingExpand}
            >
              <GenSetForm
             // heading="Product Processor Types"
               // initialState={getFromGlobalState('productProcessorTypes')}
                initialState={getFromGlobalState('productProcessorTypes').map((pt) => {
                  const ob = { ...pt };
                  ob.enabled = ob.enabled ? 'Yes' : 'No';
                  return ob;
                })}
                handleSubmit={this.handleSubmit}
                isSubmitting={isSubmitting}
                setGlobalState={setGlobalState}
                getFromGlobalState={getFromGlobalState}
                settingDialog={<ProductProcessorTypeD />}
                settingName="productProcessorTypes"
                settingHeading="Add"
                displayLabelPrim="name"
                displayLabelSec="description"
                headCells={!isMobile ? [
                  {
                    id: 'enabled', numeric: false, disablePadding: false, label: 'Enabled',
                  },
                  {
                    id: 'name', numeric: false, disablePadding: false, label: 'Name',
                  },
                  {
                    id: 'description', numeric: false, disablePadding: false, label: 'Description',
                  },
                ] : [
                  {
                    id: 'name', numeric: false, disablePadding: false, label: 'Name',
                  },
                ]}
              />
            </ExpansionPanelDetailed>
            <ExpansionPanelDetailed
              heading="Product Processors"
              expanded={expandedSetting}
              handleExpand={this.handleSettingExpand}
            >
              <GenSetForm
                heading="Product Processors"
                initialState={getFromGlobalState('productProcessors').map((producer) => {
                  const ob = { ...producer };
                  ob.productProducerTypeName = getNameFromKey('productProcessorTypes', ob.productProcessorType, getFromGlobalState);
                  ob.enabled = ob.enabled ? 'Yes' : 'No';
                  return ob;
                })}
                handleSubmit={this.handleSubmit}
                isSubmitting={isSubmitting}
                setGlobalState={setGlobalState}
                getFromGlobalState={getFromGlobalState}
                settingDialog={<ProductProcessorD />}
                settingName="productProcessors"
                metaDataName="productProcessorTypes"
                settingHeading="Add"
                displayLabelPrim="name"
                displayLabelSec="description"
                headCells={!isMobile ? [
                  {
                    id: 'enabled', numeric: false, disablePadding: false, label: 'Enabled',
                  },
                  {
                    id: 'province', numeric: false, disablePadding: false, label: 'Province',
                  },
                  {
                    id: 'productProducerTypeName', numeric: false, disablePadding: false, label: 'Type',
                  },
                  {
                    id: 'name', numeric: false, disablePadding: false, label: 'Name',
                  },
                  {
                    id: 'description', numeric: false, disablePadding: false, label: 'Description',
                  },
                ] : [
                  {
                    id: 'productProducerTypeName', numeric: false, disablePadding: false, label: 'Type',
                  },
                  {
                    id: 'name', numeric: false, disablePadding: false, label: 'Name',
                  },
                ]}
              />
              </ExpansionPanelDetailed> */}
          </Paper>
        </Tabs>
      </Paper>

    );
  }
}
Admin.propTypes = {
  getFromGlobalState: PropTypes.func.isRequired,
  setSnack: PropTypes.func.isRequired,
  setGlobalState: PropTypes.func.isRequired,
  fertiliserColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default withStyles(styles)(Admin);
