import React from 'react';
import {
  GoogleMap, Marker, InfoWindow, Polygon, Circle, DrawingManager, useJsApiLoader, KmlLayer, OverlayView,
} from '@react-google-maps/api';
import PropTypes from 'prop-types';
import { getPolyCenter, getPolysBounds } from '../../libs/geoLib';

const containerStyle = {
  width: '100%',
  height: '100%',
  marginTop: '15px',
  marginBottom: '15px',
};

const lib = ['drawing', 'geometry'];
const polygonOptions = {
  fillColor: 'grey',
  fillOpacity: 0.4,
  strokeColor: 'grey',
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

const circleOptions = {
  strokeColor: '#FF0000',
  strokeOpacity: 1,
  strokeWeight: 3,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
  clickable: true,
  draggable: false,
  editable: false,
  visible: true,
  radius: 2,
  zIndex: 6,
};

const divStyle = {
  background: 'white',
  padding: 3,
  opacity: 0.75,
  minWidth: '100px',
};

// South Africa bounds
const southAfricaBounds = {
  north: -22.137,
  south: -34.831,
  east: 31.265,
  west: 16.587,
};

function getPolygonOptions(polygon) {
  const pOptions = { ...polygonOptions };
  pOptions.fillColor = polygon.color;
  pOptions.strokeColor = polygon.strokeColor;
  pOptions.clickable = polygon.clickable;
  pOptions.strokeWeight = polygon.strokeWeight ? polygon.strokeWeight : 1;
  return pOptions;
}

function getCircleOptions(circle) {
  const cOptions = { ...circleOptions };
  cOptions.fillColor = circle.observationData && circle.observationData.color;
  cOptions.radius = circle.observationData && circle.observationData.radius;
  cOptions.strokeColor = cOptions.fillColor;
  cOptions.zIndex = 1000 - (circle.observationData ? circle.observationData.cnt : 0);
  return cOptions;
}

function LandMap(props) {
  const {
    center,
    markers,
    zoom,
    onSelectMarker,
    onCloseMarker,
    onCloseInfoWindow,
    onClick,
    onPolygonComplete,
    onPolygonClick,
    onMarkerComplete,
    polygons,
    circles,
    infoWindow,
    mode,
    kmlLayerSrc,
    onLayerClick,
    selectedPoly,
    onCircleClick,
    style,
    keepBounds,
    recenter,
    userLocation,
    isModalOpen,
  } = props;

  const [map, setMap] = React.useState(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCT5z0IYRQ_u7P7jVO_XTY6LYuUxV1E8S8',
    libraries: lib,
  });

  const onLoad = React.useCallback((map) => {
    // Function to handle setting map bounds and center
    const setMapBoundsAndCenter = (bounds, center) => {
      if (bounds) {
        map.fitBounds(bounds);
      }
      if (center) {
        map.setCenter(center);
      }
    };

    // Check if user location is available and modal is not open
    if (!isModalOpen && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const userLoc = { lat: latitude, lng: longitude };
          
          let bounds;
          if (polygons.length) {
            const polys = selectedPoly ? polygons.filter((p) => p.sk === selectedPoly) : polygons;
            bounds = getPolysBounds(polys);
          } else {
            bounds = new window.google.maps.LatLngBounds(
              new window.google.maps.LatLng(userLoc.lat - 0.1, userLoc.lng - 0.1),
              new window.google.maps.LatLng(userLoc.lat + 0.1, userLoc.lng + 0.1)
            );
          }

          setMapBoundsAndCenter(bounds, userLoc);
          setMap(map);
        },
        () => {
          let bounds;
          if (polygons.length) {
            const polys = selectedPoly ? polygons.filter((p) => p.sk === selectedPoly) : polygons;
            bounds = getPolysBounds(polys);
          } else {
            bounds = new window.google.maps.LatLngBounds(
              new window.google.maps.LatLng(southAfricaBounds.south, southAfricaBounds.west),
              new window.google.maps.LatLng(southAfricaBounds.north, southAfricaBounds.east)
            );
          }

          setMapBoundsAndCenter(bounds);
          setMap(map);
        }
      );
    } else {
      let bounds;
      if (polygons.length) {
        const polys = selectedPoly ? polygons.filter((p) => p.sk === selectedPoly) : polygons;
        bounds = getPolysBounds(polys);
      } else if(userLocation){
        bounds = new window.google.maps.LatLngBounds(
          new window.google.maps.LatLng(userLocation.lat - 0.1, userLocation.lng - 0.1),
          new window.google.maps.LatLng(userLocation.lat + 0.1, userLocation.lng + 0.1)
        );
      } else {
        bounds = new window.google.maps.LatLngBounds(
          new window.google.maps.LatLng(southAfricaBounds.south, southAfricaBounds.west),
          new window.google.maps.LatLng(southAfricaBounds.north, southAfricaBounds.east)
        );
      }

      setMapBoundsAndCenter(bounds);
      setMap(map);
    }
  }, [polygons, selectedPoly, isModalOpen]);

  const onUnmount = React.useCallback((map) => {
    setMap(null);
  }, []);

  return isLoaded ? (
    <div style={style}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        onClick={onClick}
        drawingMode={mode}
        options={{ streetViewControl: false, mapTypeId: 'satellite', disableDoubleClickZoom: true }}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {recenter && <button onClick={recenter}>Recenter</button>}
        {kmlLayerSrc && (
          <KmlLayer
            url={kmlLayerSrc}
            options={{ preserveViewport: false, clickable: true, suppressInfoWindows: false }}
            onClick={onLayerClick}
          />
        )}
        {mode && (
          <DrawingManager
            onMarkerComplete={onMarkerComplete}
            options={{ drawingMode: mode }}
            onPolygonComplete={onPolygonComplete}
          />
        )}
        {polygons && polygons.map((polygon) => (
          <React.Fragment key={polygon.sk}>
            <Polygon
              onClick={onPolygonClick ? onPolygonClick.bind(this, polygon.sk) : undefined}
              paths={polygon.paths}
              options={getPolygonOptions(polygon)}
            />
            <OverlayView
              position={getPolyCenter(polygon)}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <span style={{ color: 'white', fontWeight: 'bold' }}>{polygon.label}</span>
            </OverlayView>
          </React.Fragment>
        ))}
        {circles && circles.map((ci) => (
          <Circle
            key={ci.id} // Ensure each Circle has a unique key
            onClick={onCircleClick ? onCircleClick.bind(this, ci) : undefined}
            center={ci}
            options={getCircleOptions(ci)}
          />
        ))}
        {infoWindow && (
          <InfoWindow
            onCloseClick={onCloseInfoWindow ? onCloseInfoWindow.bind(this) : null}
            position={infoWindow.position}
          >
            <div style={divStyle}>
              {infoWindow.content}
            </div>
          </InfoWindow>
        )}
        {markers && markers.map((marker) => (
          <React.Fragment key={marker.sk}>
            <Marker
              position={marker.position}
              clickable={marker.clickable ? marker.clickable : undefined}
              icon={marker.icon}
              onClick={onSelectMarker ? onSelectMarker.bind(this, marker.sk) : undefined}
            />
          </React.Fragment>
        ))}
      </GoogleMap>
    </div>
  ) : <></>;
}

LandMap.defaultProps = {
  markers: [],
  polygons: [],
  circles: [],
  infoWindow: null,
  onSelectMarker: null,
  onCloseMarker: null,
  onPolygonComplete: null,
  onPolygonClick: null,
  onCircleClick: null,
  onCloseInfoWindow: null,
  onClick: null,
  onMarkerComplete: null,
  mode: null,
  kmlLayerSrc: null,
  onLayerClick: null,
  selectedPoly: '',
  keepBounds: false,
  style: { height: '600px', width: '100%' },
  recenter: null,
  isModalOpen: false,
};

LandMap.propTypes = {
  center: PropTypes.object.isRequired,
  markers: PropTypes.array,
  polygons: PropTypes.array,
  circles: PropTypes.array,
  infoWindow: PropTypes.object,
  zoom: PropTypes.number,
  onSelectMarker: PropTypes.func,
  onCloseMarker: PropTypes.func,
  onPolygonComplete: PropTypes.func,
  onPolygonClick: PropTypes.func,
  onCircleClick: PropTypes.func,
  onCloseInfoWindow: PropTypes.func,
  onClick: PropTypes.func,
  onMarkerComplete: PropTypes.func,
  mode: PropTypes.string,
  kmlLayerSrc: PropTypes.string,
  onLayerClick: PropTypes.func,
  selectedPoly: PropTypes.string,
  keepBounds: PropTypes.bool,
  style: PropTypes.object,
  recenter: PropTypes.func,
  isModalOpen: PropTypes.bool,
  userLocation:PropTypes.object
};

export default LandMap;
